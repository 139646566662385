const today = new Date();
const currentYear = today.getFullYear();
const loginYear = 2021;

export const usersModule = {
  labelsList: [
    { id: 1, name: 'Banking', slug: 'banking', color: '#FF8C00' },
    { id: 2, name: 'Company', slug: 'company', color: '#00C4B4' },
    { id: 3, name: 'Payments', slug: 'payments', color: '#0F9AF7' },
  ],
  usersList: [
    {
      id: 1457690400,
      name: 'Alex Dolgove',
      profile_pic: '/images/avatar/alex-dolgove.png',
      email: 'alex-dolgove@example.com',
      emailUsage: 1.11, // GB
      code2: 'PFICB0951',
      vattin: 'L001-201087311',
      address: '#61, Preah Monivong Blvd., Sangkat Srah Chork, Khan Daun Penh, Phnom Penh, Cambodia.',
      phones: [{ phone: '(215)-659-7529', label: 'Home' }],
      designation: 'CEO',
      starred: false,
      company: 'TCS',
      labels: [2],
      isAdmin: true,
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 1, 1),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
    },
    {
      id: 1457690401,
      name: 'Chelsea Johns',
      profile_pic: '/images/avatar/chelsea-johns.jpg',
      email: 'chelsea-johnss@example.com',
      emailUsage: 1.6, // GB
      code2: 'PFICB0951',
      vattin: 'L001-201087311',
      address: '#61, Preah Monivong Blvd., Sangkat Srah Chork, Khan Daun Penh, Phnom Penh, Cambodia.',
      phones: [{ phone: '(215)-659-7529', label: 'home' }],
      designation: 'CFO',
      starred: false,
      company: 'Infosys',
      labels: [2, 1],
      isAdmin: true,
      status: 'suspended',
      suspendedBy: 'Admin',
      suspendedAt: new Date(currentYear, 1, 1),
      lastLoginAt: new Date(loginYear, 2, 17),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
    },
    {
      id: 1457690402,
      name: 'Domnic Brown',
      profile_pic: '/images/avatar/domnic-brown.png',
      email: 'domnic-brown@example.com',
      emailUsage: 1.8, // GB
      code2: 'PFICB0951',
      vattin: 'L001-201087311',
      address: '#61, Preah Monivong Blvd., Sangkat Srah Chork, Khan Daun Penh, Phnom Penh, Cambodia.',
      phones: [{ phone: '(215)-748-7855', label: 'home' }],
      designation: 'Designer',
      starred: false,
      company: 'Accenture',
      labels: [3],
      isAdmin: false,
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 6, 6),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
    },
    {
      id: 1457690403,
      name: 'Domnic Harris',
      profile_pic: '/images/avatar/domnic-harris.jpg',
      email: 'domnic-brown@example.com',
      emailUsage: 1.7, // GB
      code2: 'PFICB0951',
      vattin: 'L001-201087311',
      address: '#61, Preah Monivong Blvd., Sangkat Srah Chork, Khan Daun Penh, Phnom Penh, Cambodia.',
      phones: [{ phone: '(215)-659-7529', label: 'home' }],
      designation: 'PHP Developer',
      starred: false,
      company: 'Pizza Hut',
      labels: [],
      isAdmin: false,
      status: 'active',
      suspendedBy: 'Admin',
      suspendedAt: new Date(),
      lastLoginAt: new Date(loginYear, 5, 20),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
    },
    {
      id: 1457690404,
      name: 'Garry Sobars',
      profile_pic: '/images/avatar/garry-sobars.jpg',
      email: 'garry-sobars@example.com',
      emailUsage: 2.4, // GB
      code2: 'PFICB0951',
      vattin: 'L001-201087311',
      address: '#61, Preah Monivong Blvd., Sangkat Srah Chork, Khan Daun Penh, Phnom Penh, Cambodia.',
      phones: [{ phone: '(215)-659-8965', label: 'home' }],
      designation: 'HR Manager',
      starred: true,
      company: 'Dominos',
      labels: [],
      isAdmin: false,
      status: 'suspended',
      suspendedBy: 'Admin',
      suspendedAt: new Date(currentYear, 1, 1),
      lastLoginAt: new Date(loginYear, 5, 30),
      createdAt: new Date(currentYear, 1, 1),
      updatedAt: new Date(currentYear, 1, 1),
    },
  ],
};
