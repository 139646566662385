import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  currentUser: undefined,
  selectedUser: undefined,
  broadcastMessage: undefined,
  justLogin: false,
};

const updateSelectedUser = (state) => {
  state.currentUser = state.users.find((user) => user.id === state.currentUser?.id);
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
      updateSelectedUser(state);
    },
    addUser: (state, action) => {
      state.users.push(action.payload);
    },
    editUser: (state, action) => {
      state.users = state.users.map((user) => (user.id === action.payload.id ? action.payload : user));
      updateSelectedUser(state);
    },
    deleteUser: (state, action) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
      updateSelectedUser(state);
    },
    bulkDeleteUser: (state, action) => {
      state.users = state.users.filter((user) => !action.payload.includes(user.id));
      updateSelectedUser(state);
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    selectUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setBroadcastMessage: (state, action) => {
      state.broadcastMessage = action.payload;
    },
    clearBroadcastMessage: (state) => {
      state.broadcastMessage = undefined;
    },
    setJustLogin: (state) => {
      state.justLogin = true;
    },
  },
});

export const {
  setUsers,
  addUser,
  editUser,
  deleteUser,
  bulkDeleteUser,
  setCurrentUser,
  selectUser,
  setBroadcastMessage,
  clearBroadcastMessage,
  setJustLogin,
} = userSlice.actions;

export default userSlice.reducer;
