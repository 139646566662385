import CmtAvatar from '@coremat/CmtAvatar';
import CmtMediaObject from '@coremat/CmtMediaObject';
import { Box, Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { BILLING_DATETIME_FORMAT } from '@component/constants/Billing';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
  navMenuLink: { color: theme.palette.text.primary },
}));

const EInvoice = ({ item }) => {
  const classes = useStyles();

  return (
    <CmtMediaObject
      avatarPos="center"
      avatar={<CmtAvatar size={40} src="/images/avator/avator3.jpg" />}
      title={
        <Typography component="div" variant="h5" className={classes.titleRoot}>
          <Box component="span" color="blue">
            {item?.payload?.content}
          </Box>
        </Typography>
      }
      subTitle={
        <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
          <Box ml={2}>{moment(item?.createdAt).format(BILLING_DATETIME_FORMAT)}</Box>
        </Box>
      }
    />
  );
};

export default EInvoice;
