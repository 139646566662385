import Common from './Common';
import ProfileApp from './ProfileApp';
import Auth from './Auth';
import Users from './Users';
import PFI from './PFI';
import GMSUI from './GMS-UI';
import GMSUIPG from './GMS-UI-PG';
import Borrower from './Borrower';
import Shareholder from './Shareholder';
import Guarantor from './Guarantor';
import CIF from './CIF';
import Business from './Business';
import LG from './LG';

// export default (history) =>
//   combineReducers({
//     router: connectRouter(history),
//     common: Common,
//     profileApp: ProfileApp,
//     auth: Auth,
//     usersReducer: Users,
//     pfis: PFIs,
//     notification: Noti,
//     gmsui: GMSUI,
//     borrowers: Borrower,
//     shareholder: Shareholder,
//     guarantor: Guarantor,
//   });

const rootReducer = {
  common: Common,
  profileApp: ProfileApp,
  auth: Auth,
  usersReducer: Users,
  pfis: PFI,
  gmsui: GMSUI,
  gmsuiPG: GMSUIPG,
  borrowers: Borrower,
  shareholder: Shareholder,
  guarantor: Guarantor,
  cifs: CIF,
  business: Business,
  lg: LG,
};

export default rootReducer;
