import {
  BUSINESS_GET,
  BUSINESS_GET_ONE,
  BUSINESS_GET_LOAN,
  BUSINESS_GET_GUARANTEE,
} from '../../@jumbo/constants/BusinessActionType';

const INIT_STATE = {
  business: [],
  scheme: {},
};

export default (state = INIT_STATE, action) => {
  let returnData = action.payload;
  let business = state.business;
  switch (action.type) {
    case BUSINESS_GET: {
      let result = returnData;
      return {
        ...state,
        businesses: result,
      };
    }
    case BUSINESS_GET_ONE: {
      let result = returnData;
      return {
        ...state,
        business: result,
      };
    }
    case BUSINESS_GET_LOAN: {
      let result = returnData;
      return {
        ...state,
        loans: result,
      };
    }
    case BUSINESS_GET_GUARANTEE: {
      let result = returnData;
      return {
        ...state,
        guarantee: result,
      };
    }

    default:
      return state;
  }
};
