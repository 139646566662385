import { emptySplitApi } from './index';

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getOfficeHours: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/workingtime/officehour`,
      }),
      providesTags: (_resut, _error) => [{ type: 'OfficeHours', id: 'LIST' }],
    }),
    updateOfficeHour: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/workingtime/officehour`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_resut, _error) => [{ type: 'OfficeHours', id: 'LIST' }],
    }),
    getHolidays: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/workingtime/holiday`,
      }),
      providesTags: (_resut, _error) => [{ type: 'Holidays', id: 'LIST' }],
    }),
    addNewHoliday: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/workingtime/holiday`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_resut, _error) => [{ type: 'Holidays', id: 'LIST' }],
    }),
    deleteHoliday: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/workingtime/holiday/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_resut, _error) => [{ type: 'Holidays', id: 'LIST' }],
    }),
    getProfile: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/company`,
      }),
      providesTags: (result, error, args) => [{ type: 'Exchange', id: 'LIST' }],
    }),
    AddNewProfile: builder.mutation({
      query: ({ body }) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/company`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Exchange', id: 'LIST' }],
    }),
    updateProfile: builder.mutation({
      query: ({ body }) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/company`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Exchange', id: 'LIST' }],
    }),
    getPasswordPolicy: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/password-policy`,
      }),
      providesTags: (result, error, args) => [{ type: 'PasswordPolicy' }],
    }),
    updatePasswordPolicy: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/password-policy`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'PasswordPolicy' }],
    }),
    getRestructureLimit: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/restructure-limit`,
      }),
      providesTags: (result, error, args) => [{ type: 'RestructureLimit' }],
    }),
    updateRestructureLimit: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/restructure-limit`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'RestructureLimit' }],
    }),
    getTags: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/tag`,
      }),
      providesTags: (_resut, _error) => [{ type: 'Tags', id: 'LIST' }],
    }),
    addNewTag: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/tag`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_resut, _error) => [{ type: 'Tags', id: 'LIST' }],
    }),
    deleteTag: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/tag/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_resut, _error) => [{ type: 'Tags', id: 'LIST' }],
    }),
    updateClaimPayment: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/claim-payment-due-day`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ClaimPayment' }],
    }),
    getClaimPayment: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/claim-payment-due-day`,
      }),
      providesTags: (_resut, _error) => [{ type: 'CLAIM', id: 'LIST' }],
    }),
    getELGSetting: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/e-lg`,
      }),
      providesTags: (result, error, args) => [{ type: 'ELG-Setting' }],
    }),
    updateELGSetting: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/e-lg`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ELG-Setting' }],
    }),
    getRenewalSetting: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/renewal`,
      }),
      providesTags: (result, error, args) => [{ type: 'Renewal-Setting' }],
    }),
    updateRenewalSetting: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/renewal`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Renewal-Setting' }],
    }),
    getUpdateBizRegistrationSetting: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/business-registration`,
      }),
      providesTags: (result, error, args) => [{ type: 'Update-Biz-Registration-Setting' }],
    }),
    updateUpdateBizRegistrationSetting: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/business-registration`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Update-Biz-Registration-Setting' }],
    }),
    getExpireGASetting: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/expire-ga`,
      }),
      providesTags: (result, error, args) => [{ type: 'ExpireGA-Setting' }],
    }),
    updateExpireGASetting: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/expire-ga`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ExpireGA-Setting' }],
    }),
    getBroadcasts: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/broadcast`,
      }),
      providesTags: (_resut, _error) => [{ type: 'Broadcast', id: 'LIST' }],
    }),
    getBroadcastById: builder.query({
      query: (broadcastId) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/broadcast/${broadcastId}`,
      }),
      providesTags: (_resut, _error, id) => [{ type: 'Broadcast', id }],
    }),
    getBroadcastAnnouncement: builder.query({
      query: (broadcastId) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/broadcast/announcement`,
      }),
      providesTags: (_resut, _error, id) => [{ type: 'BroadcastAnnouncement' }],
    }),
    createNewBroadcast: builder.mutation({
      query: (broadcast) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/broadcast`,
        method: 'POST',
        body: broadcast,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Broadcast' }],
    }),
    updateBroadcast: builder.mutation({
      query: (broadcast) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/broadcast`,
        method: 'PATCH',
        body: broadcast,
      }),
      invalidatesTags: (result, error, args) => [{ type: 'Broadcast' }],
    }),
    deleteBroadcast: builder.mutation({
      query: (broadcastId) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/broadcast/${broadcastId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, args) => [{ type: 'Broadcast' }],
    }),

    //List Monthly Uploading Deadline
    getMonthlyUploadingDeadline: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/monthly-upload-deadline`,
        method: 'GET',
      }),
      providesTags: (result, error, args) => [{ type: 'Monthly-Setting', id: 'LIST' }],
    }),
    //Create and Update Monthly Uploading Deadline
    updateMonthlyUploadingDeadline: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/setting/monthly-upload-deadline`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, args) => [{ type: 'Monthly-Setting' }],
    }),
    getBusinessMainActivity: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/business-main-activity/all`,
      }),
      providesTags: (_resut, _error, id) => [{ type: 'BusinessMainActivity' }],
    }),
    createBusinessMainActivity: builder.mutation({
      query: (businessActivity) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/business-main-activity/create`,
        method: 'POST',
        body: businessActivity,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'BusinessMainActivity' }],
    }),
    updateBusinessMainActivity: builder.mutation({
      query: (BusinessMainActivity) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/business-main-activity/update`,
        method: 'PUT',
        body: BusinessMainActivity,
      }),
      invalidatesTags: (result, error, args) => [{ type: 'BusinessMainActivity' }],
    }),
    deleteBusinessMainActivity: builder.mutation({
      query: (businessMainActivityId) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/business-main-activity/delete/${businessMainActivityId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, args) => [{ type: 'BusinessMainActivity' }],
    }),
    getKscore: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/kscore/all`,
      }),
      providesTags: (_resut, _error, id) => [{ type: 'Kscore' }],
    }),
    createKscore: builder.mutation({
      query: (kscore) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/kscore/create`,
        method: 'POST',
        body: kscore,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Kscore' }],
    }),
    updateKscore: builder.mutation({
      query: (kscore) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/kscore/update`,
        method: 'PUT',
        body: kscore,
      }),
      invalidatesTags: (result, error, args) => [{ type: 'Kscore' }],
    }),
    deleteKscore: builder.mutation({
      query: (kscoreId) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/kscore/delete/${kscoreId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, args) => [{ type: 'Kscore' }],
    }),
    getRelationship: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/relationship/all`,
      }),
      providesTags: (_resut, _error, id) => [{ type: 'Relationship' }],
    }),
    createRelationship: builder.mutation({
      query: (relationship) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/relationship/create`,
        method: 'POST',
        body: relationship,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Relationship' }],
    }),
    updateRelationship: builder.mutation({
      query: (relationship) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/relationship/update`,
        method: 'PUT',
        body: relationship,
      }),
      invalidatesTags: (result, error, args) => [{ type: 'Relationship' }],
    }),
    deleteRelationship: builder.mutation({
      query: (relationshipId) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/relationship/delete/${relationshipId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, args) => [{ type: 'Relationship' }],
    }),
  }),
});

export const {
  useGetOfficeHoursQuery,
  useUpdateOfficeHourMutation,
  useGetHolidaysQuery,
  useAddNewHolidayMutation,
  useDeleteHolidayMutation,
  useGetProfileQuery,
  useAddNewProfileMutation,
  useUpdateProfileMutation,
  useGetPasswordPolicyQuery,
  useUpdatePasswordPolicyMutation,
  useGetRestructureLimitQuery,
  useUpdateRestructureLimitMutation,
  useGetTagsQuery,
  useAddNewTagMutation,
  useDeleteTagMutation,
  useUpdateClaimPaymentMutation, //update claim payment due days
  useGetClaimPaymentQuery, //List Claim Payment Setting
  useGetELGSettingQuery,
  useUpdateELGSettingMutation,
  useGetRenewalSettingQuery,
  useUpdateRenewalSettingMutation,
  useGetUpdateBizRegistrationSettingQuery,
  useUpdateUpdateBizRegistrationSettingMutation,
  useGetExpireGASettingQuery,
  useUpdateExpireGASettingMutation,
  useGetBroadcastsQuery,
  useGetBroadcastByIdQuery,
  useGetBroadcastAnnouncementQuery,
  useCreateNewBroadcastMutation,
  useUpdateBroadcastMutation,
  useDeleteBroadcastMutation,
  useGetMonthlyUploadingDeadlineQuery, //List Monthly Deadline Setting
  useUpdateMonthlyUploadingDeadlineMutation, //Create and Update Monthly Deadline Setting
  useCreateBusinessMainActivityMutation,
  useDeleteBusinessMainActivityMutation,
  useGetBusinessMainActivityQuery,
  useUpdateBusinessMainActivityMutation,
  useCreateKscoreMutation,
  useDeleteKscoreMutation,
  useGetKscoreQuery,
  useUpdateKscoreMutation,
  useCreateRelationshipMutation,
  useDeleteRelationshipMutation,
  useGetRelationshipQuery,
  useUpdateRelationshipMutation,
} = extendedApi;
