import { Box, Checkbox, Dialog, DialogContent, DialogTitle, IconButton, Typography, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetBroadcastAnnouncementQuery } from 'redux/api/GeneralSetting';
import { clearBroadcastMessage, setBroadcastMessage } from 'redux/reducers/Users';
import RTE from '@component/RTE';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
    },
  },
  title: { backgroundColor: theme.palette.primary.main, color: 'white', padding: '8px 16px 8px 16px' },
  content: { padding: '8px 16px 8px 16px' },
}));

const BroadcastMessageDialog = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const broadcastMessage = useSelector((state) => state?.usersReducer?.broadcastMessage);
  const justLogin = useSelector((state) => state?.usersReducer?.justLogin);

  const { data: announcement } = useGetBroadcastAnnouncementQuery(null, { skip: !justLogin });

  useEffect(() => {
    if (!!announcement) {
      dispatch(setBroadcastMessage(announcement));
    }
  }, [announcement]);

  const close = () => {
    dispatch(clearBroadcastMessage());
  };

  const isDisableToday = (broadcastMessage) => {
    if (broadcastMessage?.isTest) {
      return false;
    }

    return localStorage.getItem('disableBroadcastMessage') === moment().format('L') + '-' + broadcastMessage?.id;
  };

  useEffect(() => {
    if (!!broadcastMessage && isDisableToday(broadcastMessage)) {
      console.log(`BroadcastMessage ${broadcastMessage?.name} is disabled to Today.`);
      close();
    }
  }, [broadcastMessage]);

  return (
    <Dialog open={!!broadcastMessage && !isDisableToday(broadcastMessage)} maxWidth="md">
      <DialogTitle className={classes.title}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{broadcastMessage?.subject}</Typography>
          <IconButton size="small" edge="end" style={{ color: 'white' }} onClick={close}>
            <CancelIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <RTE value={broadcastMessage?.message} readOnly={true} />

        <Box display="flex" alignItems="center" justifyContent="center">
          <Checkbox
            onChange={(event, checked) => {
              if (broadcastMessage?.isTest === true) {
                return;
              }

              if (broadcastMessage?.id) {
                if (checked) {
                  localStorage.setItem('disableBroadcastMessage', moment().format('L') + '-' + broadcastMessage?.id);
                } else {
                  localStorage.removeItem('disableBroadcastMessage');
                }
              }
            }}
          />
          Don't show this message again today
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BroadcastMessageDialog;
