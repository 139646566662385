import React, { useEffect } from 'react';
import { Box, IconButton, makeStyles, Popover, Tooltip, useTheme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtList from '@coremat/CmtList';
import CmtCard from '@coremat/CmtCard';
import NotificationItem from './NotificationItem';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { socket } from 'services/socket/noti';
import ErrorBoundary from '@component/ErrorBoundary';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    position: 'relative',
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20,
  },
  scrollbarRoot: {
    height: 300,
    // padding: 16,
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 375,
    },
  },
  unReadBox: {
    // backgroundColor: theme.palette.background.default,
    // backgroundColor: '#b1decd',
    backgroundColor: '#7dcc7c',
  },
  notiBox: {
    // backgroundColor: theme.palette.background.default,
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const actions = [
  {
    code: 'mark_all_as_read',
    label: 'Mark All as Read',
  },
  {
    code: 'close',
    label: 'Close',
  },
];

// const headerNotifications = [];

const HeaderNotifications = () => {
  const classes = useStyles();
  const [notifications, setNotifications] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [counter, setCounter] = React.useState(0);
  const theme = useTheme();
  const { authUser } = useSelector(({ auth }) => auth);

  const onOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const actionHandler = (type) => {
    if (type?.code === 'mark_all_as_read') {
      socket.emit('readAllNoti');
    } else if (type?.code === 'close') {
      onClosePopOver();
    }
  };

  useEffect(() => {
    socket.on('newNoti', (noti) => {
      setNotifications((prev) => {
        const existingIndex = prev.findIndex((item) => item.id === noti.id);
        if (existingIndex === -1) {
          return [noti, ...prev];
        } else {
          prev[existingIndex] = noti;
          return [...prev];
        }
      });
    });

    socket.on('existingNoti', (noti) => {
      if (Array.isArray(noti)) {
        setNotifications(noti);
      }
    });

    socket.on('oldNoti', (noti) => {
      if (Array.isArray(noti)) {
        setNotifications((prev) => [...prev, ...noti]);
      }
    });

    socket.on('unreadCount', (count) => {
      setCounter(count);
    });

    socket.on('readAllNoti', () => {
      setNotifications((prev) => prev?.map((item) => ({ ...item, read: true })));
    });

    socket.emit('init');
    return () => {
      socket.removeAllListeners('newNoti');
      socket.removeAllListeners('existingNoti');
      socket.removeAllListeners('oldNoti');
      socket.removeAllListeners('unreadCount');
      socket.removeAllListeners('readAllNoti');
    };
  }, []);

  // useEffect(() => {
  //   setCounter(notifications.filter((item) => !item?.read).length);
  // }, [notifications]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box pr={2}>
      <Tooltip title="Notifications">
        <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, 'Cmt-appIcon', {
            active: counter > 0,
          })}>
          <Badge overlap="rectangular" badgeContent={counter} classes={{ badge: classes.counterRoot }}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Notifications"
            actionsPos="top-corner"
            actions={actions}
            actionHandler={actionHandler}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <CmtCardContent>
            {notifications.length > 0 ? (
              <PerfectScrollbar
                className={classes.scrollbarRoot}
                onYReachEnd={(item) => {
                  socket.emit('findOldNoti', notifications[notifications.length - 1]?._id);
                }}>
                <CmtList
                  data={notifications}
                  renderRow={(item, index) => (
                    <Box
                      key={index}
                      className={clsx([classes.notiBox, !item.read && classes.unReadBox])}
                      onClick={() => {
                        socket.emit('readNoti', item.id);
                      }}>
                      <ErrorBoundary>
                        <NotificationItem item={item} />
                      </ErrorBoundary>
                    </Box>
                  )}
                />
              </PerfectScrollbar>
            ) : (
              <Box p={6}>
                <Typography variant="body2">No notifications found</Typography>
              </Box>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
