import {
  GUARANTOR_CREATE,
  GUARANTOR_DEL,
  GUARANTOR_GET,
  GUARANTOR_UPDATE,
} from '../../@jumbo/constants/GuarantorActionType';

const INIT_STATE = {
  guarantor: [],
  scheme: {},
};

export default (state = INIT_STATE, action) => {
  let returnData = action.payload;
  let guarantor = state.guarantor;
  switch (action.type) {
    case GUARANTOR_GET: {
      let result = returnData;
      return {
        ...state,
        guarantor: result,
      };
    }
    case GUARANTOR_CREATE: {
      guarantor.push(returnData);
      return {
        ...state,
        guarantor: [...guarantor],
      };
    }
    case GUARANTOR_UPDATE: {
      let findIndex = guarantor.findIndex((item) => item._id == returnData._id);
      guarantor[findIndex] = returnData;
      return {
        ...state,
        guarantor: [...guarantor],
      };
    }

    case GUARANTOR_DEL: {
      return {
        ...state,
        guarantor: state.guarantor.filter((guarantor) => guarantor._id !== action.payload),
      };
    }
    default:
      return state;
  }
};
