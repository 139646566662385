import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  pfis: [],
  selectedPFI: null,
  currentPfi: null,
};

const pfiSlice = createSlice({
  name: 'pfis',
  initialState,
  reducers: {
    setPFIs: (state, action) => {
      state.pfis = action.payload;
    },
    addPFI: (state, action) => {
      state.pfis.push(action.payload);
    },
    editPFI: (state, action) => {
      state.pfis = state.pfis.map((pfi) => (pfi.id === action.payload.id ? action.payload : pfi));
    },
    deletePFI: (state, action) => {
      state.pfis = state.pfis.filter((pfi) => pfi.id !== action.payload);
    },
    setCurrentPFI: (state, action) => {
      state.currentPfi = action.payload;
    },
    selectPFI: (state, action) => {
      state.selectedPFI = action.payload;
    },
  },
});

export const { setPFIs, addPFI, editPFI, deletePFI, setCurrentPFI, selectPFI } = pfiSlice.actions;

export default pfiSlice.reducer;
