import React from 'react';
import * as yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useFormik } from 'formik';
import FlatTextField from '@component/TextField';
import { useCreateShortcutMutation } from 'redux/api/User';
import { fetchError, fetchSuccess } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { listIcons } from './AppsMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import { COLOR_BACKGROUND } from 'routes/CreateApp/data';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
  dialogActionsRoot: {
    padding: '16px 24px',
  },
}));

const validationSchema = yup.object({
  name: yup.string().required('Name is required.'),
  path: yup
    .string()
    .required('Path is required.')
    .test('is-valid-url', 'URL must match the current URL', function (value) {
      // Get the current URL
      const currentURL = window.location.hostname;
      // Check if the URL starts with "http" and matches the current URL
      if (value && value.startsWith('http')) {
        const url = new URL(value);
        if (url.hostname === currentURL) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }),
});
const CreateShortcutPopup = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [createShortcut] = useCreateShortcutMutation();
  const formik = useFormik({
    initialValues: {
      name: '',
      path: '',
      photo: 1,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await createShortcut(values).unwrap();
        dispatch(fetchSuccess('Create Shortcut Successfully'));
        onClose();
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    },
  });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-shortcut"
      className={classes.dialogRoot}
      maxWidth="sm"
      fullWidth>
      <DialogTitle id="create-shortcut" className={classes.dialogTitleRoot}>
        Create Shortcut
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <form>
            <Grid container spacing={4}>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="textSecondary">
                  Name
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <FlatTextField
                  name="name"
                  fullWidth
                  placeholder="Type in name"
                  required={true}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>

              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="textSecondary">
                  Path
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <FlatTextField
                  name="path"
                  fullWidth
                  placeholder="Type in path"
                  required={true}
                  value={formik.values.path}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.path && formik.errors.path}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gridGap={10}>
                  {listIcons.map((icon) => (
                    <CmtAvatar
                      size={46}
                      style={{ ...(formik.values.photo === icon.id && { backgroundColor: COLOR_BACKGROUND }) }}
                      onClick={() => {
                        formik.setFieldValue('photo', icon.id);
                      }}>
                      {icon.icon}
                    </CmtAvatar>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={() => formik.handleSubmit()} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CreateShortcutPopup;
