import {
  SHAREHOLDER_CREATE,
  SHAREHOLDER_DEL,
  SHAREHOLDER_GET,
  SHAREHOLDER_UPDATE,
} from '../../@jumbo/constants/ShareholderActionType';

const INIT_STATE = {
  shareholder: [],
  scheme: {},
};

export default (state = INIT_STATE, action) => {
  let returnData = action.payload;
  let shareholder = state.shareholder;
  switch (action.type) {
    case SHAREHOLDER_GET: {
      let result = returnData;
      return {
        ...state,
        shareholder: result,
      };
    }
    case SHAREHOLDER_CREATE: {
      shareholder.push(returnData);
      return {
        ...state,
        shareholder: [...shareholder],
      };
    }
    case SHAREHOLDER_UPDATE: {
      let findIndex = shareholder.findIndex((item) => item._id == returnData._id);
      shareholder[findIndex] = returnData;
      return {
        ...state,
        shareholder: [...shareholder],
      };
    }

    case SHAREHOLDER_DEL: {
      return {
        ...state,
        shareholder: state.shareholder.filter((shareholder) => shareholder._id !== action.payload),
      };
    }
    default:
      return state;
  }
};
