import CmtAvatar from '@coremat/CmtAvatar';
import CmtMediaObject from '@coremat/CmtMediaObject';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { fetchError } from '../../../../../../../redux/actions';
import IntlMessages from '../../../../../../utils/IntlMessages';
import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { BILLING_DATETIME_FORMAT } from '../../../../../../../@component/constants/Billing';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
  navMenuLink: { color: theme.palette.text.primary },
}));

const Invoice = ({ item }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const getLink = (payload) => {
    if (payload.billingTag === 'invoice') {
      history.push(`/finance/invoice-management/all-invoice/${payload.id}/${payload.currency}/invoice`);
    } else if (payload.billingTag === 'credit_note') {
      history.push(`/finance/invoice-management/all-invoice/${payload.id}/${payload.currency}/credit_noted`);
    } else if (payload.billingTag === 'skipped_invoice') {
      history.push(`/finance/invoice-management/all-invoice/${payload.id}/${payload.currency}/skipped/invoice`);
    } else if (payload.billingTag === 'skipped_credit_note') {
      history.push(`/finance/invoice-management/all-invoice/${payload.id}/${payload.currency}/skipped/credit_noted`);
    } else {
      dispatch(fetchError(<IntlMessages id="invoice.not.found" />));
    }
  };

  return (
    <CmtMediaObject
      onClick={() => {
        getLink(item?.payload);
      }}
      avatarPos="center"
      avatar={<CmtAvatar size={40} src="/images/avator/avator3.jpg" />}
      title={
        <Typography component="div" variant="h5" className={classes.titleRoot}>
          <Box component="span" color="blue">
            {item?.payload?.content}
          </Box>
        </Typography>
      }
      subTitle={
        <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
          <Box ml={2}>{moment(item?.createdAt).format(BILLING_DATETIME_FORMAT)}</Box>
        </Box>
      }
    />
  );
};

export default Invoice;
