import { ADD_ALERT, FETCH_ERROR, FETCH_ERROR_10s, FETCH_START, FETCH_SUCCESS, GET_ADDRESS, REMOVE_ALERT } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  initialURL: '/',
  error: '',
  errorExt: '',
  message: '',
  loading: false,
  address: [],
  dialogMessage: ''
};

const Common = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', loading: false, message: action.payload };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, message: '', error: action.payload };
    }
    case FETCH_ERROR_10s: {
      return { ...state, loading: false, errorExt: action.payload };
    }
    case GET_ADDRESS: {
      return { ...state, address: action.payload };
    }
    case ADD_ALERT: {
      return { ...state, dialogMessage: action.payload };
    }
    case REMOVE_ALERT: {
      return { ...state, dialogMessage: "" };
    }
    default:
      return state;
  }
};

export default Common;
