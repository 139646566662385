import { emptySplitApi } from './index';

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report`,
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_DETAIL', id: 'LIST' },
        ...result?.items?.map(({ id }) => ({ type: 'REPORT_DETAIL', id })),
      ],
    }),
    getReportDepartments: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/department`,
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_DEPARTMENT', id: 'LIST' },
        ...result?.items?.map(({ id }) => ({ type: 'REPORT_DEPARTMENT', id })),
      ],
    }),
    getReportTypes: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/type`,
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_TYPE', id: 'LIST' },
        ...result?.items?.map(({ id }) => ({ type: 'REPORT_TYPE', id })),
      ],
    }),
    getReportTemplates: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/template`,
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_TEMPLATE', id: 'LIST' },
        ...result?.items?.map(({ id }) => ({ type: 'REPORT_TEMPLATE', id })),
      ],
    }),
    getReportRecurSettings: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/recur-setting`,
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_RECUR_SETTING', id: 'LIST' },
        ...result?.items?.map(({ id }) => ({ type: 'REPORT_RECUR_SETTING', id })),
      ],
    }),
    getReportPurgeSettings: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/purge-setting`,
        params,
      }),
      providesTags: (result = []) => [
        { type: 'REPORT_PURGE_SETTING', id: 'LIST' },
        ...result?.items?.map(({ id }) => ({ type: 'REPORT_PURGE_SETTING', id })),
      ],
    }),
    getReportDepartmentById: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/department/${id}`,
      }),
      providesTags: (result, error, { id }) => [{ type: 'REPORT_DEPARTMENT', id }],
    }),
    getReportTypeById: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/type/${id}`,
      }),
      providesTags: (result, error, { id }) => [{ type: 'REPORT_TYPE', id }],
    }),
    getReportTemplateById: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/template/${id}`,
      }),
      providesTags: (result, error, { id }) => [{ type: 'REPORT_TEMPLATE', id }],
    }),
    getReportById: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/${id}`,
      }),
      providesTags: (result, error, { id }) => [{ type: 'REPORT_DETAIL', id }],
    }),
    getReportRecurSettingById: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/recur-setting/${id}`,
      }),
      providesTags: (result, error, { id }) => [{ type: 'REPORT_RECUR_SETTING', id }],
    }),
    getReportPurgeSettingById: builder.query({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/purge-setting/${id}`,
      }),
      providesTags: (result, error, { id }) => [{ type: 'REPORT_PURGE_SETTING', id }],
    }),
    addNewReportDepartment: builder.mutation({
      query: (department) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/department`,
        method: 'POST',
        body: department,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_DEPARTMENT', id: 'LIST' }],
    }),
    addNewReportType: builder.mutation({
      query: (type) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/type`,
        method: 'POST',
        body: type,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_TYPE', id: 'LIST' }],
    }),
    addNewReportTemplate: builder.mutation({
      query: (template) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/template`,
        method: 'POST',
        body: template,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_TEMPLATE', id: 'LIST' }],
    }),
    addNewReportRecurSetting: builder.mutation({
      query: (type) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/recur-setting`,
        method: 'POST',
        body: type,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_RECUR_SETTING', id: 'LIST' }],
    }),
    addNewReportPurgeSetting: builder.mutation({
      query: (type) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/purge-setting`,
        method: 'POST',
        body: type,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_PURGE_SETTING', id: 'LIST' }],
    }),
    updateReportDepartment: builder.mutation({
      query: (department) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/department`,
        method: 'PATCH',
        body: department,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_DEPARTMENT', id: 'LIST' }],
      async onQueryStarted(department, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedDepartment } = await queryFulfilled;
          dispatch(extendedApi.util.updateQueryData('getReportDepartmentById', department.id, (draft) => updatedDepartment));
        } catch (error) {}
      },
    }),
    updateReportType: builder.mutation({
      query: (type) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/type`,
        method: 'PATCH',
        body: type,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_TYPE', id: 'LIST' }],
      async onQueryStarted(type, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedType } = await queryFulfilled;
          dispatch(extendedApi.util.updateQueryData('getReportTypeById', type.id, (draft) => updatedType));
        } catch (error) {}
      },
    }),
    updateReportTemplate: builder.mutation({
      query: (template) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/template`,
        method: 'PATCH',
        body: template,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_TEMPLATE', id: 'LIST' }],
      async onQueryStarted(template, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTemplate } = await queryFulfilled;
          dispatch(extendedApi.util.updateQueryData('getReportTemplateById', template.id, (draft) => updatedTemplate));
        } catch (error) {}
      },
    }),
    updateReportRecurSetting: builder.mutation({
      query: (setting) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/recur-setting`,
        method: 'PATCH',
        body: setting,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_RECUR_SETTING', id: 'LIST' }],
      async onQueryStarted(setting, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSetting } = await queryFulfilled;
          dispatch(extendedApi.util.updateQueryData('getReportRecurSettingById', setting.id, (draft) => updatedSetting));
        } catch (error) {}
      },
    }),
    updateReportPurgeSetting: builder.mutation({
      query: (setting) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/purge-setting`,
        method: 'PATCH',
        body: setting,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_PURGE_SETTING', id: 'LIST' }],
      async onQueryStarted(setting, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSetting } = await queryFulfilled;
          dispatch(extendedApi.util.updateQueryData('getReportPurgeSettingById', setting.id, (draft) => updatedSetting));
        } catch (error) {}
      },
    }),
    deleteReport: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_DETAIL' }],
    }),
    deleteReportDepartment: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/department/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_DEPARTMENT' }],
    }),
    deleteReportType: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/type/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_TYPE' }],
    }),
    deleteReportTemplate: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/template/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_TEMPLATE' }],
    }),
    deleteReportRecurSetting: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/recur-setting/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_RECUR_SETTING' }],
    }),
    deleteReportPurgeSetting: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/purge-setting/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_PURGE_SETTING' }],
    }),
    getMatabaseDashboard: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_REPORT_SERVICE}/report/metabase/CGCCDashboard/${id}`,
        method: 'GET',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'REPORT_DASHBOARD' }],
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetReportDepartmentsQuery,
  useGetReportTypesQuery,
  useGetReportTemplatesQuery,
  useGetReportRecurSettingsQuery,
  useGetReportPurgeSettingsQuery,
  useGetReportDepartmentByIdQuery,
  useGetReportTypeByIdQuery,
  useGetReportTemplateByIdQuery,
  useGetReportByIdQuery,
  useGetReportRecurSettingByIdQuery,
  useGetReportPurgeSettingByIdQuery,
  useAddNewReportDepartmentMutation,
  useAddNewReportTypeMutation,
  useAddNewReportTemplateMutation,
  useAddNewReportRecurSettingMutation,
  useAddNewReportPurgeSettingMutation,
  useUpdateReportDepartmentMutation,
  useUpdateReportTypeMutation,
  useUpdateReportTemplateMutation,
  useUpdateReportRecurSettingMutation,
  useUpdateReportPurgeSettingMutation,
  useDeleteReportMutation,
  useDeleteReportDepartmentMutation,
  useDeleteReportTemplateMutation,
  useDeleteReportTypeMutation,
  useDeleteReportRecurSettingMutation,
  useDeleteReportPurgeSettingMutation,
  useGetMatabaseDashboardMutation,
} = extendedApi;
