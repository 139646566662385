import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: '30px 16px 12px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
    minHeight: 64,
    [theme.breakpoints.up('md')]: {
      minHeight: 72,
    },
    backgroundColor: theme.palette.primary.main,
    marginBottom: '20px',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      justifyContent: 'left',
      paddingLeft: 16,
    },
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
  headerText: {
    color: 'white',
    whiteSpace: 'nowrap',
    paddingLeft: 8,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      visibility: 'hidden',
    },
  },
}));

const SidebarHeaderLogo = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });

  return (
    <div className={classes.root}>
      <CmtAvatar src={'/images/cgcc-symbol.png'} alt="User Avatar" height={60} />
      <Typography component="h3" variant="h6" className={classes.headerText}>
        <Box fontWeight="fontWeightBold">Guarantee Management System</Box>
        {/* <Box fontWeight="fontWeightBold">of Cambodia Plc.</Box> */}
      </Typography>
    </div>
  );
};

export default SidebarHeaderLogo;
