import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MessageIcon from '@material-ui/icons/Message';
import ShareIcon from '@material-ui/icons/Share';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import AttachmentIcon from '@material-ui/icons/Attachment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core';
import OtherContent from './Templates/OtherContent';
import GA from './Templates/GA';
import MonthlyReport from './Templates/MonthlyReport';
import LG from './Templates/LG';
import LGChangeRequest from './Templates/LGChangeRequest';
import SpecialMention from './Templates/SpecialMention';
import NPL from './Templates/NPL';
import Claim from './Templates/Claim';
import ClaimPayment from './Templates/ClaimPayment';
import Recovery from './Templates/Recovery';
import WriteOff from './Templates/WriteOff';
import DraftInvoice from './Templates/DraftInvoice';
import Invoice from './Templates/Invoice';
import Report from './Templates/Report';
import PaymentReceived from './Templates/PaymentReceived';
import BillingRule from './Templates/BillingRule';
import EInvoice from './Templates/EInvoice';

const useStyles = makeStyles((theme) => ({
  feedItemRoot: {
    padding: '10px 0',
    position: 'relative',
    borderBottom: `1px solid ${alpha(theme.palette.common.dark, 0.035)}`,
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
    '& .Cmt-media-image': {
      alignSelf: 'flex-start',
      width: 56,
    },
    '& .Cmt-media-body': {
      width: 'calc(100% - 56px)',
      flex: 'inherit',
    },
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
}));

const icons = {
  POSTING: <MessageIcon style={{ color: '#836AFF' }} />,
  SHARED_POST: <ShareIcon style={{ color: '#0795F4' }} />,
  INVITATION: <EmailIcon style={{ color: '#00C4B4' }} />,
  BIRTHDAY: <CakeIcon style={{ color: '#EF933C' }} />,
  OTHER: <AttachmentIcon style={{ color: '#0795F4' }} />,
};

const getPostContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="primary.main">
      {item.user.name}
    </Box>
    <Box component="span" ml={1}>
      has recently posted an
    </Box>
    <Box component="span" ml={1}>
      {item.metaData.post.type}
    </Box>
  </Typography>
);

const getSharedContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="primary.main">
      {item.user.name}
    </Box>
    <Box component="span" ml={1}>
      has shared
    </Box>
    <Box component="span" ml={1} color="primary.main">
      {`${item.metaData.post.owner.name}'s`}
    </Box>
    <Box component="span" ml={1}>
      post.
    </Box>
  </Typography>
);

const getBirthdayContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="blue">
      {item.user.name}
    </Box>
    <Box component="span" ml={1}>
      has birthday today.
    </Box>
  </Typography>
);

const getInvitationContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="blue">
      {item.metaData.user.name}
    </Box>
    <Box component="span" ml={1}>
      has sent you a group invitation for
    </Box>
    <Box component="span" color="blue" ml={1}>
      {item.metaData.group.name}
    </Box>
  </Typography>
);

const NotificationItem = ({ item }) => {
  const classes = useStyles();

  const getContent = () => {
    switch (item.template) {
      case 'POSTING':
        return getPostContent(item, classes);
      case 'SHARED_POST':
        return getSharedContent(item, classes);
      case 'INVITATION':
        return getInvitationContent(item, classes);
      case 'BIRTHDAY':
        return getBirthdayContent(item, classes);
      case 'GA':
        return <GA item={item} />;
      case 'MONTHLY_REPORT':
        return <MonthlyReport item={item} />;
      case 'LG':
        return <LG item={item} />;
      case 'LG_CHANGE_REQUEST':
        return <LGChangeRequest item={item} />;
      case 'SPECIAL_MENTION':
        return <SpecialMention item={item} />;
      case 'NPL':
        return <NPL item={item} />;
      case 'CLAIM':
        return <Claim item={item} />;
      case 'DRAFT_CLAIM_PAYMENT':
      case 'CLAIM_PAYMENT':
        return <ClaimPayment item={item} />;
      case 'RECOVERY':
        return <Recovery item={item} />;
      case 'WRITE_OFF':
        return <WriteOff item={item} />;
      case 'DRAFT_INVOICE':
        return <DraftInvoice item={item} />;
      case 'INVOICE':
        return <Invoice item={item} />;
      case 'REPORT':
        return <Report item={item} />;
      case 'DRAFT_PAYMENT_RECEIVED':
      case 'PAYMENT_RECEIVED':
        return <PaymentReceived item={item} />;
      case 'BILLING_RULE':
        return <BillingRule item={item} />;
      case 'E_INVOICE':
        return <EInvoice item={item} />;
      case 'OTHER':
        return <OtherContent item={item} />;
      default:
        return '';
    }
  };

  return <Box className={classes.feedItemRoot}>{getContent()}</Box>;

  // const getTitle = (item, classes) => {
  //   switch (item.type) {
  //     case 'POSTING':
  //       return getPostContent(item, classes);
  //     case 'SHARED_POST':
  //       return getSharedContent(item, classes);
  //     case 'INVITATION':
  //       return getInvitationContent(item, classes);
  //     case 'BIRTHDAY':
  //       return getBirthdayContent(item, classes);
  //     case 'OTHER':
  //       return (
  //         <Typography component="div" variant="h5" className={classes.titleRoot}>
  //           {item.title}
  //         </Typography>
  //       );
  //     default:
  //       return '';
  //   }
  // };

  // const getSubTitle = () => (
  //   <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
  //     <Box fontSize={16} clone>
  //       {icons[item.type]}
  //     </Box>
  //     <Box ml={2}>{getDateElements(item.date).time}</Box>
  //   </Box>
  // );

  // return (
  //   <Box className={classes.feedItemRoot}>
  //     <CmtMediaObject
  //       avatarPos="center"
  //       avatar={<CmtAvatar size={40} src={item.user.profile_pic} alt={item.user.name} />}
  //       title={getTitle(item, classes)}
  //       subTitle={getSubTitle()}
  //     />
  //   </Box>
  // );
};

export default NotificationItem;
