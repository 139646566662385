import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import SBDMetabaseDashboard from 'modules/SBDDashboard/index';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  heading: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
  },
  TabButton: {
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
    fontSize: 14,
    width: 50,
    height: 36,
    fontFamily: 'Roboto',
  },
  normalTab: {
    backgroundColor: 'white',
  },
}));

const PGPage = ({ history }) => {
  const classes = useStyles();

  const heading = () => {
    return (
      <Box style={{ marginBottom: 10, fontSize: 24 }} className={classes.heading}>
        CGCC Dashboard
      </Box>
    );
  };

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);
  return (
    <Box className={classes.root}>
      <PageContainer heading={heading()}>
        <Box>
          <SBDMetabaseDashboard />
        </Box>
      </PageContainer>
    </Box>
  );
};

export default PGPage;
