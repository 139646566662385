import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import KeycloakAuth from 'services/auth/Keycloak';

const useStyles = makeStyles((theme) => ({
  sideNavMenu: {
    position: 'relative',
  },
}));

const hasAccess = (every = [], some = []) => {
  if (every.length > 0) {
    return KeycloakAuth.hasEveryRoles(every);
  }
  if (some.length > 0) {
    return KeycloakAuth.hasSomeRole(some);
  }
  return true;
};

const hasPermission = (every = [], some = []) => {
  if (every.length > 0) {
    return KeycloakAuth.hasEveryPermission(every);
  }
  if (some.length > 0) {
    return KeycloakAuth.hasSomePermission(some);
  }
  return true;
};

export const isMenuAllowed = (item) => {
  if (!!item?.userType && item.userType !== KeycloakAuth.getUserType()) {
    return false;
  }

  if (!!item?.access) {
    return hasAccess(item.access?.every, item.access?.some);
  }
  if (!!item?.permission) {
    return hasPermission(item.permission?.every, item.permission?.some);
  }
  return true;
};

const CmtVertical = (props) => {
  const { menuItems } = props;
  const classes = useStyles();
  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {menuItems.map((item, index) => {
        if (!isMenuAllowed(item)) {
          return null;
        }
        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          case 'item':
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default CmtVertical;
