import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import KeycloakAuth from 'services/auth/Keycloak';

const renderApp = () => ReactDOM.render(<App />, document.getElementById('root'));

KeycloakAuth.init(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
