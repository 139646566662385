import { ADD_ALERT, FETCH_ERROR, FETCH_ERROR_10s, FETCH_START, FETCH_SUCCESS, GET_ADDRESS, REMOVE_ALERT } from '../../@jumbo/constants/ActionTypes';

export const fetchSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    });
  };
};
export const fetchError = (error) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchError10s = (error) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ERROR_10s,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_START,
    });
  };
};
export const setAddress = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_ADDRESS,
      payload: data,
    });
  };
};

export const setAlert = (message) => {
  return (dispatch) => {
    dispatch({
      type: ADD_ALERT,
      payload: message || '',
    });
  };
};
export const closeAlert = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ALERT,
    });
  };
};