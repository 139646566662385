import {
  BORROWER_CREATE,
  BORROWER_DEL,
  BORROWER_GET,
  BORROWER_UPDATE,
  BORROWER_GET_ONE,
  BORROWER_GET_COMPANY_RELATIONSHIP,
  BORROWER_GET_LOAN,
  BORRORER_GET_GUARANTOR,
} from '../../@jumbo/constants/BorrwerActionType';

const INIT_STATE = {
  borrowers: [],
  scheme: {},
};

export default (state = INIT_STATE, action) => {
  let returnData = action.payload;
  let borrowers = state.borrowers;
  switch (action.type) {
    case BORROWER_GET: {
      let result = returnData;
      return {
        ...state,
        borrowers: result,
      };
    }
    case BORROWER_GET_ONE: {
      let result = returnData;
      return {
        ...state,
        borrower: result,
      };
    }
    case BORROWER_GET_COMPANY_RELATIONSHIP: {
      let result = returnData;
      return {
        ...state,
        company_relationship: result,
      };
    }
    case BORROWER_GET_LOAN: {
      let result = returnData;
      return {
        ...state,
        loans: result,
      };
    }
    case BORRORER_GET_GUARANTOR: {
      let result = returnData;
      return {
        ...state,
        guarantors: result,
      };
    }
    case BORROWER_CREATE: {
      borrowers.push(returnData);
      return {
        ...state,
        borrowers: [...borrowers],
      };
    }
    case BORROWER_UPDATE: {
      let findIndex = borrowers.findIndex((item) => item._id == returnData._id);
      borrowers[findIndex] = returnData;
      return {
        ...state,
        borrowers: [...borrowers],
      };
    }

    case BORROWER_DEL: {
      return {
        ...state,
        borrowers: state.borrowers.filter((borrowers) => borrowers._id !== action.payload),
      };
    }
    default:
      return state;
  }
};
