import axios from 'axios';

export const fileUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_PFI_SERVICE}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const cgccFileUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_CGCC_SERVICE}/cgcc/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const signedLGUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_LG_SERVICE}/lg/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const excelLGUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_LG_REPORT_SERVICE}/monthly-report/upload_lg_report`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const guaranteeAttachmentUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/attachment/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const lgAttachmentUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_LG_SERVICE}/lg/attachment/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const cifAttachmentUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/attachment/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const riskManualAttachmentUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_CIF_SERVICE}/cif/attachment/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const loanMonitoringUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(
    `${process.env.REACT_APP_LG_REPORT_SERVICE}/monthly-report/attachment/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    },
  );
  return data;
};

export const fileUploadGMS = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_GSM_SERVICE_UI}/files/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const claimRequestUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_CLAIM_SERVICE}/claim/attachment/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const paymentReceivedUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_BILLING_SERVICE}/billing/attachment/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const billingUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_BILLING_SERVICE}/billing/attachment/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const recoveryRequestUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_RECOVERY_SERVICE}/recovery/attachment/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const writeOffUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_RECOVERY_SERVICE}/recovery/attachment/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const chartOfAccountUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_BILLING_SERVICE}/billing/chartofaccount/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const pgFileUpload = async (file, onUploadProgress) => {
  let formData = new FormData();
  formData.append('files', file);
  const { data } = await axios.post(`${process.env.REACT_APP_PG_SERVICE}/pg/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
  return data;
};

export const pgFileDelete = async (id) => {
  const { data } = await axios.get(`${process.env.REACT_APP_PG_SERVICE}/pg/deleteFile?id=${id}`, {});
  return data;
};
