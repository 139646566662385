import CmtAvatar from '@coremat/CmtAvatar';
import CmtMediaObject from '@coremat/CmtMediaObject';
import { getDateElements } from '@jumbo/utils/dateHelper';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
}));

const OtherContent = ({ item }) => {
  const classes = useStyles();

  return (
    <CmtMediaObject
      onClick={() => {
        console.log('other noti click');
      }}
      avatarPos="center"
      avatar={<CmtAvatar size={40} src="/images/avator/avator3.jpg" />}
      title={
        <Typography component="div" variant="h5" className={classes.titleRoot}>
          <Box component="span" color="blue">
            {item?.payload?.content}
          </Box>
        </Typography>
      }
      subTitle={
        <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
          <Box ml={2}>{getDateElements(item?.createdAt).time}</Box>
        </Box>
      }
    />
  );
};

export default OtherContent;
