import { Delete, Edit, Remove, RemoveCircleOutlined, Visibility } from '@material-ui/icons';
import React from 'react';
import { createTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import StepConnector from '@material-ui/core/StepConnector';
import { Badge } from '@mui/material';
import moment from 'moment';
import store from 'redux/store';
import { setAlert } from 'redux/actions';

export const MAXIMUM_TENURE = 84;

export function getMaximumTenureConfig(guarantee) {
  if (guarantee.guaranteeFeeUnit === 'Y') {
    return +guarantee.maximumTenureNonPriorityY * 12;
  } else {
    return +guarantee.maximumTenureNonPriorityM;
  }
}

export const yesNoList = [
  {
    value: '0',
    desc: 'No',
  },
  {
    value: '1',
    desc: 'Yes',
  },
];

export const MIN_USD = 1;

export const YES_NO_NA_LIST = [
  {
    value: '0',
    desc: 'No',
  },
  {
    value: '1',
    desc: 'Yes',
  },
  {
    value: '2',
    desc: 'Not Available',
  },
];

export const customerRelationshipList = [
  {
    value: '10',
    desc: 'Main Borrower',
  },
  {
    value: '20',
    desc: 'Co-Borrower',
  },
  {
    value: '30',
    desc: 'Representative',
  },
  {
    value: '40',
    desc: 'Guarantor',
  },
];

export const MAX_KSCORE = 1400;

export const scoreRangeList = [
  {
    value: 0,
    desc: '100-580',
    min: 100,
    max: 580,
  },
  {
    value: 1,
    desc: '581-700',
    min: 581,
    max: 700,
  },
  {
    value: 2,
    desc: '701-850',
    min: 701,
    max: 850,
  },
  {
    value: 3,
    desc: '851-1020',
    min: 851,
    max: 1020,
  },
  {
    value: 4,
    desc: '1021-1400',
    min: 1021,
    max: MAX_KSCORE,
  },
];

export const yesNoListKscore = [
  {
    value: '0',
    desc: 'No',
  },
  {
    value: '1',
    desc: 'Yes',
  },
  {
    value: '2',
    desc: 'Not Applicable Option',
  },
];

export const TypeBorrowerInd = [
  {
    name: 'fullName',
    desc: 'Main Borrower Full Name',
  },
  {
    name: 'referenceNumber',
    desc: 'Reference No.',
  },
  {
    name: 'companyName',
    desc: 'Company Name',
  },
  {
    name: 'referenceNumberComp',
    desc: 'Reference No.',
  },
];
export const TypeBorrowerComp = [
  {
    name: 'companyName',
    desc: 'Company Name',
  },
  {
    name: 'referenceNumberComp',
    desc: 'Reference No.',
  },
  {
    name: 'fullName',
    desc: 'Main Borrower Full Name',
  },
  {
    name: 'referenceNumber',
    desc: 'Reference No.',
  },
];

export const SCHEME_DEFAULT_LIST = [
  { title: 'BRGS', value: 'BRGS' },
  { title: 'CFGS-TR', value: 'CFGS-TR' },
  { title: 'WEGS', value: 'WEGS' },
];
export const LOAN_TYPES = [
  { value: 'Term Loan (TL)', code: 0, desc: 'Term Loan (TL)' },
  { value: 'Overdraft (OD)', code: 1, desc: 'Overdraft (OD)' },
  { value: 'Trade Facility (TF)', code: 2, desc: 'Trade Facility (TF)' },
  // { value: 'Revolving', code: 3, desc: 'Revolving' },
];

export const LOAN_PURPOSES = [
  { value: 'Working Capital', code: 0, desc: 'Working Capital' },
  { value: 'Investment/Business Expansion', code: 1, desc: 'Investment/Business Expansion' },
  { value: 'Capital Expenditure', code: 2, desc: 'Capital Expenditure' },
  { value: 'Working Capital and Capital Expenditure', code: 3, desc: 'Working Capital and Capital Expenditure' },
  { value: 'New Investment and/or Business Expansion', code: 4, desc: 'New Investment and/or Business Expansion' },
  {
    value: 'To support the settlement fund to/from True Money wallet.',
    code: 5,
    desc: 'To support the settlement fund to/from True Money wallet.',
  },
  {
    value: 'Working Capital and Investment/Business Expansion',
    code: 6,
    desc: 'Working Capital and Investment/Business Expansion',
  },
  { value: 'Farm Production Loan', code: 7, desc: 'Farm Production Loan' },
  {
    value: 'Short-to-Medium term small business loan for working capital and equipment',
    code: 8,
    desc: 'Short-to-Medium term small business loan for working capital and equipment',
  },
  {
    value: 'Inventory procurement loan/seasonal line of credit (revolving)',
    code: 9,
    desc: 'Inventory procurement loan/seasonal line of credit (revolving)',
  },
  {
    value: 'Term loan for fixed capital investment and working capital',
    code: 10,
    desc: 'Term loan for fixed capital investment and working capital',
  },
];

export const KSCORE_NO_LIST = [
  { value: 'SE1', desc: 'SE1: Individual has no information with bureau' },
  { value: 'SE2', desc: 'SE2: Individual’s age is less than 18 or more than 80' },
  {
    value: 'SE3',
    desc: 'SE3: Individual has no primary account or no valid repayment status',
  },
  {
    value: 'SE4',
    desc: 'SE4: Individual has no active information on bureau of all currencies',
  },
  { value: 'SE5', desc: 'SE5: Individual is deceased' },
  {
    value: 'SE6',
    desc: 'SE6: Individual has latest payment status in 90+ days past due in the last 30 days',
  },
  {
    value: 'SE7',
    desc: 'SE7: Individual’s debt is written off with no active account',
  },
];

export const SECURITY_LIST = [
  'CA: Cash',
  'MO: Mortgage',
  'NO: None',
  'OT: Other (Anything else not found in this list)',
  'SH: Shares',
  'PM: Multiple (Having more than one collateral)',
  'FD: Fixed Deposit',
  'LT: Land Title (Referred to "Hard Title")',
  'TO: Ownership Title-Land/Buildings (Referred to "Soft Title")',
  'FB: Floating Debenture',
  'MV: Motor Vehicle',
  'IN: Inventory',
  'LC: Letter of Credit',
  'CG: Corporate Guarantees',
  'GI: Guarantor',
];
function buildSecuritiesList() {
  let result = [];
  SECURITY_LIST.map((item, idx) => {
    if (item !== 'GI: Guarantor') {
      result.push({
        value: idx,
        desc: item,
      });
    }
  });
  return result;
}
export const typeOfSecurities = buildSecuritiesList();

export const typeOfBorrowerList = [
  {
    value: 'INDIVIDUAL',
    desc: 'Individual',
  },
  {
    value: 'COMPANY',
    desc: 'Legal Entity Company',
  },
];

export const COLOR_BACKGROUND = '#028400';
export const COLOR_BACKGROUND_DISABLED = '#F0F2F5';

export const BUSINESS_TYPES_MST = [
  {
    value: 'Micro',
    desc: 'Micro',
  },
  {
    value: 'SME - Small',
    desc: 'SME - Small',
  },
  {
    value: 'SME - Medium',
    desc: 'SME - Medium',
  },
  {
    value: 'Large Firm',
    desc: 'Large Firm',
  },
];

export const MAX_999999999_STR = '999999999';
export const typeOfBusiness = [
  {
    Value: '0',
    Sec: 'Agriculture',
    MinTurnOver: '0',
    MaxTurnOver: '62249',
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'M',
    MinAsset: '0',
    MaxAsset: '49999',
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'M',
    MinStaff: '0',
    MaxStaff: '5',
    NumStaff_CodeSize: 'M',
  },
  {
    Value: '1',
    Sec: 'Agriculture',
    MinTurnOver: '62250',
    MaxTurnOver: '250000',
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'S',
    MinAsset: '50000',
    MaxAsset: '250000',
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'S',
    MinStaff: '5',
    MaxStaff: '49',
    NumStaff_CodeSize: 'S',
  },
  {
    Value: '2',
    Sec: 'Agriculture',
    MinTurnOver: '250001',
    MaxTurnOver: '1000000',
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'D',
    MinAsset: '250001',
    MaxAsset: '500000',
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'D',
    MinStaff: '50',
    MaxStaff: '199',
    NumStaff_CodeSize: 'D',
  },
  {
    Value: '3',
    Sec: 'Agriculture',
    MinTurnOver: '1000001',
    MaxTurnOver: MAX_999999999_STR,
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'L',
    MinAsset: '500001',
    MaxAsset: MAX_999999999_STR,
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'L',
    MinStaff: '199',
    MaxStaff: MAX_999999999_STR,
    NumStaff_CodeSize: 'L',
  },
  {
    Value: '0',
    Sec: 'Industry',
    MinTurnOver: '0',
    MaxTurnOver: '62249',
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'M',
    MaxAsset: '49999',
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'M',
    MaxStaff: '5',
    NumStaff_CodeSize: 'M',
  },
  {
    Value: '1',
    Sec: 'Industry',
    MinTurnOver: '62250',
    MaxTurnOver: '400000',
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'S',
    MinAsset: '50000',
    MaxAsset: '500000',
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'S',
    MinStaff: '5',
    MaxStaff: '49',
    NumStaff_CodeSize: 'S',
  },
  {
    Value: '2',
    Sec: 'Industry',
    MinTurnOver: '400001',
    MaxTurnOver: '2000000',
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'D',
    MinAsset: '500001',
    MaxAsset: '1000000',
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'D',
    MinStaff: '50',
    MaxStaff: '199',
    NumStaff_CodeSize: 'D',
  },
  {
    Value: '3',
    Sec: 'Industry',
    MinTurnOver: '2000001',
    MaxTurnOver: MAX_999999999_STR,
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'L',
    MinAsset: '1000001',
    MaxAsset: MAX_999999999_STR,
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'L',
    MinStaff: '199',
    MaxStaff: MAX_999999999_STR,
    NumStaff_CodeSize: 'L',
  },
  {
    Value: '0',
    Sec: 'Service and Trade',
    MinTurnOver: '0',
    MaxTurnOver: '62249',
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'M',
    MinAsset: '0',
    MaxAsset: '49999',
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'M',
    MinStaff: '0',
    MaxStaff: '5',
    NumStaff_CodeSize: 'M',
  },
  {
    Value: '1',
    Sec: 'Service and Trade',
    MinTurnOver: '62250',
    MaxTurnOver: '250000',
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'S',
    MinAsset: '50000',
    MaxAsset: '250000',
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'S',
    MinStaff: '5',
    MaxStaff: '49',
    NumStaff_CodeSize: 'S',
  },
  {
    Value: '2',
    Sec: 'Service and Trade',
    MinTurnOver: '250001',
    MaxTurnOver: '1500000',
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'D',
    MinAsset: '250001',
    MaxAsset: '500000',
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'D',
    MinStaff: '50',
    MaxStaff: '99',
    NumStaff_CodeSize: 'D',
  },
  {
    Value: '3',
    Sec: 'Service and Trade',
    MinTurnOver: '1500001',
    MaxTurnOver: MAX_999999999_STR,
    CurrencyTurnover: 'USD',
    TurnOver_CodeSize: 'L',
    MinAsset: '500001',
    MaxAsset: MAX_999999999_STR,
    CurrencyAsset: 'USD',
    Asset_CodeSize: 'L',
    MinStaff: '99',
    MaxStaff: MAX_999999999_STR,
    NumStaff_CodeSize: 'L',
  },
];

export const listLicenseConst = [
  {
    value: 0,
    desc: 'Certificate / License Issued by Ministry of Commerce',
  },
  {
    value: 1,
    desc: 'Certificate / License Issued by Ministry of Economy & Finance',
  },
  {
    value: 2,
    desc: 'Certificate / License Issued by General Department of Taxation',
  },
  {
    value: 3,
    desc: 'Certificate / License Issued by Other Authority',
  },
  {
    value: 4,
    desc: 'Not Available',
  },
  {
    value: 5,
    desc: 'Optional',
  },
];

export const KSCORE_GROUP = [
  {
    name: 'kscore',
    desc: 'KScore',
    value: '',
    type: 'radio',
    list: yesNoListKscore,
    validation: {
      required: true,
    },
  },
  {
    name: 'nokscore',
    desc: 'If No KScore, Please Specify Here',
    depend: 'kscore',
    dependVal: '0',
    type: 'list',
    list: KSCORE_NO_LIST,
    validation: {
      required: true,
    },
  },
  {
    name: 'score',
    desc: 'Score',
    type: 'number',
    gridRow: 12,
    smallContent: true,
    depend: 'kscore',
    dependVal: '1',
    validation: {
      required: true,
      min: 100,
      max: 1400,
    },
  },
  {
    name: 'riskGrade',
    desc: 'Risk Grade',
    value: '',
    depend: 'kscore',
    readonly: true,
    dependVal: '1',
    type: 'list',
    list: [
      {
        value: 'AA',
        desc: 'AA',
      },
      {
        value: 'BB',
        desc: 'BB',
      },
      {
        value: 'CC',
        desc: 'CC',
      },
      {
        value: 'DD',
        desc: 'DD',
      },
      {
        value: 'EE',
        desc: 'EE',
      },
    ],
    validation: {
      required: true,
    },
  },

  {
    name: 'scoreRange',
    desc: 'Score Range',
    value: '',
    readonly: true,
    depend: 'kscore',
    dependVal: '1',
    type: 'list',
    list: scoreRangeList,
    validation: {
      required: true,
    },
  },
];
export const GENDER_LIST_CONST = [
  {
    value: 'M',
    desc: 'Male',
  },
  {
    value: 'F',
    desc: 'Female',
  },
];
export const NATIONALITY_LIST_CONST = [
  {
    value: 'CAM',
    desc: 'Cambodian',
  },
  {
    value: 'NONCAM',
    desc: 'Non-Cambodian',
  },
];
export const BUSINESS_CONSTITUENCY = [
  {
    value: '0',
    desc: 'Sole Proprietorship',
  },
  {
    value: '1',
    desc: 'Partnership',
  },
  {
    value: '2',
    desc: 'Private Limited Company',
  },
  {
    value: '3',
    desc: 'Others',
  },
];
export const BUSINESS_LICENSE_UI = [
  {
    name: 'certificationLicense',
    desc: 'Certification & License',
    type: 'list',
    list: listLicenseConst,
    value: '',
    validation: {
      required: true,
    },
  },
  {
    name: 'otherAuthority',
    desc: 'Specify, If Other Authority',
    depend: 'certificationLicense',
    dependVal: 3,
    list: listLicenseConst,
    value: '',
    validation: {
      required: true,
    },
  },
  {
    name: 'referenceNo',
    desc: 'Reference No.',
    value: '',
    depend: 'certificationLicense',
    dependValNot: 4,
    dependValNot1: 5,
    validation: {
      required: true,
      maxLength: 60,
    },
  },
  {
    name: 'issueDate',
    desc: 'Issue Date',
    type: 'date',
    depend: 'certificationLicense',
    dependValNot: 4,
    dependValNot1: 5,
    value: '',
    validation: {
      required: true,
    },
  },
  {
    name: 'expiryDate',
    desc: 'Expiry Date',
    type: 'date',
    depend: 'certificationLicense',
    dependValNot: 4,
    dependValNot1: 5,
    value: '',
    validation: {
      required: true,
    },
  },
];
export const BUSINESS_LICENSE_MODAL_UI = {
  name: 'businessCertification',
  desc: 'Business Certification / License',
  groups: [
    {
      name: 'businessCertification',
      desc: '',
      data: BUSINESS_LICENSE_UI,
    },
  ],
};
export const businessUI = [
  {
    name: 'businessInformation',
    desc: 'Business Information',
    data: [
      {
        name: 'companyName',
        desc: 'Company Name',
        gridRow: 12,
        value: '',
        validation: {
          required: true,
          maxLength: 300,
        },
      },
      {
        name: 'businessConstituency',
        desc: 'Type of Business Constituency',
        value: '',
        type: 'list',
        list: BUSINESS_CONSTITUENCY,
        validation: {
          required: true,
        },
      },
      {
        name: 'businessConstituencyOther',
        desc: 'If Other, Please Specify',
        depend: 'businessConstituency',
        dependVal: '3',
        value: '',
        validation: {
          required: true,
        },
      },
      {
        name: 'mainBusinessActivity',
        desc: 'Main Business Activity',
        value: '',
        validation: {
          required: true,
        },
      },
      {
        name: 'businessInNegativeList',
        desc: 'Business in Negative List',
        type: 'radio',
        list: yesNoList,
        hasModal: true,
        content: [
          {
            content: 'Businesses and/or loan purposes listed below are ineligible for guarantee:',
            level: 1,
          },
          {
            content:
              'Any business with the purpose of using the loan for Passive Real Estate Ownership, or any combination thereof, within Cambodia, provided that a business purpose specifically acquiring or holding passive investments including purchase of non-business-related real estate and the purchase of securities.',
            level: 2,
          },
          {
            content: "Any loan used to refinance any of the Borrower's existing obligation(s) to the Lender.",
            level: 2,
          },
          {
            content: 'Any loan used for the following purposes:',
            level: 2,
            children: [
              {
                content:
                  'repay delinquent income taxes unless the Borrower has a payment plan in place with the General Department of Taxation',
                level: 1,
              },
              {
                content:
                  "reimburse funds owed to any owner, including any equity injection or injection of capital for the continuance of the Borrower's business, or",
                level: 1,
              },
              {
                content: "purchase any portion of the ownership interest of any owner of the Borrower's business, or",
                level: 1,
              },
              {
                content: 'pay any dividend to any owner.',
                level: 1,
              },
            ],
          },
          {
            content:
              "Any business activity which is deemed illegal under Cambodia's laws or regulations or international conventions, treaties, or agreements, or which involves products or services that are subject to international bans.",
            level: 2,
          },
          {
            content: 'Any business engaged in production or trade:',
            level: 2,
            children: [
              {
                content: 'weapons or munitions,',
                level: 1,
              },
              {
                content: 'radioactive materials (excluding medical equipment or quality control equipment),',
                level: 1,
              },
              {
                content: 'wood or other forestry products (other than from sustainably managed forests),',
                level: 1,
              },
              {
                content: 'un-bonded asbestos fibres.',
                level: 1,
              },
            ],
          },
          {
            content: 'Any business engaged in:',
            level: 2,
            children: [
              {
                content: 'commercial logging operations in the primary tropical moist forest,',
                level: 1,
              },
              {
                content: 'marine drift net fishing using nets more than 2.5 km in length, or',
                level: 1,
              },
              {
                content: 'activities involving harmful or exploitative forms of forced labour or child labour.',
                level: 1,
              },
            ],
          },
          {
            content:
              'Any business engaged in speculative activities that develop profits from fluctuations in price rather than through normal course of trade such as dealing in foreign currency or commodities futures unless such activities are incidental to the regular activities of the business and can be demonstrated to serve as part of a legitimate risk management strategy to guard against price fluctuations related to the regular activities of the business.',
            level: 2,
          },
          {
            content: 'Any business that earns more than half of its annual net revenue from lending activities.',
            level: 2,
          },
          {
            content:
              'Any business engaged in the pyramid or multilevel marketing sales, where a participant’s primary incentive is based on the sales made by an ever-increasing number of participants.',
            level: 2,
          },
          {
            content:
              'Any business engaged in activities that are prohibited by Cambodian law where the business is located or conducted including, but not limited to, a business engaged in the production, servicing, or distribution of otherwise legal products that are used in connection with illegal activity, such as selling drug paraphernalia or operating a motel that knowingly permits prostitution or illegal manufacturing of drugs.',
            level: 2,
          },
          {
            content:
              'Any business engaged in gambling enterprises, except a business that earns less than thirty-three percent (33.0%) of its annual net revenue from lottery sales, which shall not be deemed to be a gambling enterprise.',
            level: 2,
          },
        ],
        validation: {
          required: true,
        },
      },
      {
        name: 'detailBusinessActivities',
        desc: 'Detail of Business Activities',
        type: 'textArea',
        row: 5,
        value: '',
        validation: {
          required: true,
        },
      },
      {
        name: 'businessSector',
        desc: 'Business Sector',
        type: 'businessSector',
        gridRow: 12,
        smallContent: true,
        validation: {
          required: true,
        },
      },
    ],
  },
  {
    name: 'definingBusinessType',
    desc: 'Defining Business Type',
    data: [
      {
        name: 'annualTurnover',
        desc: 'Annual Turnover of Borrowers / Business',
        value: '',
        depend: 'businessSector',
        type: 'list',
        list: [],
        validation: {
          required: true,
        },
      },
      {
        name: 'totalAsset',
        desc: 'Total Asset',
        type: 'list',
        list: [],
        value: '',
        depend: 'businessSector',
        validation: {
          required: true,
        },
      },
      {
        name: 'numberOfFulltimeStaff',
        desc: 'Number of Full-Time Staff',
        type: 'number',
        validation: {
          required: true,
          min: 1,
        },
      },
      {
        name: 'typeOfBusiness',
        desc: 'Type of Borrower / Business',
        value: '',
        type: 'list',
        readonly: true,
        render: ['typeOfBusiness', 'numberOfFulltimeStaff', 'totalAsset', 'annualTurnover', 'businessSector'],
        list: BUSINESS_TYPES_MST,
        validation: {
          required: true,
        },
      },
    ],
  },
  {
    name: 'businessRegistrationLicense',
    desc: 'Business Registration / License',
    data: [
      {
        type: 'table',
        buttonLabel: 'Add new',
        modal: BUSINESS_LICENSE_MODAL_UI,
        actions: [
          { action: 'detail', label: 'Detail', icon: <Edit /> },
          { action: 'delete', label: 'Delete', icon: <Delete /> },
        ],
      },
    ],
    selectedData: [],
    validation: {
      required: true,
    },
  },
  {
    name: 'shareholderInformation',
    desc: 'Business Shareholder',
    data: [
      {
        type: 'table',
        buttonLabel: 'Add new',
        modal: {
          name: 'shareholderInformation',
          desc: 'Available Individual',
          groups: [
            {
              name: 'newShareholder',
              desc: 'New Shareholder',
              data: [
                {
                  name: 'pfiCifNo',
                  desc: "PFI's CIF No.",
                  value: '',
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              name: 'personalInformation',
              desc: 'Personal Information',
              data: [
                {
                  name: 'lastName',
                  desc: 'Family Name',
                  value: '',
                  validation: {
                    required: false,
                    textOnly: true,
                    maxLength: 60,
                  },
                },
                {
                  name: 'firstName',
                  desc: 'First Name',
                  value: '',
                  validation: {
                    required: false,
                    textOnly: true,
                    maxLength: 60,
                  },
                },
                {
                  name: 'gender',
                  desc: 'Gender',
                  value: 'M',
                  type: 'list',
                  list: GENDER_LIST_CONST,
                  validation: {
                    required: false,
                  },
                },
                {
                  name: 'dateOfBirth',
                  desc: 'Date Of Birth',
                  type: 'date',
                  value: '',
                  validation: {
                    required: false,
                  },
                },
                {
                  name: 'nationality',
                  desc: 'Nationality',
                  type: 'list',
                  list: NATIONALITY_LIST_CONST,
                  value: '',
                  validation: {
                    required: false,
                  },
                },
                {
                  name: 'phone',
                  desc: 'Telephone',
                  type: 'phone',
                  countryCode: 'kh',
                  value: '',
                  validation: {
                    required: false,
                    maxLength: 9,
                    minLength: 8,
                  },
                },
                {
                  name: 'identity',
                  desc: 'P. Official Identity',
                  value: '',
                  type: 'list',
                  list: [...IDENTIFICATION_LIST],
                  validation: {
                    required: false,
                  },
                },
                {
                  name: 'referNumber',
                  desc: 'Reference Number',
                  value: '',
                  validation: {
                    required: false,
                  },
                },
                {
                  name: 'boardOfDirectors',
                  desc: 'Board of Directors',
                  value: '',
                  list: yesNoList,
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'percentOwnership',
                  desc: 'Percentage of Shares Ownership',
                  value: '',
                  type: 'number',
                  decimal: 2,
                  validation: {
                    required: true,
                    min: 1,
                    max: 100,
                  },
                },
                {
                  name: 'remark',
                  desc: 'Remark',
                  value: '',
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              name: 'addressInformation',
              desc: 'Address Information',
              data: [
                {
                  name: 'addressType',
                  desc: 'Address Type',
                  type: 'list',
                  list: [
                    {
                      value: '0',
                      desc: 'Residential',
                    },
                    {
                      value: '1',
                      desc: 'Work',
                    },
                    {
                      value: '2',
                      desc: 'Correspondence',
                    },
                  ],
                  value: '',
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'houseStreet',
                  desc: 'House & Street',
                  value: '',
                  validation: {
                    required: true,
                    maxLength: 60,
                  },
                },
                {
                  name: 'city',
                  desc: 'City/Province',
                  value: '',
                  type: 'address',
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'district',
                  desc: 'Khan/District',
                  value: '',
                  type: 'address',
                  depend: 'city',
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'sangkatCommune',
                  desc: 'Sangkat/Commune',
                  value: '',
                  depend: 'district',
                  type: 'address',
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'village',
                  desc: 'Phumi/Village',
                  value: '',
                  depend: 'sangkatCommune',
                  type: 'address',
                  validation: {
                    required: false,
                  },
                },
              ],
            },
          ],
        },
        actions: [
          { action: 'detail', label: 'Detail', icon: <Edit /> },
          { action: 'delete', label: 'Delete', icon: <Delete /> },
        ],
      },
    ],
    validation: {
      required: true,
    },
  },
  {
    name: 'businessAddress',
    desc: 'Business Address',
    data: [
      {
        name: 'houseStreet',
        desc: 'House & Street',
        gridRow: 12,
        value: '',
        validation: {
          required: true,
          maxLength: 60,
        },
      },
      {
        name: 'city',
        desc: 'City/Province',
        value: '',
        type: 'address',
        validation: {
          required: true,
        },
      },
      {
        name: 'district',
        desc: 'Khan/District',
        value: '',
        type: 'address',
        depend: 'city',
        validation: {
          required: true,
        },
      },
      {
        name: 'sangkatCommune',
        desc: 'Sangkat/Commune',
        value: '',
        depend: 'district',
        type: 'address',
        validation: {
          required: true,
        },
      },
      {
        name: 'village',
        desc: 'Phumi/Village',
        value: '',
        depend: 'sangkatCommune',
        type: 'address',
        validation: {
          required: false,
        },
      },
    ],
  },
  {
    name: 'contactDetail',
    desc: 'Business Contact',
    data: [
      {
        name: 'phoneArray',
        desc: '',
        type: 'phoneArray',
        value: [],
        validation: {
          required: true,
        },
      },

      {
        name: 'website',
        desc: 'Website',
        value: '',
        validation: {
          required: false,
        },
      },
      {
        name: 'email',
        desc: 'Email Address',
        value: '',
        validation: {
          required: false,
        },
      },
    ],
  },
  {
    name: 'companyRiskScore',
    desc: 'Company Risk Score',
    data: KSCORE_GROUP,
  },
];

export const LOAN_PURPOSE_LIST = () => {
  return LOAN_PURPOSES.map((item) => {
    return { value: item.code, desc: item.value };
  });
};

export const LIST_GUARANTEE_TENURE = [
  {
    value: 0,
    desc: 'Equal to Loan Tenure',
  },
  {
    value: 1,
    desc: 'Max Allowed Tenure',
  },
  {
    value: 2,
    desc: 'Other - Specify',
  },
];

export function LIST_CURRENCY_LOAN(rate = 4000) {
  let MIN_KHR = MIN_USD * rate;
  return [
    {
      value: 'USD',
      desc: 'USD',
      min: MIN_USD,
      decimal: 2,
      prefix: '$',
    },
    {
      value: 'KHR',
      desc: 'KHR',
      min: MIN_KHR,
      // max: 2000000000,
      decimal: 0,
      prefix: '៛',
    },
  ];
}
export function LIST_CURRENCY_LOAN_SECURITY(rate = 4000) {
  return [
    {
      value: 'USD',
      desc: 'USD',
      min: 0.01,
      decimal: 2,
      prefix: '$',
    },
    {
      value: 'KHR',
      desc: 'KHR',
      min: 100,
      // max: 2000000000,
      decimal: 0,
      prefix: '៛',
    },
  ];
}
export const loanUI = [
  {
    name: 'loanApplicationNumber',
    desc: 'Loan Application Number',
    noHeader: true,
    data: [
      {
        name: 'loanApplicationNumber',
        desc: 'Loan Application Number',
        gridRow: 6,
        smallContent: true,
        validation: {
          required: true,
          maxLength: 60,
        },
      },
      {
        name: 'pfiBranchCode',
        type: 'pfiBranchCode',
        desc: 'PFI Branch',
        gridRow: 6,
        validation: {
          required: false,
        },
      },
    ],
  },
  {
    name: 'loanFacility',
    desc: 'Loan Facility',
    data: [
      {
        type: 'table',
        singleRow: true,
        buttonLabel: 'Add loan',
        modal: {
          name: 'loanFacility',
          desc: 'Loan Facility',
          groups: [
            {
              name: 'newLoanFacility',
              desc: 'New Loan Facility',
              data: [
                {
                  name: 'loanType',
                  desc: 'Loan Facility',
                  type: 'list',
                  list: LOAN_TYPES,
                  value: '',
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'loanPurpose',
                  desc: 'Loan Purpose',
                  type: 'list',
                  list: LOAN_PURPOSE_LIST(),
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'currency',
                  desc: 'Currency',
                  type: 'list',
                  list: [...LIST_CURRENCY_LOAN()],
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'loanTenure',
                  desc: 'Loan Tenure (Months)',
                  type: 'number',
                  value: '',
                  decimal: 0,
                  validation: {
                    required: true,
                    min: 12,
                    max: 1200,
                  },
                },
                {
                  name: 'interestRatePerAnnum',
                  desc: 'Interest Rate Per-Annum',
                  type: 'number',
                  gridRow: 12,
                  depend: 'firstYearInterestRate',
                  dependVal: false,
                  smallContent: true,
                  decimal: 2,
                  validation: {
                    required: true,
                    min: 1,
                    max: 100,
                  },
                },
                {
                  name: 'interestRatePerAnnum1st',
                  desc: '1st Year Interest Rate Per-Annum',
                  type: 'number',
                  depend: 'firstYearInterestRate',
                  dependVal: true,
                  readonly: false,
                  decimal: 2,
                  validation: {
                    required: true,
                    min: 0,
                  },
                },
                {
                  name: 'interestRatePerAnnum2nd',
                  desc: '2nd Year Onward Interest Rate Per-Annum',
                  type: 'number',
                  depend: 'firstYearInterestRate',
                  dependVal: true,
                  decimal: 2,
                  validation: {
                    required: true,
                    min: 0.01,
                    max: 100,
                  },
                },
                {
                  name: 'loanAmount',
                  desc: 'Loan Amount',
                  type: 'amount',
                  value: '',
                  decimal: 2,
                  validation: {
                    required: true,
                  },
                },
                /*{
                  name: 'describeLoanPurpose',
                  desc: 'Describe Loan Purpose',
                  type: 'textArea',
                  row: 5,
                  value: '',
                  validation: {
                    required: true,
                    maxLength: 160,
                  },
                },*/
                // {
                //   type: 'notice',
                //   value: '',
                //   validation: {},
                // },
              ],
            },
          ],
        },
        actions: [
          {
            value: 'detail',
            desc: 'Detail',
            groups: [
              {
                name: '',
                desc: 'Business Certification / License',
                data: [],
              },
            ],
          },
          {
            value: 'remove',
            desc: 'Remove',
          },
        ],
        subData: [
          {
            name: 'total',
            desc: 'Total Loan Amount',
            type: 'amount',
            readonly: true,
            validation: {},
          },
        ],
      },
    ],
    validation: {
      required: false,
    },
  },
  {
    name: 'guaranteeRequestInformation',
    desc: 'Guarantee Request Information',
    data: [
      {
        name: 'guaranteeTenureOptions',
        desc: 'Guarantee Tenure Options',
        type: 'list',
        list: LIST_GUARANTEE_TENURE,
        validation: {
          required: true,
        },
      },
      {
        name: 'guaranteeTenureMonths',
        desc: 'Guarantee Tenure (Months)',
        type: 'number',
        depend: 'guaranteeTenureOptions',
        dependVal: 2,
        decimal: 0,
        validation: {
          required: true,
          min: 1,
          max: MAXIMUM_TENURE,
        },
      },
      {
        name: 'guaranteeRatio',
        desc: 'Guarantee Ratio',
        type: 'number',
        readonly: true,
        validation: {
          required: false,
        },
      },
      {
        name: 'totalGuaranteeAmount',
        desc: 'Total Guaranteed Amount',
        type: 'amount',
        readonly: true,
        validation: {
          required: false,
        },
      },
    ],
  },
  {
    name: 'securityTypes',
    desc: 'Security Type',
    data: [
      {
        type: 'table',
        buttonLabel: 'Add security',
        modal: {
          name: 'securityTypes',
          desc: 'New Security Types',
          groups: [
            {
              name: 'securityTypes',
              desc: 'New Security Types',
              data: [
                {
                  name: 'typeOfSecurity',
                  desc: 'Type of Securities',
                  type: 'list',
                  list: typeOfSecurities,
                  value: '',
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'securityEvidence',
                  desc: 'Security Evidence (Ref. No)',
                  value: '',
                  depend: 'typeOfSecurity',
                  dependValNot: 2,
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'currency',
                  desc: 'Currency',
                  type: 'list',
                  readonly: false,
                  list: LIST_CURRENCY_LOAN_SECURITY(),
                  validation: {
                    required: true,
                  },
                  depend: 'typeOfSecurity',
                  dependValNot: 2,
                },
                {
                  name: 'estimatedValueSecurity',
                  desc: 'Estimated value of Securities',
                  depend: 'typeOfSecurity',
                  dependValNot: 2,
                  type: 'amount',
                  decimal: 2,
                  value: '',
                  validation: {
                    required: true,
                    min: 0.01,
                  },
                },
              ],
            },
          ],
        },
        subData: [
          {
            name: 'total',
            desc: 'Total Estimated Amount',
            type: 'amount',
            readonly: true,
          },
        ],
        validation: {
          required: false,
        },
      },
    ],

    validation: {
      required: false,
    },
  },
  {
    name: 'individualGuarantor',
    desc: 'Individual Guarantor',
    data: [
      {
        type: 'table',
        buttonLabel: 'Add Guarantor',
        modal: {
          name: 'guarantor',
          desc: 'Available Individual',
          groups: [
            {
              name: 'guarantor',
              desc: 'New Guarantor',
              data: [
                {
                  name: 'pfiCifNo',
                  desc: "PFI's CIF No",
                  gridRow: 12,
                  value: '',
                  smallContent: true,
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'lastName',
                  desc: 'Family Name',
                  value: '',
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'firstName',
                  desc: 'First Name',
                  value: '',
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'gender',
                  desc: 'Gender',
                  type: 'list',
                  list: [
                    {
                      value: 'M',
                      desc: 'Male',
                    },
                    {
                      value: 'F',
                      desc: 'Female',
                    },
                  ],
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'dob',
                  desc: 'Date Of Birth',
                  type: 'date',
                  value: '',
                  validation: {
                    required: true,
                  },
                },
                {
                  name: 'nationality',
                  desc: 'Nationality',
                  type: 'list',
                  list: [
                    {
                      value: 'CAM',
                      desc: 'Cambodian',
                    },
                    {
                      value: 'NONCAM',
                      desc: 'Non-Cambodian',
                    },
                  ],
                  value: '',
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              name: 'guarantorInfo',
              desc: 'Guarantor Information',
              data: [
                {
                  name: 'guarantorValue',
                  desc: 'Guarantor Value',
                  gridRow: 12,
                  smallContent: true,
                  value: '',
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        validation: {
          required: false,
        },
      },
    ],
  },
  {
    name: 'companyGuarantor',
    desc: 'Company Guarantor',
    data: [
      {
        type: 'table',
        buttonLabel: 'Add Guarantor',
        modal: {
          name: 'companyGuarantor',
          desc: 'New Guarantor as Company',
          groups: [
            {
              name: 'guarantor',
              desc: 'New Guarantor',
              data: [
                {
                  name: 'companyName',
                  desc: 'Company Name',
                  value: '',
                  validation: {
                    required: true,
                  },
                },
              ],
            },
            {
              name: 'guarantorInfo',
              desc: 'Guarantor Information',
              data: [
                {
                  name: 'guarantorValue',
                  desc: 'Guarantor Value',
                  gridRow: 12,
                  smallContent: true,
                  value: '',
                  validation: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        validation: {
          required: false,
        },
      },
    ],
  },
  {
    name: 'PFIRemarks',
    desc: 'PFI Remarks',
    data: [
      {
        name: 'PFIExperienceWithMainBorrowers',
        desc: 'PFI’s Experience with Main Borrowers',
        type: 'radio',
        list: [
          {
            value: 0,
            desc: 'New Borrower',
          },
          {
            value: 1,
            desc: 'Existing Borrower',
          },
        ],
        validation: {
          required: true,
        },
      },
      {
        name: 'numberOfYearsOfEngagement',
        desc: 'Number of Years of Engagement',
        type: 'number',
        depend: 'PFIExperienceWithMainBorrowers',
        dependVal: 1,
        value: null,
        validation: {
          required: true,
          min: 1,
          max: 100,
        },
      },
    ],
  },
];

const buildYearList = () => {
  let result = [];
  for (let i = 2000; i < 2100; i++) {
    let obj = {
      value: i,
      desc: i,
    };
    result.push(obj);
  }
  return result;
};
export const YEARS = buildYearList();
export const yearUI = {
  data: [
    {
      name: 'currency',
      desc: 'Currency',
      type: 'list',
      list: [
        {
          value: 'USD',
          desc: 'USD',
        },
        {
          value: 'KHR',
          desc: 'KHR',
        },
      ],
      validation: {
        required: true,
      },
    },
    {
      name: 'annualTurnover',
      desc: 'Annual Turnover',
      type: 'amount',
      validation: {
        required: true,
      },
    },
    {
      name: 'borrowerTotalAssets',
      desc: 'Borrower Total Assets',
      type: 'amount',
      validation: {
        required: true,
      },
    },
    {
      name: 'borrowerNetProfit',
      desc: 'Borrower Net Profit',
      type: 'amount',
      validation: {
        required: true,
      },
    },
    {
      name: 'borrowerTotalLiability',
      desc: 'Borrower Total Liability',
      type: 'amount',
      validation: {
        required: true,
      },
    },
    {
      name: 'debtsToEquityRatio',
      desc: 'Debts to Equity Ratio',
      readonly: true,
      validation: {
        required: true,
      },
    },
    {
      name: 'borrowerTotalEquity',
      desc: 'Borrower Total Equity',
      type: 'amount',
      validation: {
        required: true,
      },
    },
    {
      name: 'debtServiceCoverageRatio',
      desc: 'Debt Service Coverage Ratio (DSCR)',
      type: 'amount',
      decimal: 2,
      validation: {
        required: true,
        min: 1,
        max: 100,
      },
    },
  ],
};

export const summaryUI = [
  {
    name: 'last3years',
    desc: '',
    noHeader: true,
    type: 'SUMMARY_YEAR',
    subLabel: 'Year',
    border: true,
    years: [],
  },
];

export const DOCS_LIST_MAX_30K = [
  {
    label: 'CGCC Cover Letter',
    code: 0,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    label: 'Loan Approval',
    code: 100,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    label: 'Consent Letter',
    code: 101,
    files: [],
    validation: {
      required: true,
    },
  },
];

export const DOCS_LIST = [
  {
    label: 'CGCC Cover Letter',
    code: 0,
    index: 0,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    label: "Borrower's Loan Application",
    code: 1,
    index: 1,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    label: "PFI's Loan Appraisal Report",
    code: 2,
    index: 2,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    label: "Borrower's CBC Report",
    code: 3,
    index: 3,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    label: "Borrower's Shareholders/Directors Official Identity Document",
    code: 4,
    index: 4,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    label: "Borrower's Business Registration and/or Licenses",
    code: 5,
    index: 5,
    files: [],
    validation: {
      required: false,
    },
  },
  {
    label: 'Security’s Evidence (Hard Title, Soft Title, Ownership, Certificate, etc.)',
    code: 6,
    index: 6,
    depend: 'securityTypes',
    dependStep: 'LOAN',
    files: [],
    validation: {
      required: false,
    },
  },
  {
    label: 'Report on Borrower’s Security Valuation (Either Internal or External)',
    code: 7,
    index: 7,
    depend: 'securityTypes',
    dependStep: 'LOAN',
    files: [],
    validation: {
      required: false,
    },
  },
  {
    label: "PFI's Loan Letter of Offer (Including Terms & Conditions of the Loan)",
    code: 8,
    index: 8,
    files: [],
    validation: {
      required: false,
    },
  },
  {
    label: "Borrower's Consent for the Disclosure & Usage of their Information by CGCC",
    code: 9,
    index: 9,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    label: 'Business Photos',
    code: 10,
    index: 10,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    label: 'Other supporting document',
    code: 11,
    index: 11,
    files: [],
    validation: {
      required: false,
    },
  },
];

export const DOC_LIST_RESTRUCTURE = [
  {
    idx: 100,
    name: 'doc100',
    desc: 'Loan Statement',
    label: 'Loan Statement',
    code: 100,
    index: 100,
    files: [],
    validation: {
      required: true,
    },
  },
  {
    idx: 101,
    name: 'doc101',
    desc: 'Loan Repayment Schedule',
    label: 'Loan Repayment Schedule',
    code: 101,
    index: 101,
    files: [],
    validation: {
      required: true,
    },
  },
];

export const GROUP_ATTACHMENTS = [
  {
    name: 'ATTACHMENTS',
    desc: 'Required Attachments',
    type: 'DOCS',
    data: DOCS_LIST,
    validation: {},
  },
];
let ANNUAL_TURNOVER_LIST = [
  {
    value: '0',
    desc: '< 400,0001 USD',
  },
  {
    value: '1',
    desc: '400,001 - 2,000,000 USD',
  },
  {
    value: '2',
    desc: '> 2,000,0001 USD',
  },
];
let TOTAL_ASSET_LIST = [
  {
    value: '0',
    desc: '< 500,0001 USD',
  },
  {
    value: '1',
    desc: '500,001 - 1,000,000 USD',
  },
  {
    value: '2',
    desc: '> 1,000,0001 USD',
  },
];
export const schemeData = [
  {
    id: 1,
    title: 'Business Recovery Guarantee Scheme',
    acronym: 'BRGS',
    expiryDate: '2030-05-15',
    createdDate: '2022-05-15',
    status: 'ACTIVE',
    hasPopup: false,
    schemeSize: '$200 million',
    sizeOfSME: '$150 million',
    sizeOfLargeFirm: '$50 million',
    appNo: '100000',
    stage: '',
    cifNo: 'CIF00001',
    desc:
      'BRGS aims to support businesses including Micro, Small and Medium Enterprises (SMEs) and Large Firms to enhance\n' +
      'their access to formal loans for both working capital and investment or business expansion. BRGS is in line\n' +
      'with the policies of the Royal Government of Cambodia to support the survival and economic recovery during the\n' +
      'COVID-19 pandemic. BRGS acts as collateral/security for 70%-80% of the loan amount borrowed by businesses from\n' +
      'the Participating Financial Institutions (PFIs); thus, reducing the physical collateral required from the\n' +
      'borrowers.',
    steps: [
      {
        name: 'BORROWER',
        desc: 'Borrower information',
        display: 'SHOW',
        type: 'BORROWER',
        stepType: 'TABLE',
        groups: [
          {
            name: 'borrowerDetail',
            desc: 'Borrower Detail',
            data: [
              {
                name: 'fullName',
                desc: 'Full Name',
                type: 'readonly',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'referenceNumber',
                desc: 'Reference No.',
                type: 'readonly',
                value: '',
                validation: {
                  required: true,
                },
              },
            ],
          },
          {
            name: 'personalInformation',
            desc: 'Personal Information',
            data: [
              {
                name: 'borrowerRelationship',
                desc: 'Customer Relationship',
                type: 'list',
                value: '10',
                list: customerRelationshipList,
                validation: {
                  required: true,
                },
              },
              {
                name: 'lastName',
                desc: 'Family Name',
                value: '',
                validation: {
                  required: true,
                  textOnly: true,
                },
              },
              {
                name: 'firstName',
                desc: 'First Name',
                value: '',
                validation: {
                  required: true,
                  textOnly: true,
                },
              },
              {
                name: 'gender',
                desc: 'Gender',
                value: 'M',
                type: 'list',
                list: [
                  {
                    value: 'M',
                    desc: 'Male',
                  },
                  {
                    value: 'F',
                    desc: 'Female',
                  },
                ],
                validation: {
                  required: true,
                },
              },
              {
                name: 'dateOfBirth',
                desc: 'Date Of Birth',
                type: 'date',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'nationality',
                desc: 'Nationality',
                type: 'list',
                list: [
                  {
                    value: 'CAM',
                    desc: 'Cambodian',
                  },
                  {
                    value: 'NONCAM',
                    desc: 'Non-Cambodian',
                  },
                ],
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'phone',
                desc: 'Telephone',
                type: 'phone',
                countryCode: 'kh',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'pOfficeIdentity',
                desc: 'P. Official Identity',
                value: '',
                type: 'list',
                list: [...IDENTIFICATION_LIST],
                validation: {
                  required: true,
                },
              },
              {
                name: 'referenceNumber',
                desc: 'Reference Number',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'showOnLg',
                desc: 'Show on LG',
                type: 'radio',
                list: [
                  {
                    value: '0',
                    desc: 'No',
                  },
                  {
                    value: '1',
                    desc: 'Yes',
                  },
                ],
                value: '',
                validation: {
                  required: true,
                },
              },
            ],
          },
          {
            name: 'addressInformation',
            desc: 'Address Information',
            data: [
              {
                name: 'addressType',
                desc: 'Address Type',
                type: 'list',
                list: [
                  {
                    value: '0',
                    desc: 'Residential',
                  },
                  {
                    value: '1',
                    desc: 'Work',
                  },
                  {
                    value: '2',
                    desc: 'Correspondence',
                  },
                ],
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'houseStreet',
                desc: 'House & Street',
                value: '',
                validation: {
                  required: true,
                  maxLength: 60,
                },
              },
              {
                name: 'city',
                desc: 'City/Province',
                value: '',
                type: 'address',
                validation: {
                  required: true,
                },
              },
              {
                name: 'district',
                desc: 'Khan/District',
                value: '',
                type: 'address',
                depend: 'city',
                validation: {
                  required: true,
                },
              },
              {
                name: 'sangkatCommune',
                desc: 'Sangkat/Commune',
                value: '',
                depend: 'district',
                type: 'address',
                validation: {
                  required: true,
                },
              },
              {
                name: 'village',
                desc: 'Phumi/Village',
                value: '',
                depend: 'sangkatCommune',
                type: 'address',
                validation: {
                  required: false,
                },
              },
            ],
          },
          {
            name: 'miscellaneous',
            desc: 'Miscellaneous',
            type: 'list',
            data: [
              {
                name: 'boardOfDirectors',
                desc: 'Board of Directors',
                value: '',
                list: yesNoList,
                validation: {
                  required: true,
                },
              },
              {
                name: 'percentOwnership',
                desc: 'Percentage of Shares Ownership',
                value: '',
                validation: {
                  required: true,
                },
              },
              ...KSCORE_GROUP,
            ],
          },
        ],
        selectedData: [],
      },
      {
        name: 'BUSINESS',
        type: 'BUSINESS',
        desc: 'Business detail',
        display: 'SHOW',
        stepType: 'GROUPS',
        typeOfBusiness: typeOfBusiness,
        data: {
          id: 1,
          shareholders: [{ id: 1 }],
          inWhichSector: '1',
          inWhichSector1: '',
          inWhichSector2: '',
        },
        groups: businessUI,
      },
      {
        name: 'LOAN',
        stepType: 'LOAN',
        display: 'SHOW',
        desc: 'Loan information',
        data: {
          loanFacilities: [{}],
          guaranteeRequests: [{}],
          securityTypes: [{}],
        },
        groups: loanUI,
      },
      {
        name: 'SUMMARY',
        display: 'SHOW',
        stepType: 'SUMMARY_YEAR',
        data: {
          borrowerRisk: [{ id: 1 }],
        },
        desc: 'Summary data',
        groups: [
          {
            name: 'year0',
            type: 'SUMMARY_YEAR',
            subLabel: 'Year',
            totalYears: 2,
            border: true,
            years: [],
          },
        ],
      },
      {
        name: 'ATTACHMENTS',
        display: 'SHOW',
        desc: 'Documents',
        stepType: 'DOCS',
        groups: GROUP_ATTACHMENTS,
      },
      {
        name: 'SUBMIT',
        display: 'SHOW',
        desc: 'Review all information',
        data: {
          borrowerRisk: [{ id: 1 }],
        },
        stepType: 'REVIEW',
        groups: [
          {
            name: 'SUBMIT',
            desc: 'SUBMIT',
            type: 'REVIEW',
            data: [
              {
                data: [
                  {
                    name: '0',
                    desc: 'I. Guarantee Scheme',
                    data: [
                      {
                        name: '0',
                        desc: 'Scheme Type',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                    ],
                  },
                  {
                    name: '1',
                    desc: 'II. PFI Information',
                    data: [
                      {
                        name: '0',
                        desc: 'PFI Name (ENGLISH)',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                      {
                        name: '1',
                        desc: 'PFI Name (KHMER)',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                      {
                        name: '2',
                        desc: 'PFI ID',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                      {
                        name: '3',
                        desc: 'Type of PFI',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'III. Borrower Information',
                    data: borrowerData,
                  },
                  {
                    name: '0',
                    desc: 'IV. Business Information',
                    data: businessUI,
                  },
                  {
                    name: '0',
                    desc: 'V. Loan Information & Guarantee Requests',
                    data: loanUI,
                  },
                  {
                    name: '0',
                    desc: 'VI. Summary of PFI’s Appraisal',
                    data: [
                      {
                        name: '0',
                        desc: 'Year 1',
                        type: 'subGroup',
                        border: true,
                        data: summaryUI,
                        validation: {},
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'VII. Required Attachment',
                    data: [
                      {
                        type: 'subGroup',
                        noHeader: true,
                        data: [
                          {
                            type: 'docs',
                            name: '0',
                            data: DOCS_LIST,
                            validation: {},
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            validation: {},
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Co - Financing Guarantee Scheme',
    acronym: 'CFGS-TR',
    createdDate: '2022-05-22',
    expiryDate: '2030-01-01',
    status: 'ACTIVE',
    hasPopup: false,
    schemeSize: '$200 million',
    sizeOfSME: '$150 million',
    sizeOfLargeFirm: '$50 million',
    appNo: '200000',
    cifNo: 'CIF00002',
    desc:
      'The Co-Financing Guarantee Scheme (CFGS) is the credit guarantee scheme offered by the CGCC to Participating\n' +
      'Financial Institutions (PFIs) on the loans lent out under the SME Co-Financing Scheme II of SME Bank of\n' +
      'Cambodia.',
    steps: [
      {
        name: 'BORROWER',
        desc: 'Borrower information',
        display: 'SHOW',
        groups: [
          {
            name: 'borrowerDetail',
            desc: 'Borrower Detail',
            data: [
              {
                name: 'id',
                desc: "PFI's CIF No",
                type: 'readonly',
                value: '',
                validation: {
                  required: false,
                },
              },
              {
                name: '',
                desc: '',
                value: '',
                validation: {
                  required: false,
                },
              },
              {
                name: 'name',
                desc: 'Full Name',
                type: 'readonly',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'referenceNo',
                desc: 'Reference No.',
                type: 'readonly',
                value: '',
                validation: {
                  required: true,
                },
              },
            ],
          },
          {
            name: 'personalInformation',
            desc: 'Personal Information',
            data: [
              {
                name: 'customerRelationship',
                desc: 'Customer Relationship',
                type: 'list',
                value: '10',
                list: [
                  {
                    value: '10',
                    desc: 'Main Borrower',
                  },
                  {
                    value: '11',
                    desc: 'Main Borrower, Shareholder',
                  },
                  {
                    value: '20',
                    desc: 'Co-Borrower',
                  },
                  {
                    value: '21',
                    desc: 'Guarantor',
                  },
                  {
                    value: '22',
                    desc: 'Shareholder',
                  },
                  {
                    value: '23',
                    desc: 'Guarantor, Shareholder',
                  },
                  {
                    value: '24',
                    desc: 'Co-Borrower, Shareholder',
                  },
                ],
                validation: {
                  required: true,
                },
              },
              {
                name: 'lastName',
                desc: 'Family Name',
                value: '',
                validation: {
                  required: true,
                  textOnly: true,
                },
              },
              {
                name: 'firstName',
                desc: 'First Name',
                value: '',
                validation: {
                  required: true,
                  textOnly: true,
                },
              },
              {
                name: 'gender',
                desc: 'Gender',
                value: 'M',
                type: 'list',
                list: [
                  {
                    value: 'M',
                    desc: 'Male',
                  },
                  {
                    value: 'F',
                    desc: 'Female',
                  },
                ],
                validation: {
                  required: true,
                },
              },
              {
                name: 'dateOfBirth',
                desc: 'Date Of Birth',
                type: 'date',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'nationality',
                desc: 'Nationality',
                type: 'list',
                list: [
                  {
                    value: 'CAM',
                    desc: 'Cambodian',
                  },
                  {
                    value: 'NONCAM',
                    desc: 'Non-Cambodian',
                  },
                ],
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'phone',
                desc: 'Telephone',
                type: 'phone',
                countryCode: 'kh',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'identity',
                desc: 'P. Official Identity',
                value: '',
                type: 'list',
                list: [...IDENTIFICATION_LIST],
                validation: {
                  required: true,
                },
              },
              {
                name: 'referNumber',
                desc: 'Reference Number',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'showOnLg',
                desc: 'Show on LG',
                type: 'radio',
                list: [
                  {
                    value: '0',
                    desc: 'No',
                  },
                  {
                    value: '1',
                    desc: 'Yes',
                  },
                ],
                value: '',
                validation: {
                  required: true,
                },
              },
            ],
          },
          {
            name: 'addressInformation',
            desc: 'Address Information',
            data: [
              {
                name: 'addressType',
                desc: 'Address Type',
                type: 'list',
                list: [
                  {
                    value: '0',
                    desc: 'Residential',
                  },
                  {
                    value: '1',
                    desc: 'Work',
                  },
                  {
                    value: '2',
                    desc: 'Correspondence',
                  },
                ],
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'houseStreet',
                desc: 'House & Street',
                value: '',
                validation: {
                  required: true,
                  maxLength: 60,
                },
              },
              {
                name: 'city',
                desc: 'City/Province',
                value: '',
                type: 'address',
                validation: {
                  required: true,
                },
              },
              {
                name: 'district',
                desc: 'Khan/District',
                value: '',
                type: 'address',
                depend: 'city',
                validation: {
                  required: true,
                },
              },
              {
                name: 'sangkatCommune',
                desc: 'Sangkat/Commune',
                value: '',
                depend: 'district',
                type: 'address',
                validation: {
                  required: true,
                },
              },
              {
                name: 'village',
                desc: 'Phumi/Village',
                value: '',
                depend: 'sangkatCommune',
                type: 'address',
                validation: {
                  required: false,
                },
              },
            ],
          },
          {
            name: 'miscellaneous',
            desc: 'Miscellaneous',
            type: 'list',
            data: [
              {
                name: 'boardOfDirectors',
                desc: 'Board of Directors',
                value: '',
                list: yesNoList,
                validation: {
                  required: true,
                },
              },
              {
                name: 'percentOwnership',
                desc: 'Percentage of Shares Ownership',
                value: '',
                validation: {
                  required: true,
                },
              },
              ...KSCORE_GROUP,
              {
                name: 'remark',
                desc: 'Remark',
                value: '',
                validation: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
      {
        name: 'BUSINESS',
        desc: 'Business detail',
        display: 'SHOW',
        data: {
          id: 1,
          shareholders: [{ id: 1 }],
          inWhichSector: '1',
          inWhichSector1: '',
          inWhichSector2: '',
        },
        groups: businessUI,
      },
      {
        name: 'LOAN',
        display: 'SHOW',
        desc: 'Loan information',
        data: {
          loanFacilities: [{}],
          guaranteeRequests: [{}],
          securityTypes: [{}],
        },
        groups: loanUI,
      },
      {
        name: 'SUMMARY',
        display: 'SHOW',
        data: {
          borrowerRisk: [{ id: 1 }],
        },
        desc: 'Summary data',
        groups: summaryUI,
      },
      {
        name: 'ATTACHMENTS',
        display: 'SHOW',
        desc: 'Documents',
        groups: [
          {
            name: 'ATTACHMENTS',
            desc: 'Required Attachments',
            type: 'docs',
            data: DOCS_LIST,
            validation: {},
          },
        ],
      },
      {
        name: 'SUBMIT',
        display: 'SHOW',
        desc: 'Review all information',
        data: {
          borrowerRisk: [{ id: 1 }],
        },
        type: 'REVIEW',
        groups: [
          {
            name: 'SUBMIT',
            desc: 'SUBMIT',
            type: 'REVIEW',
            data: [
              {
                data: [
                  {
                    name: '0',
                    desc: 'I. Guarantee Scheme',
                    data: [
                      {
                        name: '0',
                        desc: 'Scheme Type',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                    ],
                  },
                  {
                    name: '1',
                    desc: 'II. PFI Information',
                    data: [
                      {
                        name: '0',
                        desc: 'PFI Name (ENGLISH)',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                      {
                        name: '1',
                        desc: 'PFI Name (KHMER)',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                      {
                        name: '2',
                        desc: 'PFI ID',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                      {
                        name: '3',
                        desc: 'Type of PFI',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'III. Borrower Information',
                    data: [
                      {
                        name: '0',
                        desc: 'Main Borrower, Shareholder',
                        type: 'subGroup',
                        border: true,
                        trailing: {
                          name: 'borrowerType',
                          desc: 'Individual',
                        },
                        data: [
                          {
                            name: 'lastName',
                            desc: 'Family Name',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'firstName',
                            desc: 'First Name',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'gender',
                            desc: 'Gender',
                            value: 'M',
                            type: 'list',
                            list: [
                              {
                                value: 'M',
                                desc: 'Male',
                              },
                              {
                                value: 'F',
                                desc: 'Female',
                              },
                            ],
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'dateOfBirth',
                            desc: 'Date Of Birth',
                            type: 'date',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'nationality',
                            desc: 'Nationality',
                            type: 'list',
                            list: [
                              {
                                value: 'CAM',
                                desc: 'Cambodian',
                              },
                              {
                                value: 'NONCAM',
                                desc: 'Non-Cambodian',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'phone',
                            desc: 'Telephone',
                            type: 'phone',
                            countryCode: 'kh',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'identity',
                            desc: 'P. Official Identity',
                            value: '',
                            type: 'list',
                            list: [...IDENTIFICATION_LIST],
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'referNumber',
                            desc: 'Reference Number',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'showOnLg',
                            desc: 'Show on LG',
                            type: 'radio',
                            list: [
                              {
                                value: '0',
                                desc: 'No',
                              },
                              {
                                value: '1',
                                desc: 'Yes',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'addressType',
                            desc: 'Address Type',
                            type: 'list',
                            list: [
                              {
                                value: '0',
                                desc: 'Residential',
                              },
                              {
                                value: '1',
                                desc: 'Work',
                              },
                              {
                                value: '2',
                                desc: 'Correspondence',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'houseStreet',
                            desc: 'House & Street',
                            value: '',
                            validation: {
                              required: false,
                              maxLength: 60,
                            },
                          },
                          {
                            name: 'city',
                            desc: 'City/Province',
                            value: '',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'district',
                            desc: 'Khan/District',
                            value: '',
                            type: 'address',
                            depend: 'city',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'sangkatCommune',
                            desc: 'Sangkat/Commune',
                            value: '',
                            depend: 'district',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'village',
                            desc: 'Phumi/Village',
                            value: '',
                            depend: 'sangkatCommune',
                            type: 'address',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'riskScore',
                            desc: 'Risk Score',
                            type: 'list',
                            data: KSCORE_GROUP,
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Co - Borrower',
                        type: 'subGroup',
                        border: true,
                        trailing: {
                          name: 'borrowerType',
                          desc: 'Individual',
                        },
                        data: [
                          {
                            name: 'lastName',
                            desc: 'Family Name',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'firstName',
                            desc: 'First Name',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'gender',
                            desc: 'Gender',
                            value: 'M',
                            type: 'list',
                            list: [
                              {
                                value: 'M',
                                desc: 'Male',
                              },
                              {
                                value: 'F',
                                desc: 'Female',
                              },
                            ],
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'dateOfBirth',
                            desc: 'Date Of Birth',
                            type: 'date',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'nationality',
                            desc: 'Nationality',
                            type: 'list',
                            list: [
                              {
                                value: 'CAM',
                                desc: 'Cambodian',
                              },
                              {
                                value: 'NONCAM',
                                desc: 'Non-Cambodian',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'phone',
                            desc: 'Telephone',
                            type: 'phone',
                            countryCode: 'kh',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'identity',
                            desc: 'P. Official Identity',
                            value: '',
                            type: 'list',
                            list: [...IDENTIFICATION_LIST],
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'referNumber',
                            desc: 'Reference Number',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'showOnLg',
                            desc: 'Show on LG',
                            type: 'radio',
                            list: [
                              {
                                value: '0',
                                desc: 'No',
                              },
                              {
                                value: '1',
                                desc: 'Yes',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'addressType',
                            desc: 'Address Type',
                            type: 'list',
                            list: [
                              {
                                value: '0',
                                desc: 'Residential',
                              },
                              {
                                value: '1',
                                desc: 'Work',
                              },
                              {
                                value: '2',
                                desc: 'Correspondence',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'houseStreet',
                            desc: 'House & Street',
                            value: '',
                            validation: {
                              required: false,
                              maxLength: 60,
                            },
                          },
                          {
                            name: 'city',
                            desc: 'City/Province',
                            value: '',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'district',
                            desc: 'Khan/District',
                            value: '',
                            type: 'address',
                            depend: 'city',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'sangkatCommune',
                            desc: 'Sangkat/Commune',
                            value: '',
                            depend: 'district',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'village',
                            desc: 'Phumi/Village',
                            value: '',
                            depend: 'sangkatCommune',
                            type: 'address',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'riskScore',
                            desc: 'Risk Score',
                            type: 'list',
                            data: KSCORE_GROUP,
                          },
                        ],
                        validation: {},
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'IV. Business Information',
                    data: [
                      {
                        name: '0',
                        desc: 'Information',
                        type: 'subGroup',
                        noHeader: true,
                        border: false,
                        data: [
                          {
                            name: 'companyId',
                            desc: 'Company ID',
                            gridRow: 12,
                            smallContent: true,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'companyName',
                            desc: 'Company Name',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'businessConstituency',
                            desc: 'Type of Business Constituency',
                            value: '',
                            type: 'list',
                            list: BUSINESS_CONSTITUENCY,
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'businessConstituencyOther',
                            desc: 'If Other, Please Specify',
                            depend: 'businessConstituency',
                            dependVal: '3',
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'annualTurnover',
                            desc: 'Annual Turnover of Borrowers / Business',
                            value: '',
                            type: 'list',
                            list: ANNUAL_TURNOVER_LIST,
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'totalAsset',
                            desc: 'Total Asset',
                            value: '',
                            type: 'list',
                            list: TOTAL_ASSET_LIST,
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'businessSector',
                            desc: 'Business Sector',
                            value: '',
                            type: 'list',
                            list: [
                              {
                                value: '0',
                                desc: 'Agriculture',
                              },
                              {
                                value: '1',
                                desc: 'Industry',
                              },
                              {
                                value: '2',
                                desc: 'Service and Trade',
                              },
                            ],
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'typeOfBorrower',
                            desc: 'Type of Borrower / Business',
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'numberOfFulltimeStaff',
                            desc: 'Number of Full-Time Staff',
                            value: '',
                            validation: {
                              required: true,
                              min: 1,
                            },
                          },
                          {
                            name: 'numberOfMaleFemale',
                            desc: 'Number of all Male & Female Staff',
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'mainBusinessActivity',
                            desc: 'Main Business Activity',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'detailBusinessActivities',
                            desc: 'Detail of Business Activities',
                            type: 'textArea',
                            row: 5,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Business Contact',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            name: '0',
                            desc: 'Contact Number',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: '1',
                            desc: 'Email Address',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Business Address',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            name: 'addressType',
                            desc: 'Address Type',
                            type: 'list',
                            list: [
                              {
                                value: '0',
                                desc: 'Residential',
                              },
                              {
                                value: '1',
                                desc: 'Work',
                              },
                              {
                                value: '2',
                                desc: 'Correspondence',
                              },
                            ],
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'houseStreet',
                            desc: 'House & Street',
                            value: '',
                            validation: {
                              required: true,
                              maxLength: 60,
                            },
                          },
                          {
                            name: 'city',
                            desc: 'City/Province',
                            value: '',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'district',
                            desc: 'Khan/District',
                            value: '',
                            type: 'address',
                            depend: 'city',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'sangkatCommune',
                            desc: 'Sangkat/Commune',
                            value: '',
                            depend: 'district',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'village',
                            desc: 'Phumi/Village',
                            value: '',
                            depend: 'sangkatCommune',
                            type: 'address',
                            validation: {
                              required: false,
                            },
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Business Registration / License',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            type: 'table',
                            data: [
                              {
                                key: 'no',
                                desc: 'No.',
                              },
                              {
                                key: 'certification',
                                desc: 'Certification / License.',
                              },
                              {
                                key: 'otherAuthority',
                                desc: 'Specify, If Other Authority',
                              },
                              {
                                key: 'referenceNo',
                                desc: 'Reference No.',
                              },
                              {
                                key: 'issueDate',
                                desc: 'Issue Date',
                              },
                              {
                                key: 'expiryDate',
                                desc: 'Expiry Date',
                              },
                            ],
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Business Shareholder',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            type: 'table',
                            data: [
                              {
                                key: 'indNo',
                                desc: 'CIF No.',
                              },
                              {
                                key: 'fullName',
                                desc: 'Full Name',
                              },
                              {
                                key: 'gender',
                                desc: 'Gender',
                              },
                              {
                                key: 'nationality',
                                desc: 'Nationality',
                              },
                              {
                                key: 'ContactNumber',
                                desc: 'Contact Number',
                              },
                              {
                                key: 'boardOfDirector',
                                desc: 'Board of Director',
                              },
                              {
                                key: 'percentShareOwnership',
                                desc: '% of Share Ownership',
                              },
                            ],
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Company Risk Score',
                        type: 'subGroup',
                        border: false,
                        data: KSCORE_GROUP,
                        validation: {},
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'V. Loan Information & Guarantee Requests',
                    data: [
                      {
                        name: '0',
                        desc: '',
                        type: 'subGroup',
                        noHeader: true,
                        border: false,
                        data: [
                          {
                            name: 'appNo',
                            desc: 'Loan Application No.',
                            gridRow: 12,
                            smallContent: true,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                        ],
                      },
                      {
                        name: '0',
                        desc: 'In Which Sector  (The Loan Will Be Used)',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            name: 'appNo',
                            desc: 'Business Sector Type',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'appNo',
                            desc: 'Business Sector 1st Level',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'appNo',
                            desc: 'Business Sector 2nd Level',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'appNo',
                            desc: 'Business Sector 3rd Level',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'appNo',
                            desc: 'Business in Negative List',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                        ],
                      },
                      {
                        name: '0',
                        desc: 'Loan Facility',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            type: 'table',
                            data: [
                              {
                                key: 'no',
                                desc: 'No.',
                              },
                              {
                                key: 'loanFacility',
                                desc: 'Loan Facility',
                              },
                              {
                                key: 'loanPurpose',
                                desc: 'Loan Purpose',
                              },
                              {
                                key: 'interestRatePerAnnum',
                                desc: 'Interest Rate Per-Annum',
                              },
                              {
                                key: 'loanTenureMonths',
                                desc: 'Loan Tenure (Months)',
                              },
                              {
                                key: 'loanAmount',
                                desc: 'Loan Amount',
                              },
                            ],
                            subData: [
                              {
                                name: 'total',
                                desc: 'Total Loan Amount ($)',
                                type: 'amount',
                                validation: {},
                              },
                            ],
                          },
                        ],
                      },
                      {
                        name: '0',
                        desc: 'Security Type',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            key: 'no',
                            desc: 'No.',
                          },
                          {
                            key: 'typeOfSecurity',
                            desc: 'Type of Securities',
                          },
                          {
                            key: 'securityEvidence',
                            desc: 'Security Evidence (Reference No.)',
                          },
                          {
                            key: 'estimatedValue',
                            desc: 'Estimated Value of Securities (USD)',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'VI. Summary of PFI’s Appraisal',
                    data: [
                      {
                        name: '0',
                        desc: 'Year 1',
                        type: 'subGroup',
                        border: true,
                        data: [
                          {
                            name: 'year',
                            desc: 'Year',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'currency',
                            desc: 'Currency',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'BorrowerTotalAssets',
                            desc: 'Borrower Total Assets',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Borrower Total Liability',
                            desc: 'Borrower Total Liability',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Borrower Total Equity',
                            desc: 'Borrower Total Equity',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Annual Turnover',
                            desc: 'Annual Turnover',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Borrower Net-Profit',
                            desc: 'Borrower Net-Profit',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Debt to Equidity Ratio',
                            desc: 'Debt to Equidity Ratio',
                            readyOnly: true,
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'Debt Service Coverage Ratio',
                            desc: 'Debt Service Coverage Ratio',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Return on Equidity',
                            desc: 'Return on Equidity',
                            readyOnly: true,
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'Liquidity Ratio',
                            desc: 'Liquidity Ratio',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Return on Assets',
                            desc: 'Return on Assets',
                            readyOnly: true,
                            validation: {
                              required: false,
                            },
                          },
                        ],
                        validation: {},
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'VII. Required Attachment',
                    data: [
                      {
                        type: 'subGroup',
                        noHeader: true,
                        data: [
                          {
                            type: 'docs',
                            name: '0',
                            data: DOCS_LIST,
                            validation: {},
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            validation: {},
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Women Entrepreneurs Guarantee Scheme',
    acronym: 'WEGS',
    expiryDate: '2030-01-01',
    status: 'ACTIVE',
    decs: '',
    hasPopup: true,
    schemeSize: '$200 million',
    sizeOfSME: '$150 million',
    sizeOfLargeFirm: '$50 million',
    appNo: '300000',
    cifNo: 'CIF00003',
    steps: [
      {
        name: 'BORROWER',
        desc: 'Borrower information',
        display: 'SHOW',
        groups: [
          {
            name: 'borrowerDetail',
            desc: 'Borrower Detail',
            data: [
              {
                name: 'id',
                desc: "PFI's CIF No",
                type: 'readonly',
                value: '',
                validation: {
                  required: false,
                },
              },
              {
                name: '',
                desc: '',
                value: '',
                validation: {
                  required: false,
                },
              },
              {
                name: 'name',
                desc: 'Full Name',
                type: 'readonly',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'referenceNo',
                desc: 'Reference No.',
                type: 'readonly',
                value: '',
                validation: {
                  required: true,
                },
              },
            ],
          },
          {
            name: 'personalInformation',
            desc: 'Personal Information',
            data: [
              {
                name: 'customerRelationship',
                desc: 'Customer Relationship',
                type: 'list',
                value: '10',
                list: [
                  {
                    value: '10',
                    desc: 'Main Borrower',
                  },
                  // {
                  //   value: '11',
                  //   desc: 'Main Borrower, Shareholder',
                  // },
                  {
                    value: '20',
                    desc: 'Co-Borrower',
                  },
                  // {
                  //   value: '21',
                  //   desc: 'Guarantor',
                  // },
                  // {
                  //   value: '22',
                  //   desc: 'Shareholder',
                  // },
                  // {
                  //   value: '23',
                  //   desc: 'Guarantor, Shareholder',
                  // },
                  // {
                  //   value: '24',
                  //   desc: 'Co-Borrower, Shareholder',
                  // },
                ],
                validation: {
                  required: true,
                },
              },
              {
                name: 'lastName',
                desc: 'Family Name',
                value: '',
                validation: {
                  required: true,
                  textOnly: true,
                },
              },
              {
                name: 'firstName',
                desc: 'First Name',
                value: '',
                validation: {
                  required: true,
                  textOnly: true,
                },
              },

              {
                name: 'gender',
                desc: 'Gender',
                value: 'M',
                type: 'list',
                list: [
                  {
                    value: 'M',
                    desc: 'Male',
                  },
                  {
                    value: 'F',
                    desc: 'Female',
                  },
                ],
                validation: {
                  required: true,
                },
              },
              {
                name: 'dateOfBirth',
                desc: 'Date Of Birth',
                type: 'date',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'nationality',
                desc: 'Nationality',
                type: 'list',
                list: [
                  {
                    value: 'CAM',
                    desc: 'Cambodian',
                  },
                  {
                    value: 'NONCAM',
                    desc: 'Non-Cambodian',
                  },
                ],
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'phone',
                desc: 'Telephone',
                type: 'phone',
                countryCode: 'kh',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'identity',
                desc: 'P. Official Identity',
                value: '',
                type: 'list',
                list: [...IDENTIFICATION_LIST],
                validation: {
                  required: true,
                },
              },
              {
                name: 'referNumber',
                desc: 'Reference Number',
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'showOnLg',
                desc: 'Show on LG',
                type: 'radio',
                list: [
                  {
                    value: '0',
                    desc: 'No',
                  },
                  {
                    value: '1',
                    desc: 'Yes',
                  },
                ],
                value: '',
                validation: {
                  required: true,
                },
              },
            ],
          },
          {
            name: 'addressInformation',
            desc: 'Address Information',
            data: [
              {
                name: 'addressType',
                desc: 'Address Type',
                type: 'list',
                list: [
                  {
                    value: '0',
                    desc: 'Residential',
                  },
                  {
                    value: '1',
                    desc: 'Work',
                  },
                  {
                    value: '2',
                    desc: 'Correspondence',
                  },
                ],
                value: '',
                validation: {
                  required: true,
                },
              },
              {
                name: 'houseStreet',
                desc: 'House & Street',
                value: '',
                validation: {
                  required: true,
                  maxLength: 60,
                },
              },
              {
                name: 'city',
                desc: 'City/Province',
                value: '',
                type: 'address',
                validation: {
                  required: true,
                },
              },
              {
                name: 'district',
                desc: 'Khan/District',
                value: '',
                type: 'address',
                depend: 'city',
                validation: {
                  required: true,
                },
              },
              {
                name: 'sangkatCommune',
                desc: 'Sangkat/Commune',
                value: '',
                depend: 'district',
                type: 'address',
                validation: {
                  required: true,
                },
              },
              {
                name: 'village',
                desc: 'Phumi/Village',
                value: '',
                depend: 'sangkatCommune',
                type: 'address',
                validation: {
                  required: false,
                },
              },
            ],
          },
          {
            name: 'miscellaneous',
            desc: 'Miscellaneous',
            type: 'list',
            data: [
              {
                name: 'boardOfDirectors',
                desc: 'Board of Directors',
                value: '',
                list: yesNoList,
                validation: {
                  required: true,
                },
              },
              {
                name: 'percentOwnership',
                desc: 'Percentage of Shares Ownership',
                value: '',
                validation: {
                  required: true,
                },
              },
              ...KSCORE_GROUP,
              {
                name: 'remark',
                desc: 'Remark',
                value: '',
                validation: {
                  required: true,
                },
              },
            ],
          },
        ],
      },
      {
        name: 'BUSINESS',
        desc: 'Business detail',
        display: 'SHOW',
        data: {
          id: 1,
          shareholders: [{ id: 1 }],
          inWhichSector: '1',
          inWhichSector1: '',
          inWhichSector2: '',
        },
        groups: businessUI,
      },
      {
        name: 'LOAN',
        display: 'SHOW',
        desc: 'Loan information',
        data: {
          loanFacilities: [{}],
          guaranteeRequests: [{}],
          securityTypes: [{}],
        },
        groups: loanUI,
      },
      {
        name: 'SUMMARY',
        display: 'SHOW',
        data: {
          borrowerRisk: [{ id: 1 }],
        },
        desc: 'Summary data',
        groups: summaryUI,
      },
      {
        name: 'ATTACHMENTS',
        display: 'SHOW',
        desc: 'Documents',
        groups: [
          {
            name: 'ATTACHMENTS',
            desc: 'Required Attachments',
            type: 'docs',
            data: DOCS_LIST,
            validation: {},
          },
        ],
      },
      {
        name: 'SUBMIT',
        display: 'SHOW',
        desc: 'Review all information',
        data: {
          borrowerRisk: [{ id: 1 }],
        },
        groups: [
          {
            name: 'SUBMIT',
            desc: 'SUBMIT',
            type: 'review',
            data: [
              {
                data: [
                  {
                    name: '0',
                    desc: 'I. Guarantee Scheme',
                    data: [
                      {
                        name: '0',
                        desc: 'Scheme Type',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                    ],
                  },
                  {
                    name: '1',
                    desc: 'II. PFI Information',
                    data: [
                      {
                        name: '0',
                        desc: 'PFI Name (ENGLISH)',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                      {
                        name: '1',
                        desc: 'PFI Name (KHMER)',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                      {
                        name: '2',
                        desc: 'PFI ID',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                      {
                        name: '3',
                        desc: 'Type of PFI',
                        readonly: true,
                        validation: {
                          required: false,
                        },
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'III. Borrower Information',
                    data: [
                      {
                        name: '0',
                        desc: 'Main Borrower, Shareholder',
                        type: 'subGroup',
                        border: true,
                        trailing: {
                          name: 'borrowerType',
                          desc: 'Individual',
                        },
                        data: [
                          {
                            name: 'lastName',
                            desc: 'Family Name',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'firstName',
                            desc: 'First Name',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'gender',
                            desc: 'Gender',
                            value: 'M',
                            type: 'list',
                            list: [
                              {
                                value: 'M',
                                desc: 'Male',
                              },
                              {
                                value: 'F',
                                desc: 'Female',
                              },
                            ],
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'dateOfBirth',
                            desc: 'Date Of Birth',
                            type: 'date',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'nationality',
                            desc: 'Nationality',
                            type: 'list',
                            list: [
                              {
                                value: 'CAM',
                                desc: 'Cambodian',
                              },
                              {
                                value: 'NONCAM',
                                desc: 'Non-Cambodian',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'phone',
                            desc: 'Telephone',
                            type: 'phone',
                            countryCode: 'kh',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'identity',
                            desc: 'P. Official Identity',
                            value: '',
                            type: 'list',
                            list: [...IDENTIFICATION_LIST],
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'referNumber',
                            desc: 'Reference Number',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'showOnLg',
                            desc: 'Show on LG',
                            type: 'radio',
                            list: [
                              {
                                value: '0',
                                desc: 'No',
                              },
                              {
                                value: '1',
                                desc: 'Yes',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'addressType',
                            desc: 'Address Type',
                            type: 'list',
                            list: [
                              {
                                value: '0',
                                desc: 'Residential',
                              },
                              {
                                value: '1',
                                desc: 'Work',
                              },
                              {
                                value: '2',
                                desc: 'Correspondence',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'houseStreet',
                            desc: 'House & Street',
                            value: '',
                            validation: {
                              required: false,
                              maxLength: 60,
                            },
                          },
                          {
                            name: 'city',
                            desc: 'City/Province',
                            value: '',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'district',
                            desc: 'Khan/District',
                            value: '',
                            type: 'address',
                            depend: 'city',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'sangkatCommune',
                            desc: 'Sangkat/Commune',
                            value: '',
                            depend: 'district',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'village',
                            desc: 'Phumi/Village',
                            value: '',
                            depend: 'sangkatCommune',
                            type: 'address',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'riskScore',
                            desc: 'Risk Score',
                            type: 'list',
                            data: KSCORE_GROUP,
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Co - Borrower',
                        type: 'subGroup',
                        border: true,
                        trailing: {
                          name: 'borrowerType',
                          desc: 'Individual',
                        },
                        data: [
                          {
                            name: 'lastName',
                            desc: 'Family Name',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'firstName',
                            desc: 'First Name',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'gender',
                            desc: 'Gender',
                            value: 'M',
                            type: 'list',
                            list: [
                              {
                                value: 'M',
                                desc: 'Male',
                              },
                              {
                                value: 'F',
                                desc: 'Female',
                              },
                            ],
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'dateOfBirth',
                            desc: 'Date Of Birth',
                            type: 'date',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'nationality',
                            desc: 'Nationality',
                            type: 'list',
                            list: [
                              {
                                value: 'CAM',
                                desc: 'Cambodian',
                              },
                              {
                                value: 'NONCAM',
                                desc: 'Non-Cambodian',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'phone',
                            desc: 'Telephone',
                            type: 'phone',
                            countryCode: 'kh',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'identity',
                            desc: 'P. Official Identity',
                            value: '',
                            type: 'list',
                            list: [...IDENTIFICATION_LIST],
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'referNumber',
                            desc: 'Reference Number',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'showOnLg',
                            desc: 'Show on LG',
                            type: 'radio',
                            list: [
                              {
                                value: '0',
                                desc: 'No',
                              },
                              {
                                value: '1',
                                desc: 'Yes',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'addressType',
                            desc: 'Address Type',
                            type: 'list',
                            list: [
                              {
                                value: '0',
                                desc: 'Residential',
                              },
                              {
                                value: '1',
                                desc: 'Work',
                              },
                              {
                                value: '2',
                                desc: 'Correspondence',
                              },
                            ],
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'houseStreet',
                            desc: 'House & Street',
                            value: '',
                            validation: {
                              required: false,
                              maxLength: 60,
                            },
                          },
                          {
                            name: 'city',
                            desc: 'City/Province',
                            value: '',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'district',
                            desc: 'Khan/District',
                            value: '',
                            type: 'address',
                            depend: 'city',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'sangkatCommune',
                            desc: 'Sangkat/Commune',
                            value: '',
                            depend: 'district',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'village',
                            desc: 'Phumi/Village',
                            value: '',
                            depend: 'sangkatCommune',
                            type: 'address',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'riskScore',
                            desc: 'Risk Score',
                            type: 'list',
                            data: KSCORE_GROUP,
                          },
                        ],
                        validation: {},
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'IV. Business Information',
                    data: [
                      {
                        name: '0',
                        desc: 'Information',
                        type: 'subGroup',
                        noHeader: true,
                        border: false,
                        data: [
                          {
                            name: 'companyId',
                            desc: 'Company ID',
                            gridRow: 12,
                            smallContent: true,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'companyName',
                            desc: 'Company Name',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'businessConstituency',
                            desc: 'Type of Business Constituency',
                            value: '',
                            type: 'list',
                            list: BUSINESS_CONSTITUENCY,
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'businessConstituencyOther',
                            desc: 'If Other, Please Specify',
                            depend: 'businessConstituency',
                            dependVal: '3',
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'annualTurnover',
                            desc: 'Annual Turnover of Borrowers / Business',
                            value: '',
                            type: 'list',
                            list: ANNUAL_TURNOVER_LIST,
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'totalAsset',
                            desc: 'Total Asset',
                            value: '',
                            type: 'list',
                            list: TOTAL_ASSET_LIST,
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'businessSector',
                            desc: 'Business Sector',
                            value: '',
                            type: 'list',
                            list: [
                              {
                                value: '0',
                                desc: 'Agriculture',
                              },
                              {
                                value: '1',
                                desc: 'Industry',
                              },
                              {
                                value: '2',
                                desc: 'Service and Trade',
                              },
                            ],
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'typeOfBorrower',
                            desc: 'Type of Borrower / Business',
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'numberOfFulltimeStaff',
                            desc: 'Number of Full-Time Staff',
                            value: '',
                            validation: {
                              required: true,
                              min: 1,
                            },
                          },
                          {
                            name: 'numberOfMaleFemale',
                            desc: 'Number of all Male & Female Staff',
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'mainBusinessActivity',
                            desc: 'Main Business Activity',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'detailBusinessActivities',
                            desc: 'Detail of Business Activities',
                            type: 'textArea',
                            row: 5,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Business Contact',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            name: '0',
                            desc: 'Contact Number',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: '1',
                            desc: 'Email Address',
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Business Address',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            name: 'addressType',
                            desc: 'Address Type',
                            type: 'list',
                            list: [
                              {
                                value: '0',
                                desc: 'Residential',
                              },
                              {
                                value: '1',
                                desc: 'Work',
                              },
                              {
                                value: '2',
                                desc: 'Correspondence',
                              },
                            ],
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'houseStreet',
                            desc: 'House & Street',
                            value: '',
                            validation: {
                              required: true,
                              maxLength: 60,
                            },
                          },
                          {
                            name: 'city',
                            desc: 'City/Province',
                            value: '',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'district',
                            desc: 'Khan/District',
                            value: '',
                            type: 'address',
                            depend: 'city',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'sangkatCommune',
                            desc: 'Sangkat/Commune',
                            value: '',
                            depend: 'district',
                            type: 'address',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'village',
                            desc: 'Phumi/Village',
                            value: '',
                            depend: 'sangkatCommune',
                            type: 'address',
                            validation: {
                              required: false,
                            },
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Business Registration / License',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            type: 'table',
                            data: [
                              {
                                key: 'no',
                                desc: 'No.',
                              },
                              {
                                key: 'certification',
                                desc: 'Certification / License.',
                              },
                              {
                                key: 'otherAuthority',
                                desc: 'Specify, If Other Authority',
                              },
                              {
                                key: 'referenceNo',
                                desc: 'Reference No.',
                              },
                              {
                                key: 'issueDate',
                                desc: 'Issue Date',
                              },
                              {
                                key: 'expiryDate',
                                desc: 'Expiry Date',
                              },
                            ],
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Business Shareholder',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            type: 'table',
                            data: [
                              {
                                key: 'indNo',
                                desc: 'CIF No.',
                              },
                              {
                                key: 'fullName',
                                desc: 'Full Name',
                              },
                              {
                                key: 'gender',
                                desc: 'Gender',
                              },
                              {
                                key: 'nationality',
                                desc: 'Nationality',
                              },
                              {
                                key: 'ContactNumber',
                                desc: 'Contact Number',
                              },
                              {
                                key: 'boardOfDirector',
                                desc: 'Board of Director',
                              },
                              {
                                key: 'percentShareOwnership',
                                desc: '% of Share Ownership',
                              },
                            ],
                          },
                        ],
                        validation: {},
                      },
                      {
                        name: '0',
                        desc: 'Company Risk Score',
                        type: 'subGroup',
                        border: false,
                        data: KSCORE_GROUP,
                        validation: {},
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'V. Loan Information & Guarantee Requests',
                    data: [
                      {
                        name: '0',
                        desc: '',
                        type: 'subGroup',
                        noHeader: true,
                        border: false,
                        data: [
                          {
                            name: 'appNo',
                            desc: 'Loan Application No.',
                            gridRow: 12,
                            smallContent: true,
                            value: '',
                            validation: {
                              required: true,
                            },
                          },
                        ],
                      },
                      {
                        name: '0',
                        desc: 'In Which Sector  (The Loan Will Be Used)',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            name: 'appNo',
                            desc: 'Business Sector Type',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'appNo',
                            desc: 'Business Sector 1st Level',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'appNo',
                            desc: 'Business Sector 2nd Level',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'appNo',
                            desc: 'Business Sector 3rd Level',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'appNo',
                            desc: 'Business in Negative List',
                            gridRow: 12,
                            value: '',
                            validation: {
                              required: false,
                            },
                          },
                        ],
                      },
                      {
                        name: '0',
                        desc: 'Loan Facility',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            type: 'table',
                            data: [
                              {
                                key: 'no',
                                desc: 'No.',
                              },
                              {
                                key: 'loanFacility',
                                desc: 'Loan Facility',
                              },
                              {
                                key: 'loanPurpose',
                                desc: 'Loan Purpose',
                              },
                              {
                                key: 'interestRatePerAnnum',
                                desc: 'Interest Rate Per-Annum',
                              },
                              {
                                key: 'loanTenureMonths',
                                desc: 'Loan Tenure (Months)',
                              },
                              {
                                key: 'loanAmount',
                                desc: 'Loan Amount',
                              },
                            ],
                            subData: [
                              {
                                name: 'total',
                                desc: 'Total Loan Amount ($)',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        name: '0',
                        desc: 'Security Type',
                        type: 'subGroup',
                        border: false,
                        data: [
                          {
                            key: 'no',
                            desc: 'No.',
                          },
                          {
                            key: 'typeOfSecurity',
                            desc: 'Type of Securities',
                          },
                          {
                            key: 'securityEvidence',
                            desc: 'Security Evidence (Reference No.)',
                          },
                          {
                            key: 'estimatedValue',
                            desc: 'Estimated Value of Securities (USD)',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'VI. Summary of PFI’s Appraisal',
                    data: [
                      {
                        name: '0',
                        desc: 'Year 1',
                        type: 'subGroup',
                        border: true,
                        data: [
                          {
                            name: 'year',
                            desc: 'Year',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'currency',
                            desc: 'Currency',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'BorrowerTotalAssets',
                            desc: 'Borrower Total Assets',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Borrower Total Liability',
                            desc: 'Borrower Total Liability',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Borrower Total Equity',
                            desc: 'Borrower Total Equity',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Annual Turnover',
                            desc: 'Annual Turnover',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Borrower Net-Profit',
                            desc: 'Borrower Net-Profit',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Debt to Equidity Ratio',
                            desc: 'Debt to Equidity Ratio',
                            readyOnly: true,
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'Debt Service Coverage Ratio',
                            desc: 'Debt Service Coverage Ratio',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Return on Equidity',
                            desc: 'Return on Equidity',
                            readyOnly: true,
                            validation: {
                              required: false,
                            },
                          },
                          {
                            name: 'Liquidity Ratio',
                            desc: 'Liquidity Ratio',
                            validation: {
                              required: true,
                            },
                          },
                          {
                            name: 'Return on Assets',
                            desc: 'Return on Assets',
                            readyOnly: true,
                            validation: {
                              required: false,
                            },
                          },
                        ],
                        validation: {},
                      },
                    ],
                  },
                  {
                    name: '0',
                    desc: 'VII. Required Attachment',
                    data: [
                      {
                        type: 'subGroup',
                        noHeader: true,
                        data: [
                          {
                            type: 'docs',
                            name: '0',
                            data: DOCS_LIST,
                            validation: {},
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            validation: {},
          },
        ],
      },
    ],
  },
];

export function getScheme(idx) {
  return schemeData[idx];
}

export function updateScheme(scheme) {
  schemeData[scheme.id] = scheme;
  return schemeData[scheme.id];
}

export function getListScheme() {
  return schemeData;
}

export const draftGuaranteesConst = [
  // {id:1}
];

export const submittedGuaranteesConst = [
  {
    applicationNo: 1,
    data: schemeData[0],
  },
  {
    applicationNo: 2,
    data: schemeData[1],
  },
];

let borrowerData = [
  {
    name: 'pfiCifNo',
    desc: "PFI's CIF No",
    gridRow: 12,
    value: '',
    validation: {
      required: true,
      maxLength: 60,
    },
  },
];

export const BORROWER_EXT_DATA_GROUP = {
  name: 'guaranteeApp',
  type: 'binding',
  desc: 'Application Binding Info',
  data: [
    {
      name: 'borrowerRelationship',
      desc: 'Borrower Relationship',
      type: 'list',
      value: '10',
      list: customerRelationshipList,
      validation: {
        required: true,
      },
    },
    {
      name: 'showOnLg',
      desc: 'Show on LG',
      type: 'radio',
      list: [
        {
          value: '0',
          desc: 'No',
        },
        {
          value: '1',
          desc: 'Yes',
        },
      ],
      value: '',
      validation: {
        required: true,
      },
    },
    ...KSCORE_GROUP,
  ],
};

let INDIVIDUAL_NAME_INFO = {
  name: 'individualInformation',
  desc: 'Individual Information',
  data: [
    {
      name: 'lastName',
      desc: 'Family Name',
      value: '',
      validation: {
        required: true,
        textOnly: true,
        maxLength: 60,
      },
    },
    {
      name: 'firstName',
      desc: 'First Name',
      value: '',
      validation: {
        required: true,
        textOnly: true,
        maxLength: 60,
      },
    },
    {
      name: 'gender',
      desc: 'Gender',
      type: 'list',
      list: GENDER_LIST_CONST,
      validation: {
        required: true,
      },
    },
    {
      name: 'dob',
      desc: 'Date Of Birth',
      type: 'date',
      value: '',
      validation: {
        required: true,
      },
    },
    {
      name: 'nationality',
      desc: 'Nationality',
      type: 'list',
      list: [
        {
          value: 'CAM',
          desc: 'Cambodian',
        },
        {
          value: 'NONCAM',
          desc: 'Non-Cambodian',
        },
      ],
      value: '',
      validation: {
        required: true,
      },
    },
    /*{
      name: 'phone',
      desc: 'Telephone',
      type: 'phone',
      countryCode: 'kh',
      value: '',
      validation: {
        required: false,
      },
    },*/
  ],
};

export const CONTACT_INFO = {
  name: 'contactInfo',
  desc: 'Contact',
  data: [
    {
      name: 'phoneArray',
      type: 'phoneArray',
      desc: '',
      value: '',
      validation: {
        required: true,
      },
    },
  ],
};
export const INDIVIDUAL_NAME_INFO_NEW = {
  name: 'individualInformation',
  desc: 'Basic Info',
  data: [
    {
      name: 'lastName',
      desc: 'Family Name',
      value: '',
      validation: {
        required: true,
        textOnly: true,
        maxLength: 60,
      },
    },
    {
      name: 'firstName',
      desc: 'First Name',
      value: '',
      validation: {
        required: true,
        textOnly: true,
        maxLength: 60,
      },
    },
    {
      name: 'gender',
      desc: 'Gender',
      type: 'list',
      list: GENDER_LIST_CONST,
      validation: {
        required: true,
      },
    },
    {
      name: 'nationality',
      desc: 'Nationality',
      type: 'nationality',
      value: '',
      validation: {
        required: true,
      },
    },
    {
      name: 'dob',
      desc: 'Date Of Birth',
      type: 'date',
      value: '',
      validation: {
        required: true,
      },
    },
  ],
};

export const INDIVIDUAL_ID_INFO = {
  name: 'officialIdentification',
  desc: 'Official Identification',
  data: [
    {
      type: 'listTable',
      name: '',
      desc: '',
      list: IDENTIFICATION_LIST,
      validation: {
        required: true,
      },
    },
  ],
};

export const BLACKLIST_TYPE_CONST = [
  {
    value: '0',
    desc: 'Internal',
  },
  {
    value: '1',
    desc: 'Global',
  },
];
let INDIVIDUAL_BACK_LIST = {
  name: 'blacklistDetail',
  desc: 'Blacklist Detail',
  data: [
    {
      name: 'blackListStatus',
      desc: 'Black List Status',
      type: 'radio',
      list: yesNoList,
      validation: {},
    },
    {
      name: 'blackListType',
      desc: 'Blacklist Type',
      type: 'list',
      list: BLACKLIST_TYPE_CONST,
      validation: {},
    },
    {
      name: 'remark',
      desc: 'Remark',
      type: 'textArea',
      row: 5,
      validation: {
        maxLength: 160,
      },
    },
  ],
};
export const INDIVIDUAL_ADDRESS_INFO = {
  name: 'addressInformation',
  desc: 'Current Address',
  data: [
    {
      name: 'addressType',
      desc: 'Address Type',
      type: 'list',
      list: [
        {
          value: '0',
          desc: 'Residential',
        },
        {
          value: '1',
          desc: 'Work',
        },
        {
          value: '2',
          desc: 'Correspondence',
        },
      ],
      value: '',
      validation: {
        required: true,
      },
    },
    {
      name: 'houseStreet',
      desc: 'House & Street',
      value: '',
      validation: {
        required: true,
        maxLength: 60,
      },
    },
    {
      name: 'city',
      desc: 'City/Province',
      value: '',
      type: 'address',
      validation: {
        required: true,
      },
    },
    {
      name: 'district',
      desc: 'Khan/District',
      value: '',
      type: 'address',
      depend: 'city',
      validation: {
        required: true,
      },
    },
    {
      name: 'sangkatCommune',
      desc: 'Sangkat/Commune',
      value: '',
      depend: 'district',
      type: 'address',
      validation: {
        required: true,
      },
    },
    {
      name: 'village',
      desc: 'Phumi/Village',
      value: '',
      depend: 'sangkatCommune',
      type: 'address',
      validation: {
        required: false,
      },
    },
  ],
};
export const newBorrowerUI = {
  groups: [INDIVIDUAL_NAME_INFO_NEW, INDIVIDUAL_ID_INFO, CONTACT_INFO, INDIVIDUAL_ADDRESS_INFO, BORROWER_EXT_DATA_GROUP],
};

let SHAREHOLDER_INFO = {
  name: 'shareholderInfo',
  type: 'binding',
  desc: 'Shareholder Info',
  data: [
    {
      name: 'boardOfDirectors',
      desc: 'Board of Directors',
      value: '',
      list: yesNoList,
      validation: {
        required: true,
      },
    },
    {
      name: 'percentOwnership',
      desc: 'Percentage of Shares Ownership',
      value: '',
      type: 'number',
      decimal: 2,
      validation: {
        required: true,
        min: 1,
        max: 100,
      },
    },
  ],
};

let ATTACHMENT_FILES = {
  name: 'attachment',
  desc: 'Attachment',
  data: [
    {
      name: 'file',
      desc: 'File',
      type: 'docs',
      list: yesNoList,
      validation: {},
    },
  ],
};

export const newBorrowerUIBlackList = {
  groups: [INDIVIDUAL_NAME_INFO, INDIVIDUAL_ID_INFO, INDIVIDUAL_BACK_LIST, ATTACHMENT_FILES],
};

export const newBorrowerUIShareholder = {
  groups: [INDIVIDUAL_NAME_INFO, INDIVIDUAL_ID_INFO, SHAREHOLDER_INFO],
};

export const IDENTIFICATION_LIST = [
  {
    value: '1',
    desc: 'National ID',
  },
  {
    value: '2',
    desc: 'Passport',
  },
  {
    value: '3',
    desc: 'Family Book',
  },
  {
    value: '4',
    desc: 'Driver License',
  },
  {
    value: '5',
    desc: 'Government Issued ID',
  },
  {
    value: '6',
    desc: 'Birth Certificate',
  },
  {
    value: '7',
    desc: 'Voter Req. Card',
  },
  {
    value: '8',
    desc: 'Tax Number',
  },
  {
    value: '9',
    desc: 'Resident Book',
  },
  {
    value: '10',
    desc: 'Local Authority Certify with photo',
  },
];

export const newShareholdersUI = {
  groups: [
    INDIVIDUAL_NAME_INFO_NEW,
    INDIVIDUAL_ID_INFO,
    CONTACT_INFO,
    INDIVIDUAL_ADDRESS_INFO,
    {
      name: 'guaranteeApp',
      type: 'binding',
      desc: 'Guarantee Application Binding Information',
      data: [
        {
          name: 'boardOfDirector',
          desc: 'Board of Director',
          type: 'radio',
          value: '',
          list: yesNoList,
          validation: {
            required: true,
          },
        },
        {
          name: 'percentOwnership',
          desc: '% Share of Ownership',
          value: '',
          type: 'number',
          decimal: 2,
          validation: {
            required: true,
            min: 0.01,
            max: 100,
          },
        },
      ],
    },
  ],
};

export const newGuarantorsUI = {
  groups: [
    INDIVIDUAL_NAME_INFO_NEW,
    INDIVIDUAL_ID_INFO,
    CONTACT_INFO,
    INDIVIDUAL_ADDRESS_INFO,
    {
      name: 'guarantorInformation',
      type: 'binding',
      desc: 'Application Binding Info',
      data: [
        {
          name: 'guarantorValue',
          desc: 'Guarantor Amount',
          gridRow: 12,
          type: 'amount',
          smallContent: true,
          value: '',
          validation: {
            required: true,
            min: 1,
          },
        },
        ...KSCORE_GROUP,
      ],
    },
  ],
};

// export const existingBorrowerUI = {
//   groups: [
//     {
//       name: 'borrowerDetail',
//       desc: 'Borrower Detail',
//       data: [
//         {
//           name: 'pfiCifNo',
//           desc: "PFI's CIF No",
//           gridRow: 12,
//           readonly: true,
//           value: '',
//           validation: {
//             required: true,
//           },
//         },
//         {
//           name: 'fullName',
//           desc: 'Full Name',
//           readonly: true,
//           value: '',
//           validation: {
//             required: true,
//             textOnly: true,
//           },
//         },
//         {
//           name: 'referenceNumber',
//           desc: 'Reference No.',
//           readonly: true,
//           value: '',
//           validation: {
//             required: true,
//           },
//         },
//       ],
//     },
//     BORROWER_EXT_DATA_GROUP,
//   ],
// };
export const existingShareholderUI = {
  groups: [
    {
      name: 'shareholderDetail',
      desc: 'Shareholder Detail',
      data: [
        {
          name: 'pfiCifNo',
          desc: "PFI's CIF No",
          gridRow: 12,
          smallContent: true,
          readonly: true,
          value: '',
          validation: {
            required: true,
          },
        },
        {
          name: 'fullName',
          desc: 'Full Name',
          readonly: true,
          value: '',
          validation: {
            required: true,
            textOnly: true,
          },
        },
        {
          name: 'referenceNumber',
          desc: 'Reference No.',
          readonly: true,
          value: '',
          validation: {
            required: true,
          },
        },
      ],
    },
    {
      name: 'guaranteeApp',
      desc: 'Guarantee Application Binding Information',
      data: [
        {
          name: 'boardOfDirector',
          desc: 'Board of Director',
          type: 'radio',
          value: '',
          list: yesNoList,
          validation: {
            required: true,
          },
        },
        {
          name: 'percentOwnership',
          desc: '% Share of Ownership',
          value: '',
          type: 'number',
          decimal: 2,
          validation: {
            required: true,
            min: 0.01,
            max: 100,
          },
        },
      ],
    },
  ],
};
export const existingGuarantorUI = {
  groups: [
    {
      name: 'guarantorInformation',
      desc: 'Guarantor Detail',
      data: [
        {
          name: 'pfiCifNo',
          desc: "PFI's CIF No",
          gridRow: 12,
          smallContent: true,
          readonly: true,
          value: '',
          validation: {
            required: true,
          },
        },
        {
          name: 'fullName',
          desc: 'Full Name',
          readonly: true,
          value: '',
          validation: {
            required: true,
            textOnly: true,
          },
        },
        {
          name: 'referenceNumber',
          desc: 'Reference No.',
          readonly: true,
          value: '',
          validation: {
            required: true,
          },
        },
      ],
    },
    {
      name: 'guarantorInformation',
      desc: 'Guarantor Information',
      data: [
        {
          name: 'guarantorValue',
          desc: 'Guarantor Value (USD)',
          gridRow: 12,
          type: 'amount',
          smallContent: true,
          value: '',
          validation: {
            required: true,
            min: 1,
          },
        },
      ],
    },
  ],
};

export function doAddNewBorrower(borrower) {}

// export const selectedBorrowers = [];

export const headCellsShareHolder = [
  {
    id: 'no',
    key: 'no',
    numeric: false,
    disablePadding: true,
    desc: 'No.',
  },
  {
    id: 'indNo',
    key: 'indNo',
    numeric: true,
    disablePadding: false,
    desc: 'CIF No.',
  },
  {
    id: 'fullName',
    key: 'fullName',
    numeric: false,
    disablePadding: false,
    desc: 'Full Name',
  },
  {
    id: 'gender',
    key: 'gender',
    numeric: false,
    disablePadding: false,
    desc: 'Gender',
  },
  {
    id: 'nationality',
    key: 'nationality',
    numeric: false,
    disablePadding: false,
    desc: 'Nationality',
  },
  {
    id: 'phone',
    key: 'phone',
    numeric: false,
    disablePadding: false,
    desc: 'Contact Number',
  },
  {
    id: 'boardOfDirector',
    key: 'boardOfDirector',
    numeric: false,
    disablePadding: false,
    desc: 'Board Of Director',
  },
  {
    id: 'percentOwnership',
    key: 'percentOwnership',
    numeric: false,
    disablePadding: false,
    desc: ' % of Share Ownership',
  },
  { id: 'status', key: 'status', numeric: false, disablePadding: false, desc: 'Status' },
];
export const headCellsShareHolderAdd = [
  {
    id: 'no',
    key: 'no',
    numeric: false,
    disablePadding: true,
    desc: 'No.',
  },
  {
    id: 'indNo',
    key: 'indNo',
    numeric: true,
    disablePadding: false,
    desc: 'Individual No.',
  },
  {
    id: 'fullName',
    key: 'fullName',
    numeric: false,
    disablePadding: false,
    desc: 'Full Name',
  },
  {
    id: 'gender',
    key: 'gender',
    numeric: false,
    disablePadding: false,
    desc: 'Gender',
  },
  {
    id: 'nationality',
    key: 'nationality',
    numeric: false,
    disablePadding: false,
    desc: 'Nationality',
  },
  {
    id: 'phone',
    key: 'phone',
    numeric: false,
    disablePadding: false,
    desc: 'Contact Number',
  },
  {
    id: 'status',
    key: 'status',
    numeric: false,
    disablePadding: false,
    desc: 'Status',
  },
];

export const headCellsCompanyGuarantee = [
  {
    id: 'no',
    key: 'no',
    numeric: false,
    disablePadding: true,
    desc: 'No.',
  },
  {
    id: 'companyName',
    key: 'companyName',
    numeric: false,
    disablePadding: false,
    desc: 'Name',
  },
  {
    id: 'guarantorValue',
    key: 'guarantorValue',
    numeric: false,
    disablePadding: false,
    desc: 'Guarantor Value',
  },
];
export const headCellsGuarantors = [
  {
    id: 'no',
    key: 'no',
    numeric: false,
    disablePadding: true,
    desc: 'No.',
  },
  {
    id: 'indNo',
    key: 'indNo',
    numeric: false,
    disablePadding: false,
    desc: 'Individual No.',
  },
  {
    id: 'fullName',
    key: 'fullName',
    numeric: false,
    disablePadding: false,
    desc: 'Name',
  },
  {
    id: 'guarantorValue',
    key: 'guarantorValue',
    numeric: false,
    disablePadding: false,
    desc: 'Guarantor Value',
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];
export const headCellsGuarantorsAddNew = [
  {
    id: 'no',
    key: 'no',
    numeric: false,
    disablePadding: true,
    desc: 'No.',
  },
  {
    id: 'indNo',
    key: 'indNo',
    numeric: false,
    disablePadding: false,
    desc: 'Individual No.',
  },
  {
    id: 'fullName',
    key: 'fullName',
    numeric: false,
    disablePadding: false,
    desc: 'Name',
  },
  {
    id: 'gender',
    key: 'gender',
    numeric: false,
    disablePadding: false,
    desc: 'Gender',
  },
  {
    id: 'nationality',
    key: 'nationality',
    numeric: false,
    disablePadding: false,
    desc: 'Nationality',
  },
  {
    id: 'phone',
    key: 'phone',
    numeric: false,
    disablePadding: false,
    desc: 'Contact Number (Primary)',
  },
  {
    id: 'status',
    key: 'status',
    numeric: false,
    disablePadding: false,
    desc: 'Status',
  },
];

export const detailDelAction = [
  { action: 'detail', label: 'Detail', icon: <Edit /> },
  { action: 'delete', label: 'Delete', icon: <Delete /> },
];

export const detailRemoveAction = [
  { action: 'detail', label: 'Detail', icon: <Edit /> },
  { action: 'delete', label: 'Remove', icon: <RemoveCircleOutlined /> },
];

export const editDelAction = [
  { action: 'edit', label: 'Edit', icon: <Edit /> },
  { action: 'delete', label: 'Delete', icon: <Delete /> },
];

export const detailDelEditAction = [
  { action: 'view', label: 'View', icon: <Visibility /> },
  { action: 'edit', label: 'Edit', icon: <Edit /> },
  { action: 'delete', label: 'Delete', icon: <Delete /> },
];

export const stypeFullRow = {
  width: '24.5%',
  // marginTop: 'auto',
  marginBottom: 'auto',
  wordBreak: 'break-word',
  paddingRight: '4.55555%',
  marginRight: '0.55555%',
};
export const styleSubItemConst = {
  width: '100%',
  border: '2px solid #028400',
  borderRadius: 12,
  marginBottom: 10,
};
export const fileCard = {
  borderRight: '#F0F2F5 2px solid',
  margin: '0px 5px',
  padding: '0px 5px',
};

export const checkedDocBtn = {
  backgroundColor: '#028400',
  borderRadius: 10,
  width: 200,
  height: 50,
  color: 'white',
};

export const checkedDocBtNot = {
  backgroundColor: '#F0F2F5',
  borderRadius: 10,
  width: 200,
  height: 50,
  color: 'black',
};

export const useStylesStep = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 0,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cellItem: {
    display: 'flex',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: '50%',
    },
    '$&focused': {
      color: 'red',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  tableBody: {
    backgroundColor: 'white',
  },
  control: {
    padding: theme.spacing(2),
  },
  focused: {
    color: '#028400 !important',
  },
  textField: {
    fontSize: '0.8rem',
    border: 'none',
    backgroundColor: '#F0F2F5',
    '& select:focus,input:focus': {
      // backgroundColor: 'white'
    },
  },
  textArea: {
    fontFamily: 'inherit',
    fontSize: '1rem',
    border: 'none',
    width: '100%',
    color: 'currentColor',
    backgroundColor: '#F0F2F5',
    '& select:focus,input:focus': {},
    padding: 12,
    resize: 'none',
  },
  dividerClass: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: '#028400',
  },
  buttonDialog: {
    width: 200,
    height: 50,
    marginLeft: 30,
    marginBottom: 30,
  },
  buttonDialogRight: {
    width: 200,
    height: 50,
    marginRight: 30,
    marginBottom: 30,
  },
}));

export function alertGenericMsg(error) {
  let msg = null;
  if (error && error.response && error.response.data) {
    let data = error.response.data;
    if (data) msg = data.message;
  } else if (error) {
    msg = error;
  }
  alert(msg || 'There was something issue in responding server');
}

export function getRiskGradeVal(scoreRangeValue) {
  let riskGrade = '';
  if (scoreRangeValue == 0) {
    riskGrade = 'EE';
  } else if (scoreRangeValue == 1) {
    riskGrade = 'DD';
  } else if (scoreRangeValue == 2) {
    riskGrade = 'CC';
  } else if (scoreRangeValue == 3) {
    riskGrade = 'BB';
  } else if (scoreRangeValue == 4) {
    riskGrade = 'AA';
  }
  return riskGrade;
}

export function buildRangeBusinessSector(min, max) {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    currencyDisplay: 'symbol',
  });

  if (min && max) {
    return `${formatter.format(Number(min))} - ${formatter.format(Number(max))}`;
  }
  if (!min && max) {
    return `<${formatter.format(Number(max))}`;
  }

  if (min && !max) {
    return `>${formatter.format(Number(min))}`;
  }
}
export function buildRangeBusinessStaff(min, max) {
  if (min && max) {
    return `${Number(min)} - ${Number(max)}`;
  }
  if (!min && max) {
    return `<${Number(max)}`;
  }

  if (min && !max) {
    return `>${Number(min)} `;
  }
}

export function getStaffCode(totalStaff, businessSectorVal, guarantee) {
  let selectItem = guarantee.enterpriseDefinition.find((item) => {
    if (item.Sec.includes(businessSectorVal)) {
      if (item.MaxStaff && item.MinStaff) {
        if (+item.MaxStaff >= +totalStaff && +totalStaff >= +item.MinStaff) {
          return true;
        }
      } else if (!item.MaxStaff && item.MinStaff) {
        if (+item.MinStaff < +totalStaff) {
          return true;
        }
      } else if (item.MaxStaff && !item.MinStaff) {
        if (+item.MaxStaff > +totalStaff) {
          return true;
        }
      }
    }
  });
  if (selectItem) {
    return selectItem.NumStaff_CodeSize;
  }
  return totalStaff;
}

export function getAssetsCode(totalAsset, businessSectorVal, guarantee) {
  let selectItem = guarantee.enterpriseDefinition.find((item) => {
    if (item.Sec.includes(businessSectorVal)) {
      if (item.Value == totalAsset) {
        return true;
      }
    }
    return false;
  });
  if (selectItem) {
    return selectItem.Asset_CodeSize;
  }
  return totalAsset;
}

export function getTurnoverCode(turnover, businessSectorVal, guarantee) {
  let selectItem = guarantee.enterpriseDefinition.find((item) => {
    if (item.Sec.includes(businessSectorVal)) {
      if (item.Value == turnover) {
        return true;
      }
    }
    return false;
  });
  if (selectItem) {
    return selectItem.TurnOver_CodeSize;
  }
  return turnover;
}

export function getListSelectTurnover(businessSectorVal) {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    currencyDisplay: 'code',
  });
  let result = [];
  typeOfBusiness.map((item, idx) => {
    if (item.Sec.includes(businessSectorVal)) {
      if (item.MaxTurnOver && !item.MinTurnOver) {
        result.push({
          value: item.Value,
          desc: '<' + formatter.format(+item.MaxTurnOver),
        });
      } else if (item.MaxTurnOver && item.MinTurnOver) {
        let desc = formatter.format(+item.MinTurnOver) + ' - ' + formatter.format(+item.MaxTurnOver);
        if (item.Value == 3) {
          let tmpMin = +item.MinTurnOver - 1;
          desc = `> ${formatter.format(tmpMin)}`;
        }
        result.push({
          value: item.Value,
          desc: desc,
        });
      } else if (!item.MaxTurnOver && item.MinTurnOver) {
        result.push({
          value: item.Value,
          desc: '>' + formatter.format(+item.MinTurnOver),
        });
      }
    }
  });
  return result;
}

export function getListSelectAsset(businessSectorVal) {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    currencyDisplay: 'code',
  });
  let result = [];
  let count = 0;
  typeOfBusiness.forEach((item) => {
    if (item.Sec.includes(businessSectorVal)) {
      if (item.MaxAsset && !item.MinAsset) {
        result.push({
          value: item.Value,
          desc: '<' + formatter.format(+item.MaxAsset),
        });
      } else if (item.MaxAsset && item.MinAsset) {
        let desc = formatter.format(+item.MinAsset) + ' - ' + formatter.format(+item.MaxAsset);
        if (item.Value == 3) {
          let tmpMin = +item.MinAsset - 1;
          desc = `> ${formatter.format(tmpMin)}`;
        }
        result.push({
          value: item.Value,
          desc: desc,
        });
      } else if (!item.MaxAsset && item.MinAsset) {
        result.push({
          value: item.Value,
          desc: '>' + formatter.format(+item.MinAsset),
        });
      }
    }
  });
  return result;
}

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#028400',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}))(TableCell);

export const SHAREHOLDER = 'SHAREHOLDER';
export const GUARANTOR = 'GUARANTOR';
export const CURRENCY_USD = 'USD';

export const THEME_DATETIME = createTheme({
  palette: {
    primary: {
      main: COLOR_BACKGROUND,
    },
    // secondary: {
    //   main: '#f44336',
    // },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: `${COLOR_BACKGROUND} !important`,
      },
    },
    MuiPickersDay: {
      $dateSelected: {
        backgroundColor: `${COLOR_BACKGROUND} !important`,
      },
    },
  },
});

export const usePopoverStyles = makeStyles({
  paper: {
    // backgroundColor: "green"
  },
});

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 17,
    left: 'calc(-50% + 17px)',
    right: 'calc(50% + 17px)',
  },
  labelContainer: {
    '$ &label': {
      color: 'red',
    },
    color: 'red',
  },
  active: {
    '& $line': {
      borderColor: '#028400',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#028400',
      height: 10,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    height: 10,
  },
})(StepConnector);

export const DATE_FORMAT_APP = 'DD-MMM-yyyy';
export const DATE_FORMAT_APP_UPERCASE = 'DD-MMM-YYYY';

export const REVIEW_APP_RECOMMENDATION = [
  {
    name: 'schemeType',
    setParams: 'BRGS,CFGS-TR,WEGS',
    currentVal: '',
    status: 'PASS',
    desc: 'Scheme Type',
  },
  {
    name: 'businessType',
    setParams: 'Micro, Small, Medium, Large',
    currentVal: '',
    status: 'PASS',
    desc: 'Business Type',
  },
  {
    name: 'maxLoanAmount',
    setParams: '',
    currentVal: '',
    status: 'FAILED',
    desc: 'Maximum Loan Amount',
  },
  {
    name: 'sgbl',
    setParams: '',
    currentVal: '',
    status: 'PASS',
    desc: 'Single Borrower Guarantee Limit (SBGL)',
  },
  {
    name: 'shareholderNationality',
    setParams: 'Cambodian & Non-Cambodian',
    currentVal: '',
    status: 'PASS',
    desc: 'Majority Shareholder (Nationality)',
  },
  {
    name: 'shareholderGender',
    setParams: 'Male & Female',
    currentVal: '',
    status: 'PASS',
    desc: 'Majority Shareholder (Gender)',
  },
  {
    name: 'businessSectorPriorityType',
    setParams: 'Priority & Non-Priority',
    currentVal: '',
    status: 'PASS',
    desc: 'Business Sector Priority Type',
  },
  {
    name: 'guaranteeCoverageRatio',
    setParams: '70% & 80%',
    currentVal: '',
    status: 'PASS',
    desc: 'Guarantee Coverage Ratio',
  },
  {
    name: 'financialViable',
    setParams: 'DSCR >= 1.2x',
    currentVal: '',
    status: 'PASS',
    desc: 'Financial Viable',
  },
  {
    name: 'negativeList',
    setParams: 'Yes / No',
    currentVal: '',
    status: 'PASS',
    desc: 'Negative List',
  },
  {
    name: 'riskLevel',
    setParams: 'Low, Medium, High',
    currentVal: '',
    status: 'PASS',
    desc: 'Risk Level',
  },
  {
    name: 'blacklist',
    setParams: 'Yes / No',
    currentVal: '',
    status: 'PASS',
    desc: 'Blacklist',
  },
];
export const REVIEW_APP_RECOMMENDATION_AMEND = [
  {
    name: 'sgbl',
    setParams: '',
    currentVal: '',
    status: 'PASS',
    desc: 'Single Borrower Guarantee Limit (SBGL)',
  },
  {
    name: 'shareholderNationality',
    setParams: 'Cambodian & Non-Cambodian',
    currentVal: '',
    status: 'PASS',
    desc: 'Majority Shareholder (Nationality)',
  },
  {
    name: 'shareholderGender',
    setParams: 'Male & Female',
    currentVal: '',
    status: 'PASS',
    desc: 'Majority Shareholder (Gender)',
  },
  {
    name: 'riskLevel',
    setParams: 'Low, Medium, High',
    currentVal: '',
    status: 'PASS',
    desc: 'Risk Level',
  },
  {
    name: 'blacklist',
    setParams: 'Yes / No',
    currentVal: '',
    status: 'PASS',
    desc: 'Blacklist',
  },
];

export const GA_DOCUMENT_CHECK_LIST = [
  {
    idx: 0,
    name: 'doc0',
    desc: 'CGCC Cover Letter',
  },
  {
    idx: 1,
    name: 'doc1',
    desc: "Borrower's Loan Application",
  },
  {
    idx: 2,
    name: 'doc2',
    desc: "PFI's Loan Appraisal Report",
  },
  {
    idx: 3,
    name: 'doc3',
    desc: 'Borrower’s CBC Report',
  },
  {
    idx: 4,
    name: 'doc4',
    desc: 'Borrower’s Shareholder/Director’s Official Identity Documents',
  },
  {
    idx: 5,
    name: 'doc5',
    desc: 'Borrower Business Registration and/or Licenses',
  },
  {
    idx: 6,
    name: 'doc6',
    desc: 'Security’s Evidence (Hard Title, Soft Title, Ownership Certificate, Etc.)',
  },
  {
    idx: 7,
    name: 'doc7',
    desc: 'Report on Borrower’s Security Valuation (Either Internal or External) ',
  },
  {
    idx: 8,
    name: 'doc8',
    desc: 'PFI’s Loan Letter of Offer (Including Terms & Conditions of the Loan)',
  },
  {
    idx: 9,
    name: 'doc9',
    desc: 'Evidence of SME Bank of Cambodia’s Approval of the Loan Drawdown ',
  },
  {
    idx: 10,
    name: 'doc10',
    desc: 'Borrower’s consent for the disclosure and usage of their information by CGCC',
  },
  {
    idx: 11,
    name: 'doc11',
    desc: 'Business Photos',
  },
];

export const LIST_PRIORITY_TYPE = ['Priority', 'Non-Priority'];

export const buildCustomerRelationship = (row, guarantors) => {
  let idx = customerRelationshipList.findIndex((item) => item.value == row.borrowerRelationship);
  let result = '';
  if (idx >= 0) {
    let borrowerRelationship = `${customerRelationshipList[idx].desc},`;
    if (row.isShareholder) {
      result = `${borrowerRelationship} Shareholder`;
    } else {
      result = customerRelationshipList[idx].desc;
    }
  } else if (row.isShareholder) {
    result = `Shareholder`;
  } else {
    result = row.borrowerRelationship || '';
  }

  if (guarantors && guarantors.length > 0) {
    if (guarantors.find((item) => item._id === row._id)) {
      if (result) {
        result = `${result}, Guarantor`;
      } else {
        result = 'Guarantor';
      }
    }
  }

  return <TableCell>{result}</TableCell>;
};

export const buildOSGuaranteeAmount = (item) => {
  // console.log(item);
  if (item.sgbl) {
    if (item.sgbl.msg === 'NOCHECK') {
      return 0;
    }
    return item.sgbl.totalGuaranteeAmount;
    // if (item.sgbl.sameInfo && item.sgbl.sameInfo == 'YES') {
    //   return 'SAME';
    // } else {
    //   return item.sgbl.totalGuaranteeAmount;
    // }
  } else {
    return 0;
  }
};

export const REVIEW_STATUS_RISK = ['FAILED'];
export const buildStatusSGBLReview = (item, totalRemainingGA, highestOSGA, sgblSetting, sgblCurrentApp) => {
  if (item.sgbl) {
    if (item.sgbl.msg === 'NOCHECK') {
      return 'PASS';
    }
    if (item.sgbl.sameInfo && item.sgbl.sameInfo == 'YES') {
      return 'FAILED';
    }
  }
  let remaining = sgblSetting - item.temporaryOSGA;
  if (remaining < 0) {
    return 'FAILED';
  } else {
    return 'PASS';
  }
  // } else if (remaining >= sgblCurrentApp) {
  //   return 'PASS';
  // } else if (remaining < sgblCurrentApp) {
  //   return 'PASS';
  // }
};

export const buildGuaranteeFeePerAnnum = (lg) => {
  let isPriority = false;
  let guaranteeFeePerAnnum = 0;
  if (lg) {
    if (lg.level && lg.level.priority && 'priority' == lg.level.priority.toLowerCase()) {
      isPriority = true;
    }
    if (isPriority) {
      guaranteeFeePerAnnum = lg.sectorFee;
    } else {
      guaranteeFeePerAnnum = lg.sectorFeeNonPriority;
    }
  }
  return guaranteeFeePerAnnum;
};

export const resetBindingBorrower = (row) => {
  row.borrowerRelationship = '';
  row.kscore = null;
  row.nokscore = null;
  row.showOnLg = null;
  row.score = null;
  row.riskGrade = null;
  row.scoreRange = null;
  return row;
};

export const resetBindingShareholder = (row) => {
  row.percentOwnership = null;
  row.boardOfDirector = null;
  return row;
};

export const resetBindingGuarantor = (row, selectedBorrowers) => {
  // const borrowIdx = selectedBorrowers?.findIndex((borrow) => borrow._id === row._id);
  row.guarantorValue = null;
  // row.kscore = selectedBorrowers?.[borrowIdx]?.kscore || null;
  // row.nokscore = selectedBorrowers?.[borrowIdx]?.nokscore || null;
  // row.showOnLg = null;
  // row.score = selectedBorrowers?.[borrowIdx]?.score || null;
  // row.riskGrade = selectedBorrowers?.[borrowIdx]?.riskGrade || null;
  // row.scoreRange = selectedBorrowers?.[borrowIdx]?.scoreRange || null;
  return row;
};

export const formatMoney = (amount, currency = CURRENCY_USD) => {
  if (!amount) {
    amount = 0;
  }
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
    currencyDisplay: 'code',
  });
  let result = formatter.format(amount);
  return result;
};

export const getLoanCurrencyInListLoans = (loanArr) => {
  if (loanArr && loanArr.length > 0) {
    let currency = loanArr[0].currency;
    return currency;
  }
  return 'USD';
};

export const formatUSDAmount = (val) => {
  if (val != null && val != undefined) {
    return `USD ${val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
  return 'N/A';
};

export const formatKHRAmount = (val) => {
  if (val != null && val != undefined) {
    return `KHR ${val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
  return 'N/A';
};

export const formatUSDAmountWithoutSymbol = (val) => {
  let formater = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    currencyDisplay: 'code',
  });
  let formatVal = formater.format(val);
  return formatVal.replace('USD', '');
};

export const formatKHRAmountWithoutSymbol = (val) => {
  let formater = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    decimal: ',',
    maximumFractionDigits: 0,
    currencyDisplay: 'code',
  });

  let formatVal = formater.format(val);
  return formatVal.replace('USD', '');
};

export const THEME = createTheme({
  overrides: {
    MuiTextField: {
      root: {
        border: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#F0F2F5',
        borderRadius: 2,
      },
      notchedOutline: {
        border: 'none',
      },
      input: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },
});

// export const AUTHORIZER_BLACKLIST_ROLE=['cgcc_da1_rmc_manager', 'cgcc_da2_rmc_manager', 'cgcc_rmc_manager','cgcc_rmc_head']
export const buildBoardOfDirector = (val) => {
  if (val) {
    let idx = +val;
    return YES_NO_NA_LIST[idx].desc;
  }
  return '';
};

export const buildGender = (val) => {
  if (val === 'M') return GENDER_LIST_CONST[0].desc;
  else return GENDER_LIST_CONST[1].desc;
};

export const buildBlacklistType = (val) => {
  if (val) {
    let idx = +val;
    return BLACKLIST_TYPE_CONST[idx].desc;
  }
  return '';
};

export const validPhoneArray = (phoneArray) => {
  if (phoneArray) {
    let findInValidPhone = phoneArray.filter((item) => {
      if (item.value) {
        if (item.value.length === 11 || item.value.length === 12) {
          return false;
        }
      }
      return true;
    });
    if (findInValidPhone && findInValidPhone.length > 0) {
      store.dispatch(setAlert('Valid length of phone number is 8 or 9'));
      return false;
    }
    return true;
  } else {
    store.dispatch(setAlert('Please insert at least a phone number'));
    return false;
  }
};

export const buildPhoneArray = (item) => {
  if (item.phoneArray && item.phoneArray.length > 0) {
    return (
      <>
        <p>{item.phoneArray[0].formattedVal}</p>
        {/*{item.phoneArray.map((sub) => {
          return (
            <>
              <p>
                {sub.formattedVal} <br />
              </p>
            </>
          );
        })}*/}
      </>
    );
  }
  return '';
};

export const validRequiredDataInGroup = (item, values) => {
  for (const subItem of item.data) {
    if (subItem.validation && subItem.validation.required) {
      if (!values[subItem.name]) {
        return false;
      }
    }
  }
  return true;
};

export const validateIndividualData = (values) => {
  if (values['dob']) {
    let val = values['dob'];
    var ageDifMs = Date.now() - val;
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    let diff = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (diff < 16) {
      alert('The borrower must be over 16 years old.');
      return false;
    }
  }

  let validPhone = validPhoneArray(values.phoneArray);
  if (!validPhone) {
    return false;
  }

  if (values['score'] && values['scoreMin'] && values['scoreMax']) {
    if (values['score'] < values['scoreMin'] || values['score'] > values['scoreMax']) {
      alert('Valid score must be from:' + values['scoreMin'] + ' to ' + values['scoreMax']);
      return false;
    }
  }

  return true;
};

export function getLoanCurrencyObj(guarantee) {
  const loan = guarantee.steps.find((item) => item.name == 'LOAN');
  if (loan) {
    const loanGroups = loan.groups;
    let loanFacilityGroup = loanGroups.find((item) => item.name === 'loanFacility');
    if (loanFacilityGroup && loanFacilityGroup.selectedData && loanFacilityGroup.selectedData[0]) {
      let result = LIST_CURRENCY_LOAN().find((item) => item.value === loanFacilityGroup.selectedData[0].currency);
      return result;
    }
  }
  return LIST_CURRENCY_LOAN()[0];
}
export function getLoanCurrency(loan) {
  // if (loan) {
  //   if (loan.name === 'LOAN') {
  //     let guarantorTmp = [];
  const loanGroups = loan.groups;
  let loanFacilityGroup = loanGroups.find((item) => item.name === 'loanFacility');
  // if (loanFacilityGroup) {
  //   let selectedLoan = loanFacilityGroup.selectedLoan;
  //   if (selectedLoan && selectedLoan.guarantor) {
  //     guarantorTmp = selectedLoan.guarantor;
  //   }
  // }

  if (loanFacilityGroup && loanFacilityGroup.selectedData && loanFacilityGroup.selectedData[0]) {
    return loanFacilityGroup.selectedData[0].currency;
    //   }
    // }
  }
}

export function getLoanCurrencySymbol(stepItem) {
  let currency = getLoanCurrency(stepItem);
  let ccy = LIST_CURRENCY_LOAN().find((item) => item.value === currency);
  if (ccy) return ccy.value;
  else return LIST_CURRENCY_LOAN()[0].value;
}

export function normalizeNationality(key, value) {
  if (key === 'GF') {
    value = 'French Guiana';
  } else if (key === 'PF') {
    value = 'French Polynesia';
  } else if (key === 'TF') {
    value = 'French Southern Territories';
  } else if (key === 'CD') {
    value = 'Congo (the Democratic Republic of the)';
  } else if (key === 'CG') {
    value = 'Congo (the)';
  } else if (key === 'DM') {
    value = 'Dominica';
  } else if (key === 'DO') {
    value = 'Dominican Republic (the)';
  }
  return [key, value];
}

export const WEGS_MODAL_DATA = [
  {
    label: 'Woman owns the majority of shares',
    value: '0',
  },
  {
    label: 'Management or decision-maker is a woman',
    value: '1',
  },
  {
    label: 'Woman represents the company on any legal business registration',
    value: '2',
  },
  {
    label: 'Majority of the employees are women ',
    value: '3',
  },
];

export const BUSINESS_SECTOR_MATRIX = [
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'M',
    Asset: 'M',
    Formular: 'MMM',
    FirmDefinition: 'Micro',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'M',
    Asset: 'S',
    Formular: 'MMS',
    FirmDefinition: 'Micro',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'M',
    Asset: 'D',
    Formular: 'MMD',
    FirmDefinition: 'Micro',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'M',
    Asset: 'L',
    Formular: 'MML',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'S',
    Asset: 'M',
    Formular: 'MSM',
    FirmDefinition: 'Micro',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'S',
    Asset: 'S',
    Formular: 'MSS',
    FirmDefinition: 'Micro',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'S',
    Asset: 'D',
    Formular: 'MSD',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'S',
    Asset: 'L',
    Formular: 'MSL',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'D',
    Asset: 'M',
    Formular: 'MDM',
    FirmDefinition: 'Micro',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'D',
    Asset: 'S',
    Formular: 'MDS',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'D',
    Asset: 'D',
    Formular: 'MDD',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'D',
    Asset: 'L',
    Formular: 'MDL',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'L',
    Asset: 'M',
    Formular: 'MLM',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'L',
    Asset: 'S',
    Formular: 'MLS',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'L',
    Asset: 'D',
    Formular: 'MLD',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Agriculture',
    Staff: 'M',
    Turnover: 'L',
    Asset: 'L',
    Formular: 'MLL',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'M',
    Asset: 'M',
    Formular: 'SMM',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'M',
    Asset: 'S',
    Formular: 'SMS',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'M',
    Asset: 'D',
    Formular: 'SMD',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'M',
    Asset: 'L',
    Formular: 'SML',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'S',
    Asset: 'M',
    Formular: 'SSM',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'S',
    Asset: 'S',
    Formular: 'SSS',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'S',
    Asset: 'D',
    Formular: 'SSD',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'S',
    Asset: 'L',
    Formular: 'SSL',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'D',
    Asset: 'M',
    Formular: 'SDM',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'D',
    Asset: 'S',
    Formular: 'SDS',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'D',
    Asset: 'D',
    Formular: 'SDD',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'D',
    Asset: 'L',
    Formular: 'SDL',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'L',
    Asset: 'M',
    Formular: 'SLM',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'L',
    Asset: 'S',
    Formular: 'SLS',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'L',
    Asset: 'D',
    Formular: 'SLD',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Industry',
    Staff: 'S',
    Turnover: 'L',
    Asset: 'L',
    Formular: 'SLL',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'M',
    Asset: 'M',
    Formular: 'DMM',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'M',
    Asset: 'S',
    Formular: 'DMS',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'M',
    Asset: 'D',
    Formular: 'DMD',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'M',
    Asset: 'L',
    Formular: 'DML',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'S',
    Asset: 'M',
    Formular: 'DSM',
    FirmDefinition: 'SME - Small',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'S',
    Asset: 'S',
    Formular: 'DSS',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'S',
    Asset: 'D',
    Formular: 'DSD',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'S',
    Asset: 'L',
    Formular: 'DSL',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'D',
    Asset: 'M',
    Formular: 'DDM',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'D',
    Asset: 'S',
    Formular: 'DDS',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'D',
    Asset: 'D',
    Formular: 'DDD',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'D',
    Asset: 'L',
    Formular: 'DDL',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'L',
    Asset: 'M',
    Formular: 'DLM',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'L',
    Asset: 'S',
    Formular: 'DLS',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'L',
    Asset: 'D',
    Formular: 'DLD',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'D',
    Turnover: 'L',
    Asset: 'L',
    Formular: 'DLL',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'M',
    Asset: 'M',
    Formular: 'LMM',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'M',
    Asset: 'S',
    Formular: 'LMS',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'M',
    Asset: 'D',
    Formular: 'LMD',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'M',
    Asset: 'L',
    Formular: 'LML',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'S',
    Asset: 'M',
    Formular: 'LSM',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'S',
    Asset: 'S',
    Formular: 'LSS',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'S',
    Asset: 'D',
    Formular: 'LSD',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'S',
    Asset: 'L',
    Formular: 'LSL',
    FirmDefinition: 'Large Firm',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'D',
    Asset: 'M',
    Formular: 'LDM',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'D',
    Asset: 'S',
    Formular: 'LDS',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'D',
    Asset: 'D',
    Formular: 'LDD',
    FirmDefinition: 'Large Firm',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'D',
    Asset: 'L',
    Formular: 'LDL',
    FirmDefinition: 'Large Firm',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'L',
    Asset: 'M',
    Formular: 'LLM',
    FirmDefinition: 'SME - Medium',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'L',
    Asset: 'S',
    Formular: 'LLS',
    FirmDefinition: 'Large Firm',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'L',
    Asset: 'D',
    Formular: 'LLD',
    FirmDefinition: 'Large Firm',
  },
  {
    Business_sector: 'Service and Trade',
    Staff: 'L',
    Turnover: 'L',
    Asset: 'L',
    Formular: 'LLL',
    FirmDefinition: 'Large Firm',
  },
];

export const checkValidRangeEnterprise = (agriculture, nameOfSector) => {
  if (agriculture) {
    let invalidRange = agriculture.find(
      (item) =>
        Number(item.MaxTurnOver) < Number(item.MinTurnOver) ||
        Number(item.MaxAsset) < Number(item.MinAsset) ||
        Number(item.MaxStaff) < Number(item.MinStaff),
    );

    if (invalidRange) {
      alert('Range of ' + nameOfSector + ' is not valid. Max value must be greater than min value');
      return false;
    }
    let listMaxTurnOver = [];
    let listMaxAsset = [];
    let listMaxStaff = [];
    agriculture.forEach((item) => {
      listMaxTurnOver.push(item.MaxTurnOver);
      listMaxAsset.push(item.MaxAsset);
      listMaxStaff.push(item.MaxStaff);
    });
    if (listMaxTurnOver) {
      let overlap = false;
      for (const staff of listMaxTurnOver) {
        let overlapList = agriculture.filter(
          (sub) => Number(sub.MinTurnOver) <= Number(staff) && Number(staff) <= Number(sub.MaxTurnOver),
        );
        if (overlapList.length > 1) {
          overlap = true;
          break;
        }
      }
      if (overlap) {
        alert('Range TurnOver of ' + nameOfSector + ' is overlapped.');
        return false;
      }
    }

    if (listMaxAsset) {
      let overlap = false;
      for (const staff of listMaxAsset) {
        let overlapList = agriculture.filter(
          (sub) => Number(sub.MinAsset) <= Number(staff) && Number(staff) <= Number(sub.MaxAsset),
        );
        if (overlapList.length > 1) {
          overlap = true;
          break;
        }
      }
      if (overlap) {
        alert('Range Asset of ' + nameOfSector + ' is overlapped.');
        return false;
      }
    }

    if (listMaxStaff) {
      let overlap = false;
      for (const staff of nameOfSector) {
        let overlapList = agriculture.filter(
          (sub) => Number(sub.MinStaff) <= Number(staff) && Number(staff) <= Number(sub.MaxStaff),
        );
        if (overlapList.length > 1) {
          overlap = true;
          break;
        }
      }
      if (overlap) {
        alert('Range Staff of ' + nameOfSector + ' is overlapped.');
        return false;
      }
    }
  }
  return true;
};

export const getAllIndividualInApp = (application) => {
  let steps = application.steps;
  let businesses = steps.find((item) => item.name == 'BUSINESS');
  let borrowers = steps.find((item) => item.name == 'BORROWER');

  let borrowersSelected = [];
  let mapInd = new Map();
  if (borrowers) {
    borrowersSelected = borrowers.selectedBorrowers || [];
    borrowersSelected.forEach((item) => {
      mapInd.set(item._id, item);
    });
  }
  let selectedBusiness = businesses.selectedBusiness;
  let shareholderInformationOrg = selectedBusiness.shareholderInformation;
  if (shareholderInformationOrg && shareholderInformationOrg.length > 0) {
    shareholderInformationOrg.forEach((item) => {
      if (!mapInd.has(item._id)) {
        mapInd.set(item._id, item);
      }
    });
  }

  let loan = steps.find((item) => item.name == 'LOAN');
  let selectedLoan = loan.selectedLoan;
  if (selectedLoan && selectedLoan.individualGuarantor && selectedLoan.individualGuarantor.length > 0) {
    selectedLoan.individualGuarantor.forEach((item) => {
      if (!mapInd.has(item._id)) {
        mapInd.set(item._id, item);
      }
    });
  }
  let result = Array.from(mapInd.values());
  return result;
};

export const StyledBadge = withStyles((theme) => ({
  badge: {
    // right: -30,
    // top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 40px',
    backgroundColor: 'red',
    color: 'white',
  },
}))(Badge);

export const getLoanTuneFromLoanFacility = (guarantee) => {
  let loanStep = guarantee.steps.find((item) => item.name == 'LOAN');
  let loanFacility = loanStep.groups.find((item) => item.name == 'loanFacility');
  let loanTenure = '';
  if (loanFacility.selectedData && loanFacility.selectedData.length > 0) {
    loanTenure = loanFacility.selectedData[0].loanTenure;
    let maxLoanTenure = getMaximumTenureConfig(guarantee);
    if (+loanTenure > maxLoanTenure) {
      loanTenure = maxLoanTenure;
    }
  }
  return loanTenure;
};

export const LOAN_CURRENCIES = () => {
  return LIST_CURRENCY_LOAN().map((item) => {
    return { value: item.value, code: item.value, desc: item.desc };
  });
  // return [
  //   { value: 'USD',code:'USD', desc: 'USD' },
  //   { value: 'KHR',code:'KHR', desc: 'KHR' },
  // ]
};

export const getStartDate = (today) => {
  var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
  return myToday;
};

export const getEndDate = (today) => {
  var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
  return myToday;
};

export const SX_FILTER = {
  boxShadow: 'none',
  borderRadius: '6px !important',
  '.MuiOutlinedInput-notchedOutline': { border: 0, borderColor: 'white' },
  backgroundColor: COLOR_BACKGROUND_DISABLED,
};

export const getBlacklistTypeDesc = (val) => {
  if (val != '' || val != null || val != undefined) {
    let item = BLACKLIST_TYPE_CONST.find((item) => item.value == val);
    if (item) {
      return item.desc;
    }
  }
  return '';
};

export const getCancellationDate = (lg) => {
  if (lg && lg.changeRequest && lg.changeRequest.length > 0) {
    let find = lg.changeRequest.find((item) => item.requestType === 'CANCELLATION');
    if (find && find.cancellationDueDate) {
      if (moment(find.cancellationDueDate).isValid()) {
        return moment(find.cancellationDueDate).format(DATE_FORMAT_APP);
      }
    }
  }
  let effectiveDate = lg.effectiveDate;
  if (moment(effectiveDate).isValid()) {
    let cancellationDay = moment(effectiveDate).add(25, 'days').format(DATE_FORMAT_APP);
    return cancellationDay;
  }
  return 'N/A';
};

export const getMaturityDate = (lg) => {
  if (lg) {
    let effectiveDate = moment(lg.effectiveDate);
    let guaranteeTenureMonth = lg?.guaranteeRequestInformation?.guaranteeTenureMonths || 0;
    let result = moment(effectiveDate).add(guaranteeTenureMonth, 'months').add(-1, 'days').format(DATE_FORMAT_APP);
    return result;
  }
  return 'N/A';
};

export const getValidityDate = (lg) => {
  if (lg) {
    let maturityDate = getMaturityDate(lg);
    if (moment(maturityDate, DATE_FORMAT_APP).isValid()) {
      let result = moment(maturityDate, DATE_FORMAT_APP).add(30, 'days').format(DATE_FORMAT_APP);
      return result;
    }
  }
  return 'N/A';
};

export const buildFullName = (ind) => {
  let prefix = 'Mr. ';
  if (ind) {
    if (ind.gender === 'F') {
      prefix = 'Ms. ';
    }
    return (
      <span style={{ textTransform: 'capitalize' }}>
        {prefix} {ind.lastName} {ind.firstName}
      </span>
    );
  }
  return 'N/A';
};

export const getIndividualFullNameInList = (borrowers, roleCode) => {
  if (borrowers && borrowers.length > 0) {
    let item = borrowers.find((i) => i.borrowerRelationship == roleCode);
    if (item) {
      return (
        <span style={{ textTransform: 'capitalize' }}>
          {item.lastName} {item.firstName}
        </span>
      );
    }
  }
  return 'N/A';
};

export const getDSCRInSummaryGroup = (groups, apps) => {
  let dscr = null;
  let yearAppCreated = moment(apps.createdAt).year();

  if (groups && groups.length > 0) {
    if (groups[0].years && groups[0].years.length > 0) {
      let years = groups[0].years[0];
      if (years.data) {
        let dscrItem = years.data.find((item) => item.name == 'debtServiceCoverageRatio');
        if (dscrItem) {
          dscr = {
            value: dscrItem.value,
            year: yearAppCreated,
          };
        }
      }
    }

    if (!dscr || dscr.value == 0) {
      if (groups[0].years && groups[0].years.length > 1) {
        let years = groups[0].years[1];
        if (years.data) {
          let dscrItem = years.data.find((item) => item.name == 'debtServiceCoverageRatio');
          if (dscrItem) {
            dscr = {
              value: dscrItem.value,
              year: yearAppCreated - 1,
            };
          }
        }
      }
    }
  }
  return dscr;
};

export function checkIndividualInCurrentApp(selectedBorrowers, selectedGuarantors, selectedShareholders, indId) {
  if (selectedBorrowers) {
    let idx = selectedBorrowers.findIndex((item) => item._id == indId);
    if (idx >= 0) {
      return true;
    }
  }
  if (selectedShareholders) {
    let idx = selectedShareholders.findIndex((item) => item._id == indId);
    if (idx >= 0) {
      return true;
    }
  }
  if (selectedGuarantors) {
    let idx = selectedGuarantors.findIndex((item) => item._id == indId);
    if (idx >= 0) {
      return true;
    }
  }
}

export function getAmountFromFormattedVal(formattedVal) {
  if (formattedVal) {
    let result = formattedVal
      .toString()
      .replaceAll(',', '')
      .replaceAll('$', '')
      .replaceAll('៛', '')
      .replaceAll('KHR', '')
      .replaceAll('USD', '')
      .replaceAll(' ', '');
    return +result;
  } else {
    return 0;
  }
  // return formattedVal;
}

export function getLatestItemInArray(arraysInput) {
  let result = {};
  if (arraysInput && Array.isArray(arraysInput) && arraysInput.length > 0) {
    let tmpArr = [...arraysInput];
    let haveTimeStep = tmpArr.find((i) => i.timestamp);
    if (haveTimeStep) {
      tmpArr.sort((a, b) => {
        let tmp = (a.timestamp || 0) - (b.timestamp || 0);
        return Number(tmp);
      });
    }
    result = tmpArr[tmpArr.length - 1];
  }
  return result;
}

export function getGuaranteeRatio(guarantee) {
  let val = guarantee.level.mainSector;
  let valPriority = guarantee.level.priority;
  let sectorVal = '';
  if (valPriority && valPriority.toUpperCase() == 'PRIORITY') {
    sectorVal = guarantee[`sector${val}`];
    if (!sectorVal) {
      sectorVal = guarantee['sectorOther'];
    }
  } else {
    sectorVal = guarantee[`sector${val}NonPriority`];
    if (!sectorVal) {
      sectorVal = guarantee['sectorOtherNonPriority'];
    }
  }
  return sectorVal;
}

export function mergeAmendLGItems(amendItems, items) {
  for (const [index, obj] of amendItems?.entries()) {
    if (obj) {
      let item = { ...obj };
      if (item?._state) {
        if (item?._state === 'EDITED') {
          delete item._state;
          // item._state = undefined;

          items[index] = { ...items[index], ...item };
        } else if (item?._state === 'REMOVED') {
          items[index] = null;
        } else if (item?._state === 'ADDED') {
          delete item._state;
          // item._state = undefined;
          items.push(item);
        }
      }
    }
  }
  return [...items.filter((item) => !!item)];
}

export function mergeAmendLGItemsExt(amendItems, items) {
  let newItems = amendItems.filter((item) => item?._state === 'ADDED');
  let existItems = amendItems.filter((item) => item?._state !== 'ADDED');
  for (const [index, obj] of existItems?.entries()) {
    if (obj) {
      let item = { ...existItems[index] };
      if (item?._state) {
        if (item?._state === 'EDITED') {
          delete item._state;
          items[index] = { ...items[index], ...item };
        } else if (item?._state === 'REMOVED') {
          items[index] = null;
        }
      }
    }
  }
  // }
  items = items.filter((item) => !!item);

  if (newItems.length > 0) {
    items = items.concat(newItems);
  }

  return items;
}
