import PermissionRoute from '@component/Route/PermissionRoute';
import React, { lazy } from 'react';
import { Route, Switch } from 'react-router';
import KeycloakAuth from 'services/auth/Keycloak';

const GuaranteeManagementExt = lazy(() => import('./management'));
const CGCCApplicationPage = lazy(() => import('./Application/CGCC'));
const PFIApplicationPage = lazy(() => import('./Application/PFI'));
const ApplicationDetail = lazy(() => import('./ApplicationDetail'));

const GuaranteeManagementMain = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Switch>
      {KeycloakAuth.getUserType() === 'cgcc' && (
        <Route path={`${requestedUrl}/management/`} component={GuaranteeManagementExt} />
      )}

      <Route path={`${requestedUrl}/application/detail/:appId`} component={ApplicationDetail} />
      <Route path={`${requestedUrl}/application-pg/detail/:appId`} component={ApplicationDetail} />

      {KeycloakAuth.getUserType() === 'cgcc' && (
        <PermissionRoute
          some={['app:view', 'app:viewAll']}
          path={`${requestedUrl}/application`}
          component={CGCCApplicationPage}
        />
      )}

      {KeycloakAuth.getUserType() === 'pfi' && (
        <PermissionRoute
          some={['app:view', 'app:viewAll']}
          path={`${requestedUrl}/application`}
          component={PFIApplicationPage}
        />
      )}
    </Switch>
  );
};

export default GuaranteeManagementMain;
