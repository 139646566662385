import { fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, updateLoadUser } from '../../../redux/actions/Auth';
import axios from 'axios';
import Keycloak from 'keycloak-js';
import { setJustLogin } from 'redux/reducers/Users';
import { initSocket } from 'services/socket/noti';
const _kc = new Keycloak('/keycloak.json');

const getTokenSet = () => {
  return JSON.parse(localStorage.getItem('tokenSet'));
};

const removeTokenSet = () => {
  localStorage.removeItem('tokenSet');
};

const setTokenSet = (login = false) => {
  if (!login) {
    if (!getTokenSet()) {
      return;
    }
  }
  localStorage.setItem('tokenSet', JSON.stringify({ access_token: _kc.token, refresh_token: _kc.refreshToken }));
};

const updateAxiosToken = () => {
  if (!!_kc.token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${_kc.token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

const updateSocketIoToken = () => {
  if (!!_kc.token) {
    initSocket(_kc.tokenParsed.sub);
  }
};

const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const removeUser = () => {
  localStorage.removeItem('user');
  removeTokenSet();
};

const setUser = () => {
  localStorage.setItem('user', JSON.stringify(_kc.tokenParsed));
  setTokenSet();
  updateAxiosToken();
  updateSocketIoToken();
};

const initWithToken = async (tokenSet, callback) => {
  const authenticated = await _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    token: tokenSet.access_token,
    refreshToken: tokenSet.refresh_token,
    checkLoginIframe: false,
  });

  if (authenticated) {
    setUser();
  } else {
    console.log('user is not authenticated..!');
    removeUser();
    _kc.logout();
  }
  if (callback && typeof callback === 'function') {
    callback(authenticated);
  }
  return authenticated;
};

const KeycloakAuth = {
  init: async (callback) => {
    try {
      _kc.onAuthSuccess = () => {
        setUser();
      };

      _kc.onAuthRefreshSuccess = () => {
        setUser();
      };

      _kc.onAuthError = () => {
        removeUser();
        _kc.logout();
      };

      _kc.onAuthRefreshError = () => {
        removeUser();
        _kc.logout();
      };

      _kc.onTokenExpired = () => {
        _kc.updateToken(-1);
      };

      const tokenSet = getTokenSet();

      if (tokenSet) {
        await initWithToken(tokenSet);
      } else {
        const authenticated = await _kc.init({
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        });
        if (authenticated) {
          setUser();
        } else {
          console.log('user is not authenticated..!');
          removeUser();
        }
      }
    } catch (error) {
      console.error(error);
    }
    if (callback && typeof callback === 'function') {
      callback();
    }
  },
  initCheck: async () => {
    const tokenSet = getTokenSet();
    if (tokenSet) {
      await initWithToken(tokenSet);
    }
  },
  onLogin: () => {
    return (dispatch) => {
      _kc
        // .login({ idpHint: 'Azure AD Cyder VIP' })
        // .login({ idpHint: 'cydervip' })
        .login()
        .then(() => {
          dispatch(setJustLogin());
          dispatch(setAuthUser(_kc.tokenParsed));
        })
        .catch(console.error);
    };
  },
  onCustomLogin: (data) => {
    return (dispatch) => {
      initWithToken(data, (authenticated) => {
        if (authenticated) {
          setTokenSet(true);
          dispatch(setJustLogin());
          dispatch(setAuthUser(_kc.tokenParsed));
        }
      });
    };
  },
  // onPFILogin: ({ email, password }) => {
  //   return (dispatch) => {
  //     try {
  //       dispatch(fetchStart());
  //       axios
  //         .post(`${process.env.REACT_APP_PFI_SERVICE}/pfi/login`, { email, password })
  //         .then(({ data }) => {
  //           // const { access_token, refresh_token } = data;
  //           initWithToken(data, (authenticated) => {
  //             if (authenticated) {
  //               setTokenSet(true);
  //               dispatch(setAuthUser(_kc.tokenParsed));
  //             }
  //           });
  //           dispatch(fetchSuccess());
  //         })
  //         .catch((error) => {
  //           dispatch(fetchError(error?.response?.data?.message));
  //         });
  //     } catch (error) {
  //       dispatch(fetchError(error.message));
  //     }
  //   };
  // },
  // onCGCCLogin: ({ email, password }) => {
  //   return (dispatch) => {
  //     try {
  //       dispatch(fetchStart());
  //       axios
  //         .post(`${process.env.REACT_APP_CGCC_SERVICE}/cgcc/login`, { email, password })
  //         .then(({ data }) => {
  //           // const { access_token, refresh_token } = data;
  //           initWithToken(data, (authenticated) => {
  //             if (authenticated) {
  //               setTokenSet(true);
  //               dispatch(setAuthUser(_kc.tokenParsed));
  //             }
  //           });
  //           dispatch(fetchSuccess());
  //         })
  //         .catch((error) => {
  //           dispatch(fetchError(error?.response?.data?.message));
  //         });
  //     } catch (error) {
  //       dispatch(fetchError(error.message));
  //     }
  //   };
  // },
  onLogout: () => {
    return (dispatch) => {
      removeUser();
      _kc
        .logout()
        .then(() => {
          // dispatch(setAuthUser(null));
        })
        .catch(console.error);
      // if (getTokenSet()) {
      //   removeUser();
      //   dispatch(setAuthUser(null));
      // }
    };
  },

  getToken: () => _kc.token,

  isLoggedIn: () => !!_kc.token,

  getUserId: () => _kc.tokenParsed?.sub,
  getUserEmail: () => _kc.tokenParsed?.email,
  getUsername: () => _kc.tokenParsed?.name,
  getUserType: () => _kc.tokenParsed?.type,
  getUserPFIID: () => _kc.tokenParsed?.pfiId,

  isCGCC: () => _kc.tokenParsed?.type === 'cgcc',

  isPFI: () => _kc.tokenParsed?.type === 'pfi',

  getPermissions: () => _kc.tokenParsed?.scope.split(' '),

  hasPermission: (permission) => _kc.tokenParsed?.scope.split(' ').includes(permission),

  hasSomePermission: (permissions = []) => {
    const scopes = _kc.tokenParsed?.scope.split(' ') ?? [];
    return permissions.some((permission) => scopes.includes(permission));
  },

  hasEveryPermission: (permissions = []) => {
    const scopes = _kc.tokenParsed?.scope.split(' ') ?? [];
    return permissions.every((permission) => scopes.includes(permission));
  },

  hasSomeRole: (roles) => roles.some((role) => _kc.hasRealmRole(role)),

  hasEveryRoles: (roles) => roles.every((role) => _kc.hasRealmRole(role)),

  getAuthUser: (loaded = false) => {
    return (dispatch) => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(setAuthUser(getUser()));
      }, 300);
    };
  },
};

export default KeycloakAuth;
