import { BILLING_DATETIME_FORMAT } from '@component/constants/Billing';
import CmtAvatar from '@coremat/CmtAvatar';
import CmtMediaObject from '@coremat/CmtMediaObject';
import { Box, Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
  navMenuLink: { color: theme.palette.text.primary },
}));

const ClaimPayment = ({ item }) => {
  const classes = useStyles();
  const history = useHistory();

  const getLink = (payload) => {
    const index = '6';
    if (payload?.type) {
      if (payload.type === 'APPROVED') {
        history.push(`/claim/payment/paid?search=${payload?.gmsReference}`);
      } else if (payload.type !== 'REJECTED') {
        history.push(`/todo/assigned?index=${index}&search=${payload.todoId}`);
      }
    } else {
      history.push(`/todo/assigned?index=${index}&search=${payload?.todoId}`);
    }
  };

  return (
    <CmtMediaObject
      onClick={() => {
        getLink(item?.payload);
      }}
      avatarPos="center"
      avatar={<CmtAvatar size={40} src="/images/avator/avator3.jpg" />}
      title={
        <Typography component="div" variant="h5" className={classes.titleRoot}>
          <Box component="span" color="blue">
            {item?.payload?.content}
          </Box>
        </Typography>
      }
      subTitle={
        <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
          <Box ml={2}>{moment(item?.createdAt).format(BILLING_DATETIME_FORMAT)}</Box>
        </Box>
      }
    />
  );
};

export default ClaimPayment;
