import { SCHEME_CREATE, SCHEME_DEL, SCHEME_GET, SCHEME_UPDATE } from '../../@jumbo/constants/GMSActionType';
import { DELETE_PFI } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  schemes: [],
  scheme: {},
};

export default (state = INIT_STATE, action) => {
  let returnData = action.payload;
  let schemes = state.schemes;
  switch (action.type) {
    case SCHEME_GET: {
      let result = returnData;
      return {
        ...state,
        schemes: result,
      };
    }
    case SCHEME_CREATE: {
      schemes.push(returnData);
      return {
        ...state,
        schemes: [...schemes],
      };
    }
    case SCHEME_UPDATE: {
      let findIndex = schemes.findIndex((item) => item._id == returnData._id);
      schemes[findIndex] = returnData;
      return {
        ...state,
        schemes: [...schemes],
      };
    }

    case SCHEME_DEL: {
      return {
        ...state,
        schemes: state.schemes.filter((schemes) => schemes._id !== action.payload),
      };
    }
    default:
      return state;
  }
};
