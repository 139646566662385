import { LG_RESTRUCTURE_CREATE_APP } from '@jumbo/constants/LGActionType';

const INIT_STATE = {
  isRestructureCreateApp: false,
};

const LG = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LG_RESTRUCTURE_CREATE_APP: {
      const isCreateApp = action.payload;
      return {
        ...state,
        isRestructureCreateApp: isCreateApp,
      };
    }
    default:
      return state;
  }
};
export default LG;
