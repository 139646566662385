import React, { useState } from 'react';
import { Box, IconButton, Popover, Tooltip, useTheme } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppsIcon from '@material-ui/icons/Apps';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import CmtGridView from '../../../../../@coremat/CmtGridView';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import CmtImage from '@coremat/CmtImage';
import KeycloakAuth from 'services/auth/Keycloak';
import CreateShortcutPopup from './CreateShortcutPopup';
import { useDeleteShortcutMutation, useGetShortcutsUsersQuery } from 'redux/api/User';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { useDispatch } from 'react-redux';
import {
  AccountBalance,
  Bookmark,
  Apartment,
  Explore,
  GroupRounded,
  SentimentVerySatisfiedRounded,
  WbSunnyRounded,
  TheatersRounded,
  SupervisorAccountRounded,
} from '@material-ui/icons/';
import { COLOR_BACKGROUND } from 'routes/CreateApp/data';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  shortcutBox: {
    position: 'relative',
    '&:hover $deleteIcon': {
      display: 'block',
    },
  },
  deleteIcon: {
    position: 'absolute',
    top: 0,
    right: 8,
    display: 'none',
    padding: 0,
  },
}));

const actions = [
  {
    label: 'Create Shortcut',
    code: 'SHORTCUT',
  },
];

export const listIcons = [
  { id: 1, icon: <AccountBalance /> },
  { id: 2, icon: <Apartment /> },
  { id: 3, icon: <Bookmark /> },
  { id: 4, icon: <Explore /> },
  { id: 5, icon: <GroupRounded /> },
  { id: 6, icon: <SentimentVerySatisfiedRounded /> },
  { id: 7, icon: <WbSunnyRounded /> },
  { id: 8, icon: <TheatersRounded /> },
  { id: 9, icon: <SupervisorAccountRounded /> },
];

const appItem = (item, index, onClick, classes, onDelete) => {
  return (
    <Box className={classes.shortcutBox}>
      <Box
        key={index}
        className="pointer"
        display="flex"
        flexDirection="column"
        alignItems="center"
        onClick={() => onClick(item.path)}>
        <CmtAvatar style={{ backgroundColor: item.bgColor }} size={46} src={item?.src}>
          {!item?.src && item.icon}
        </CmtAvatar>
        <Box mt={2} fontSize={14} fontWeight="bold" color="#666666" style={{ whiteSpace: 'nowrap' }}>
          {item.name}
        </Box>
      </Box>
      {item?.userId && (
        <IconButton className={classes.deleteIcon} aria-label="delete" onClick={() => onDelete(item)}>
          <HighlightOffIcon />
        </IconButton>
      )}
    </Box>
  );
};

const AppsMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpenCreateShortcut, setOpenCreateShortcut] = useState(false);
  const [idDelete, setIdDelete] = useState({ name: '', id: '' });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const theme = useTheme();
  const { data: shortcutList } = useGetShortcutsUsersQuery({ skip: open });
  const [deleteShortcut] = useDeleteShortcutMutation();
  const getApplications = () => {
    const apps = [];

    if (KeycloakAuth.hasSomePermission(['app:create'])) {
      apps.push({
        name: 'GMS Apps',
        icon: <CmtImage src="/images/cgcc-symbol.png" />,
        bgColor: '#CDEAFD',
        path: '/app/create',
      });
    }

    if (KeycloakAuth.hasEveryRoles(['cgcc-admin'])) {
      apps.push({
        name: 'Manage PFI',
        icon: <CmtImage src="/images/cgcc-symbol.png" />,
        bgColor: '#CDEAFD',
        // path: 'https://cgcc.cyder.vip/admin/pfi',
        path: '/admin/pfi-manage',
      });
      apps.push({
        name: 'Manage User',
        icon: <CmtImage src="/images/cgcc-symbol.png" />,
        bgColor: '#CDEAFD',
        path: '/admin/user-role-manage/user',
      });
    }

    if (KeycloakAuth.hasSomePermission(['user:view', 'role:view', 'group:view'])) {
      apps.push({
        name: 'Manage User',
        icon: <CmtImage src="/images/cgcc-symbol.png" />,
        bgColor: '#CDEAFD',
        path: '/admin/user-role-manage',
      });
    }
    if (shortcutList && Array.isArray(shortcutList) && shortcutList.length > 0) {
      shortcutList.forEach((sc) => {
        apps.push({
          ...sc,
          name: sc.name,
          path: sc.path,
          icon: listIcons?.[sc.photo - 1]?.icon,
          bgColor: COLOR_BACKGROUND,
        });
      });
    }
    return apps;
  };
  const applications = getApplications();
  const onOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClickApp = (path) => {
    // history.push(path);
    window.location.href = path;
    onClosePopOver();
  };

  const handleAction = (type) => {
    if (type.code === 'SHORTCUT') {
      setOpenCreateShortcut(true);
    }
  };

  const onDelete = (item) => {
    setIdDelete(item._id);
    setOpenConfirmDialog(true);
  };
  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    try {
      dispatch(fetchStart());
      await deleteShortcut(idDelete).unwrap();
      dispatch(fetchSuccess('User Deleted Successfully'));
    } catch (error) {
      dispatch(fetchError(error.message));
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };
  return (
    <div>
      <Tooltip title="Applications">
        <IconButton onClick={onOpenPopOver} className={clsx(classes.iconRoot, 'Cmt-appIcon')}>
          <AppsIcon />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Applications"
            actionsPos="top-corner"
            actions={actions}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
            actionHandler={handleAction}
          />
          <CmtCardContent style={{ minWidth: 210 }}>
            {applications.length > 0 ? (
              <CmtGridView
                itemPadding={12}
                column={2}
                data={applications}
                renderRow={(item, index) => appItem(item, index, onClickApp, classes, onDelete)}
              />
            ) : (
              <Typography variant="body2">No applications found</Typography>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover>
      {isOpenCreateShortcut && (
        <CreateShortcutPopup open={isOpenCreateShortcut} onClose={() => setOpenCreateShortcut(false)} />
      )}
      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          title={`Confirm delete`}
          content={'Are you sure, you want to  delete this shortcut?'}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default AppsMenu;
