import axios from 'axios';

export const getPFIs = (filterOptions, searchTerm) => {
  return axios.get(`${process.env.REACT_APP_PFI_SERVICE}/pfi`, { params: { filterOptions, searchTerm } });
};

export const getPFIById = (pfiId) => {
  return axios.get(`${process.env.REACT_APP_PFI_SERVICE}/pfi/${pfiId}`);
};

export const getPFIme = () => {
  return axios.get(`${process.env.REACT_APP_PFI_SERVICE}/pfi/me`);
};

export const addNewPFI = (pfi) => {
  return axios.post(`${process.env.REACT_APP_PFI_SERVICE}/pfi`, pfi);
};

export const updatePFI = (pfi) => {
  return axios.patch(`${process.env.REACT_APP_PFI_SERVICE}/pfi`, pfi);
};

export const deletePFI = (pfiId) => {
  return axios.delete(`${process.env.REACT_APP_PFI_SERVICE}/pfi/${pfiId}`);
};

export const getGuarantorIndividual = (indId) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/individual/guarantor-performance?indId=${indId}`);
};

export const getGuarantorBusiness = (buId) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/business/guarantor-performance-pfi?buId=${buId}`);
};
