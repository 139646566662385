import { Grid } from '@material-ui/core';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import DashBar from '@component/Dashboard/DashBar';
import { useGetMatabaseDashboardMutation } from 'redux/api/Report';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Height } from '@material-ui/icons';
const SBDMetabaseDashboard = () => {
  const [iframeUrl, setIframeUrl] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_GSM_SERVICE_UI}/report/metabase/CGCCDashboard/?id=6`)
      .then((response) => {
        setIframeUrl(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // const { data: iframeUrl } = useGetMatabaseDashboardMutation({});
  console.log(iframeUrl);
  return <iframe style={{ width: '100%' }} src={iframeUrl} height="1950" frameBorder={0} allowtransparency="true" />;
};
export default SBDMetabaseDashboard;
