import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Error404 from './Pages/404';
import PageLoader from '../@jumbo/components/PageComponents/PageLoader';
import GuaranteeManagementMain from './Pages/Guarantee';
import PGPage from './Pages/MetabaseDashboard';

const SignInCGCC = lazy(() => import('./Pages/CGCC/signin'));
const SignInPFI = lazy(() => import('./Pages/PFI/signin'));
const SamplePage = lazy(() => import('./Pages/SamplePage'));
const Dashboard = lazy(() => import('./Pages/Dashboards'));
const Profile = lazy(() => import('./Pages/Profile'));
const PFIMgmt = lazy(() => import('./Pages/PFIMgmt'));
const PFIManagement = lazy(() => import('./Pages/PFIManagement'));
const UserMgmt = lazy(() => import('./Pages/UserMgmt'));
const App = lazy(() => import('./Pages/App'));
const AppPG = lazy(() => import('./Pages/AppPG'));
const GuaranteeDraftPage = lazy(() => import('./Pages/GuaranteeDraft'));
const GuaranteeListPage = lazy(() => import('./Pages/GuaranteeList'));
const Todo = lazy(() => import('./Pages/Todo'));
const MyTask = lazy(() => import('./Pages/MyTask'));
const Partner = lazy(() => import('./Pages/Partner'));
const LGManagement = lazy(() => import('./Pages/LGManagement'));
const MonthlyUpload = lazy(() => import('./Pages/MonthlyUploading'));
const Finance = lazy(() => import('./Pages/Finance'));
const Recovery = lazy(() => import('./Pages/Recovery'));
const WriteOff = lazy(() => import('./Pages/WriteOff'));
const Claim = lazy(() => import('./Pages/Claim'));
const LoanMonitoring = lazy(() => import('./Pages/LoanMonitoring'));
const GeneralSetting = lazy(() => import('./Pages/GeneralSetting'));
const UserRoleMgmt = lazy(() => import('./Pages/UserRoleMgmt'));
const Report = lazy(() => import('./Pages/Report'));
const ApprovalGroupManagement = lazy(() => import('./Pages/ApprovalGroupManagement'));
const Request = lazy(() => import('./Pages/Request'));
const RequestManagement = lazy(() => import('./Pages/RequestManagement'));
const ELG = lazy(() => import('./Pages/eLG'));
const Portfolio = lazy(() => import('./Pages/Portfolio'));

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              search: `redirect=${props.location.pathname}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const SigninComponent = [true, 'true'].includes(process.env.REACT_APP_IS_CGCC) ? SignInCGCC : SignInPFI;

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (
    authUser &&
    (location.pathname === '/signin' || location.pathname === '/cgcc/signin' || location.pathname === '/pfi/signin')
  ) {
    return <Redirect to={query.get('redirect') ?? '/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <RestrictedRoute path="/sample-page" component={SamplePage} />
          <Route path="/signin" component={SigninComponent} />
          {/* <Route path="/cgcc/signin" component={SignInCGCC} />
          <Route path="/pfi/signin" component={SignInPFI} /> */}
          <RestrictedRoute path="/app" component={App} />
          <RestrictedRoute path="/pg" component={AppPG} />
          <RestrictedRoute path="/guarantee" component={GuaranteeManagementMain} />
          <RestrictedRoute path="/guarantee-draft" component={GuaranteeDraftPage} />
          <RestrictedRoute path="/guarantee-list" component={GuaranteeListPage} />
          <RestrictedRoute path="/profile" component={Profile} />
          <RestrictedRoute path="/dashboard" component={Dashboard} />
          <RestrictedRoute path="/todo" component={Todo} />
          <RestrictedRoute path="/task" component={MyTask} />
          <RestrictedRoute path="/partner" component={Partner} />
          <RestrictedRoute path="/lg/management" component={LGManagement} />
          <RestrictedRoute path="/monthly-uploading" component={MonthlyUpload} />
          <RestrictedRoute path="/finance" component={Finance} />
          <RestrictedRoute path="/recovery" component={Recovery} />
          <RestrictedRoute path="/write-off" component={WriteOff} />
          <RestrictedRoute path="/claim" component={Claim} />
          <RestrictedRoute path="/loan-monitoring" component={LoanMonitoring} />
          <RestrictedRoute path="/request/create" component={Request} />
          <RestrictedRoute path="/request/management" component={RequestManagement} />
          {/* <RestrictedRoute path="/admin/pfi" component={PFIMgmt} /> */}
          <RestrictedRoute path="/admin/pfi-manage" component={PFIManagement} />
          {/* <RestrictedRoute path="/admin/user" component={UserMgmt} /> */}
          <RestrictedRoute path="/admin/user-role-manage" component={UserRoleMgmt} />
          <RestrictedRoute path="/admin/setting" component={GeneralSetting} />
          <RestrictedRoute path="/admin/report" component={Report} />
          <RestrictedRoute path="/SBD/Dashboard" component={PGPage} />
          <RestrictedRoute path="/admin/approval-group-management" component={ApprovalGroupManagement} />
          <Route path="/e-lg" component={ELG} />
          <RestrictedRoute path="/portfolio/" component={Portfolio} />
          <RestrictedRoute component={Error404} />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
};

export default Routes;
