import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import KeycloakAuth from 'services/auth/Keycloak';

// eslint-disable-next-line prettier/prettier
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, body, params }) => {
    try {
      const result = await axios({ url: baseUrl + url, method, data: body, params });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      if (err?.response?.status === 401) {
        KeycloakAuth.initCheck();
      }
      return {
        error: {
          status: err.response?.status,
          message: err.response?.data?.message || err.message,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const emptySplitApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseUrl: '' }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  keepUnusedDataFor: 5,
  refetchOnMountOrArgChange: 5,
  endpoints: (builder) => ({}),
});
