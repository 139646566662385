import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_NOTI_SOCKET, {
  transports: ['websocket'],
  autoConnect: false,
});

socket.on('connect_error', () => {
  // revert to classic upgrade
  // socket.io.opts.transports = ['polling', 'websocket'];
});

export const initSocket = (token) => {
  console.log('initSocket', token);

  if (token) {
    socket.io.opts.query = { token };
    socket.disconnect().connect();
  }
};

socket.on('log', (data) => {
  console.log('log', data);
});

// socket.on('connection', async socket => {
//   socket.join('b589e00d-037b-42e6-987c-db69aa87479c');
// });

// socket.join('b589e00d-037b-42e6-987c-db69aa87479c', function () {
//   console.log('Socket now in rooms', socket.rooms);
// });
