import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './custom.snow.css';

const RTE = ({ ...props }) => {
  // // Add fonts to whitelist and register them
  // const Font = ReactQuill.Quill.import('formats/font'); // <<<< ReactQuill exports it
  // Font.whitelist = ['roboto', 'sans-serif', 'serif', 'monospace']; // allow ONLY these fonts and the default
  // ReactQuill.Quill.register(Font, true);

  const modules = props?.readOnly
    ? { toolbar: false }
    : {
        toolbar: [
          [{ font: [] }, { header: [1, 2, 3, 4, 5, 6] }, { size: [] }, { align: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'super' }, { script: 'sub' }],
          ['blockquote', 'code', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image', 'video'],
          ['clean'],
        ],
      };

  const format = [
    'header',
    'font',
    'size',
    'align',

    'bold',
    'italic',
    'underline',
    'strike',

    'script',

    'color',
    'background',

    'blockquote',
    'code',
    'code-block',

    'list',
    'indent',

    'link',
    'image',
    'video',

    'clean',
  ];

  return <ReactQuill theme="snow" modules={modules} formats={format} {...props} />;
};

export default RTE;
