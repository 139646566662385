import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  flatButton: {
    padding: '15px 25px 15px 25px',
    height: 36,
    minWidth: 'auto',
    borderRadius: 6,
    fontSize: 12,
    boxShadow: 'none',
    textTransform: 'none',
    backgroundColor: '#F7F8F9',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  primaryTab: {
    color: theme.palette.primary.main,
    backgroundColor: '#0284001a',
  },
}));

const BillingSubTab = ({ label, value, index, onClick, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.flatButton, value === index && classes.primaryTab)}
      // color={value === index ? 'primary' : 'secondary'}
      size="small"
      onClick={() => {
        if (onClick && typeof onClick === 'function') {
          onClick(index);
        }
      }}
      {...props}>
      {label}
    </Button>
  );
};

export default BillingSubTab;
