import { Box, Hidden, InputBase, alpha, makeStyles } from '@material-ui/core';
import { iconMenus, permission } from '../../menus';
import { Autocomplete } from '@material-ui/lab';
import { isMenuAllowed } from '@coremat/CmtNavigation/Vertical';
import { NavLink } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      // width: 350,
      width: 300,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      // height: 48,
      height: 42,
      borderRadius: 30,
      backgroundColor: alpha(theme.palette.common.dark, 0.38),
      color: alpha(theme.palette.common.white, 0.7),
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
      '&:focus': {
        backgroundColor: alpha(theme.palette.common.dark, 0.58),
        color: alpha(theme.palette.common.white, 0.7),
      },
    },
  },
  navMenuLink: { color: theme.palette.text.primary, width: '100%' },
}));

const options = [
  { group: 'To Do', name: 'Assigned to me', icon: iconMenus.toDo, link: '/todo/assigned' },
  { group: 'To Do', name: 'Request by me', icon: iconMenus.toDo, link: '/todo/requested' },
  {
    group: 'Request Management',
    name: 'New Request',
    icon: iconMenus.request,
    link: '/request/management/new',
    permission: {
      some: permission.requestManagement,
    },
  },
  {
    group: 'Request Management',
    name: 'Approved Request',
    icon: iconMenus.request,
    link: '/request/management/approved',
    permission: {
      some: permission.requestManagement,
    },
  },
  {
    group: 'Request Management',
    name: 'Rejected Request',
    icon: iconMenus.request,
    link: '/request/management/rejected',
    permission: {
      some: permission.requestManagement,
    },
  },
  {
    group: 'Monthly Uploading Report',
    name: 'Monthly Report',
    icon: iconMenus.monthly,
    link: '/monthly-uploading/monthly-report',
    userType: 'cgcc',
    permission: {
      some: permission.cgccMonthlyUpload,
    },
  },
  {
    group: 'Monthly Uploading Report',
    name: 'Completed Report',
    icon: iconMenus.monthly,
    link: '/monthly-uploading/complete-report',
    userType: 'cgcc',
    permission: {
      some: permission.cgccMonthlyUpload,
    },
  },
  {
    group: 'Monthly Uploading Report',
    name: 'PFI Monthly Performance',
    icon: iconMenus.monthly,
    link: '/monthly-uploading/monthly-performance',
    userType: 'cgcc',
    permission: {
      some: permission.cgccMonthlyUpload,
    },
  },
  {
    group: 'Monthly Uploading Report',
    name: 'Loan Classification Change',
    icon: iconMenus.monthly,
    link: '/monthly-uploading/loan-classification',
    userType: 'cgcc',
    permission: {
      some: permission.cgccMonthlyUpload,
    },
  },
  {
    group: 'Guarantee Application',
    name: 'New Application',
    icon: iconMenus.guaranteeApp,
    link: '/guarantee/application/new',
    permission: {
      some: permission.guaranteeApplication,
    },
  },
  {
    group: 'Guarantee Application',
    name: 'Approved Application',
    icon: iconMenus.guaranteeApp,
    link: '/guarantee/application/approved',
    permission: {
      some: permission.guaranteeApplication,
    },
  },
  {
    group: 'Guarantee Application',
    name: 'Rejected Application',
    icon: iconMenus.guaranteeApp,
    link: '/guarantee/application/rejected',
    permission: {
      some: permission.guaranteeApplication,
    },
  },
  {
    group: 'LG Management',
    name: 'Issued LG',
    icon: iconMenus.lgMgt,
    link: '/lg/management/issued',
    permission: {
      some: permission.lgManagement,
    },
  },
  {
    group: 'LG Management',
    name: 'Canceled LG',
    icon: iconMenus.lgMgt,
    link: '/lg/management/canceled',
    permission: {
      some: permission.lgManagement,
    },
  },
  {
    group: 'LG Management',
    name: 'Early Settlement LG',
    icon: iconMenus.lgMgt,
    link: '/lg/management/early-settlement',
    permission: {
      some: permission.lgManagement,
    },
  },
  {
    group: 'LG Management',
    name: 'Amended LG',
    icon: iconMenus.lgMgt,
    link: '/lg/management/amended',
    permission: {
      some: permission.lgManagement,
    },
  },
  {
    group: 'LG Management',
    name: 'Restructured LG',
    icon: iconMenus.lgMgt,
    link: '/lg/management/restructured',
    permission: {
      some: permission.lgManagement,
    },
  },
  {
    group: 'LG Management',
    name: 'Matured LG',
    icon: iconMenus.lgMgt,
    link: '/lg/management/matured',
    permission: {
      some: permission.lgManagement,
    },
  },
  {
    group: 'Recovery Management',
    name: 'Request',
    icon: iconMenus.recoveryManagement,
    link: '/recovery/management/request',
    permission: {
      some: permission.recoveryManagement,
    },
  },
  {
    group: 'Recovery Management',
    name: 'Write Off',
    icon: iconMenus.recoveryManagement,
    link: '/recovery/management/write-off',
    permission: {
      some: permission.recoveryManagement,
    },
  },
  {
    group: 'Recovery Management',
    name: 'Approved Request',
    icon: iconMenus.recoveryManagement,
    link: '/recovery/management/approved',
    permission: {
      some: permission.recoveryManagement,
    },
  },
  {
    group: 'Recovery Management',
    name: 'Rejected Request',
    icon: iconMenus.recoveryManagement,
    link: '/recovery/management/rejected',
    permission: {
      some: permission.recoveryManagement,
    },
  },
  {
    group: 'Write Off Management',
    name: 'Write Off',
    icon: iconMenus.writeOff,
    link: '/write-off/management/new',
    permission: {
      some: permission.writeOffManagement,
    },
  },
  {
    group: 'Write Off Management',
    name: 'Approved Request',
    icon: iconMenus.writeOff,
    link: '/write-off/management/approved',
    permission: {
      some: permission.writeOffManagement,
    },
  },
  {
    group: 'Write Off Management',
    name: 'Rejected Request',
    icon: iconMenus.writeOff,
    link: '/write-off/management/rejected',
    permission: {
      some: permission.writeOffManagement,
    },
  },
  {
    group: 'Claim Management',
    name: 'Dashboard',
    icon: iconMenus.claimManagement,
    link: '/claim/management/dashboard',
    permission: {
      some: permission.claimManagement,
    },
  },
  {
    group: 'Claim Management',
    name: 'New Request',
    icon: iconMenus.claimManagement,
    link: '/claim/management/new',
    permission: {
      some: permission.claimManagement,
    },
  },
  {
    group: 'Claim Management',
    name: 'Approved Request',
    icon: iconMenus.claimManagement,
    link: '/claim/management/approved',
    permission: {
      some: permission.claimManagement,
    },
  },
  {
    group: 'Claim Management',
    name: 'Rejected Request',
    icon: iconMenus.claimManagement,
    link: '/claim/management/rejected',
    permission: {
      some: permission.claimManagement,
    },
  },
  {
    group: 'Loan Monitoring',
    name: 'Dashboards',
    icon: iconMenus.loanMonitor,
    link: '/loan-monitoring/dashbaord',
    permission: {
      some: permission.loanMonitoring,
    },
  },
  {
    group: 'Loan Monitoring',
    name: 'Sepcial Mention (S)',
    icon: iconMenus.loanMonitor,
    link: '/loan-monitoring/special-mention',
    permission: {
      some: permission.loanMonitoring,
    },
  },
  {
    group: 'Loan Monitoring',
    name: 'NPL Management',
    icon: iconMenus.loanMonitor,
    link: '/loan-monitoring/npl-management',
    permission: {
      some: permission.loanMonitoring,
    },
  },
  {
    group: 'Billing Management',
    name: 'Pending Bills',
    icon: iconMenus.billing,
    link: '/finance/billing-management/pending-bill',
    permission: {
      some: permission.billingManagement,
    },
  },
  {
    group: 'Billing Management',
    name: 'Late Bills',
    icon: iconMenus.billing,
    link: '/finance/billing-management/late-bill',
    permission: {
      some: permission.billingManagement,
    },
  },
  {
    group: 'Billing Management',
    name: 'Sent Bills',
    icon: iconMenus.billing,
    link: '/finance/billing-management/sent-bill',
    permission: {
      some: permission.billingManagement,
    },
  },
  {
    group: 'Billing Management',
    name: 'Available Bills',
    icon: iconMenus.billing,
    link: '/finance/billing-management/available-bill',
    permission: {
      some: permission.billingManagement,
    },
  },
  {
    group: 'Billing Management',
    name: 'Holding Bills',
    icon: iconMenus.billing,
    link: '/finance/billing-management/holding-bill',
    permission: {
      some: permission.billingManagement,
    },
  },
  {
    group: 'Billing Management',
    name: 'All Bills',
    icon: iconMenus.billing,
    link: '/finance/billing-management/all-bill',
    permission: {
      some: permission.billingManagement,
    },
  },
  {
    group: 'Billing Management',
    name: 'Credit Note',
    icon: iconMenus.billing,
    link: '/finance/billing-management/credit-note',
    permission: {
      some: permission.billingManagement,
    },
  },
  {
    group: 'Billing Management',
    name: 'Early Settlement',
    icon: iconMenus.billing,
    link: '/finance/billing-management/early-settlement',
    permission: {
      some: permission.billingManagement,
    },
  },
  {
    group: 'Invoice Management',
    name: 'Invoice & Credit Note',
    icon: iconMenus.invoice,
    link: '/finance/invoice-management/invoice',
    permission: {
      some: permission.invoiceManagement,
    },
  },
  {
    group: 'Invoice Management',
    name: 'Late Payment',
    icon: iconMenus.invoice,
    link: '/finance/invoice-management/late-payment',
    permission: {
      some: permission.invoiceManagement,
    },
  },
  {
    group: 'Invoice Management',
    name: 'All Invoice',
    icon: iconMenus.invoice,
    link: '/finance/invoice-management/all-invoice',
    permission: {
      some: permission.invoiceManagement,
    },
  },
  {
    group: 'Invoice Management',
    name: 'Draft Invoice',
    icon: iconMenus.invoice,
    link: '/finance/invoice-management/draft-invoice',
    permission: {
      some: permission.invoiceManagement,
    },
  },
  {
    group: 'Invoice Management',
    name: 'Skip Invoice',
    icon: iconMenus.invoice,
    link: '/finance/invoice-management/invoice-numbering',
    permission: {
      some: permission.invoiceManagement,
    },
  },
  {
    group: 'Financial Management Settings',
    name: 'Billing Configuration',
    icon: iconMenus.finance,
    link: '/finance/financial-settings/billing-configuration',
    permission: {
      some: permission.financialManagement,
    },
  },
  {
    group: 'Financial Management Settings',
    name: 'Invoice Configuration',
    icon: iconMenus.finance,
    link: '/finance/financial-settings/invoice-configuration',
    permission: {
      some: permission.financialManagement,
    },
  },
  {
    group: 'Financial Management Settings',
    name: 'GL Configuration',
    icon: iconMenus.finance,
    link: '/finance/financial-settings/gl-configuration',
    permission: {
      some: permission.financialManagement,
    },
  },
  {
    group: 'Financial Management Settings',
    name: 'Notification Configuration',
    icon: iconMenus.finance,
    link: '/finance/financial-settings/notification-configuration',
    permission: {
      some: permission.financialManagement,
    },
  },
  {
    group: 'Reports',
    name: 'My Report',
    icon: iconMenus.report,
    link: '/admin/report/my-report',
    permission: {
      some: ['report:view:template', 'report:view:my', 'report:view:recur'],
    },
  },
  {
    group: 'Reports',
    name: 'Recurring Report',
    icon: iconMenus.report,
    link: '/admin/report/recurring-report',
    permission: {
      some: ['report:view:template', 'report:view:my', 'report:view:recur'],
    },
  },
  {
    group: 'Reports',
    name: 'Type',
    icon: iconMenus.report,
    link: '/admin/report/type',
    permission: {
      some: ['report:view:template', 'report:view:my', 'report:view:recur'],
    },
  },
  {
    group: 'Reports',
    name: 'Template',
    icon: iconMenus.report,
    link: '/admin/report/template',
    permission: {
      some: ['report:view:template', 'report:view:my', 'report:view:recur'],
    },
  },
  {
    group: 'User & Role Management',
    name: 'User',
    icon: iconMenus.userRoleMgt,
    link: '/admin/user-role-manage/user',
    permission: {
      some: ['user:view'],
    },
  },
  {
    group: 'User & Role Management',
    name: 'Role',
    icon: iconMenus.userRoleMgt,
    link: '/admin/user-role-manage/role',
    permission: {
      some: ['role:view'],
    },
  },
  {
    group: 'User & Role Management',
    name: 'New Access Role',
    icon: iconMenus.userRoleMgt,
    link: '/admin/user-role-manage/role/new',
    permission: {
      some: ['role:create'],
    },
  },
  {
    group: 'User & Role Management',
    name: 'Group',
    icon: iconMenus.userRoleMgt,
    link: '/admin/user-role-manage/group',
    permission: {
      some: ['group:view'],
    },
  },
  {
    group: 'User & Role Management',
    name: 'New User Group',
    icon: iconMenus.userRoleMgt,
    link: '/admin/user-role-manage/group/new',
    permission: {
      some: ['group:create'],
    },
  },
  {
    group: 'PFI Management',
    name: 'New PFI',
    icon: iconMenus.pfiMgt,
    link: '/admin/pfi-manage/new',
    access: { some: ['cgcc-admin'] },
  },
  {
    group: 'General Setting',
    name: 'Exchange Rate',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/exchangerate',
    userType: 'cgcc',
    permission: {
      some: ['exchangeRate:view', 'exchangeRate:create'],
    },
  },
  {
    group: 'General Setting',
    name: 'Working Time',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/workingtime',
    userType: 'cgcc',
    permission: {
      some: ['workTime:view', 'workTime:edit'],
    },
  },
  {
    group: 'General Setting',
    name: 'Delegation Management',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/delegation',
  },
  {
    group: 'General Setting',
    name: 'CGCC Profile',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/profile',
    userType: 'cgcc',
  },
  {
    group: 'General Setting',
    name: 'Check SBGL',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/checksgbl',
    userType: 'cgcc',
  },
  {
    group: 'General Setting',
    name: 'Password Policy',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/password-policy',
    userType: 'cgcc',
  },
  {
    group: 'General Setting',
    name: 'Restructure Limit',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/restructure-limit',
    userType: 'cgcc',
  },
  {
    group: 'General Setting',
    name: 'Tag',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/tag',
    userType: 'cgcc',
  },
  {
    group: 'General Setting',
    name: 'Claim Payment',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/claim-payment',
    userType: 'cgcc',
  },
  {
    group: 'General Setting',
    name: 'e-LG',
    icon: iconMenus.generalSetting,
    link: '/admin/setting/e-lg',
    userType: 'cgcc',
  },
];

const HeaderSearch = () => {
  const classes = useStyles();

  return (
    <Box>
      <Hidden smDown>
        <Box pr={3} className={classes.searchRoot}>
          <Autocomplete
            freeSolo
            disableClearable
            options={
              options.filter((item) => isMenuAllowed(item))
              // .sort((a, b) => -b.group.localeCompare(a.group))
            }
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.name}
            sx={{ width: 300 }}
            renderOption={(option, state) => {
              const { icon: Icon, link } = option;
              return (
                <NavLink className={classes.navMenuLink} to={link ?? '#'}>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'left',
                      columnGap: 4,
                    }}>
                    {Icon}
                    {option.name}
                  </Box>
                </NavLink>
              );
            }}
            renderInput={(params) => {
              const { InputLabelProps, InputProps, ...rest } = params;
              return <InputBase {...InputLabelProps} {...InputProps} {...rest} placeholder={'Search GMS'} />;
            }}
          />
          <SearchIcon />
        </Box>
      </Hidden>
    </Box>
  );
};

export default HeaderSearch;
