import { createSlice } from '@reduxjs/toolkit';
import { getIndividuals } from '../../services/api/CIF';
const initialState = {
  cifs: [],
  selectedCIF: null,
  currentCif: null,
  riskCorpList: [],
  riskManual: [],
  riskList: [],
  riskManualCorp: [],
};

const cifSlice = createSlice({
  name: 'cifs',
  initialState,
  reducers: {
    setCIFs: (state, action) => {
      state.cifs = action.payload;
    },
    setIndividuals: (state, action) => {
      state.individuals = action.payload;
    },
    setCorporates: (state, action) => {
      state.corporates = action.payload;
    },
    addCIF: (state, action) => {
      state.cifs.push(action.payload);
    },
    editCIF: (state, action) => {
      state.cifs = state.cifs.map((cif) => (cif.id === action.payload.id ? action.payload : cif));
    },
    deleteCIF: (state, action) => {
      state.cifs = state.cifs.filter((cif) => cif.id !== action.payload);
    },
    setCurrentIndividual: (state, action) => {
      state.individual = action.payload;
      state.riskManual = action.payload.riskManual;
    },
    setCurrentCorporate: (state, action) => {
      state.corporate = action.payload;
      state.riskManualCorp = action.payload.riskManual;
    },
    setCifCompanyRelationship: (state, action) => {
      state.company_relationship = action.payload;
    },
    setLoans: (state, action) => {
      state.loans = action.payload;
    },
    setGuarantor: (state, action) => {
      state.guarantors = action.payload;
    },
    setBusinessGuarantor: (state, action) => {
      state.businessGuarantor = action.payload;
    },
    setRiskIndividual: (state, action) => {
      state.riskList = action.payload;
    },
    setRiskCorp: (state, action) => {
      state.riskCorpList = action.payload;
    },
    setRiskManualIndividual: (state, action) => {
      state.riskManual = [action.payload];
    },
    setRiskManualCorp: (state, action) => {
      state.riskManualCorp = [action.payload];
    },
  },
});

export const {
  setCIFs,
  setIndividuals,
  setCorporates,
  addCIF,
  editCIF,
  deleteCIF,
  setCurrentIndividual,
  setCurrentCorporate,
  setCifCompanyRelationship,
  setLoans,
  setGuarantor,
  setBusinessGuarantor,
  setRiskIndividual,
  setRiskCorp,
  setRiskManualIndividual,
  setRiskManualCorp,
} = cifSlice.actions;

export default cifSlice.reducer;
