import { emptySplitApi } from './index';

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/user`,
        params,
      }),
      providesTags: (result = [], error) => [
        { type: 'PFI_USER', id: 'LIST' },
        ...result?.items?.map(({ id }) => ({ type: 'PFI_USER', id })),
      ],
    }),
    getPFIUsers: builder.query({
      query: ({ pfiId, ...params }) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/${pfiId}/user`,
        params,
      }),
      providesTags: (result = [], error, { pfiId }) => [
        { type: 'PFI_USER', id: 'LIST', pfiId },
        ...result?.items?.map(({ id }) => ({ type: 'PFI_USER', id, pfiId })),
      ],
    }),
    getCGCCUsers: builder.query({
      query: (params) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user`,
        params,
      }),
      providesTags: (result = []) => [
        { type: 'CGCC_USER', id: 'LIST' },
        ...result?.items?.map(({ id }) => ({ type: 'CGCC_USER', id })),
      ],
    }),
    getUserbyId: builder.query({
      query: (userId) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/user/${userId}`,
      }),
      providesTags: (result, error, id) => [{ type: 'PFI_USER', id }],
    }),
    getPFIUserMe: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/user/me`,
      }),
      providesTags: (result, error, args) => [{ type: 'PFI_USER', id: 'me' }],
    }),
    getPFIUserbyId: builder.query({
      query: ({ pfiId, userId }) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/${pfiId}/user/${userId}`,
      }),
      providesTags: (result, error, { userId: id }) => [{ type: 'PFI_USER', id }],
    }),
    getCGCCUserbyId: builder.query({
      query: (userId) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user/${userId}`,
      }),
      providesTags: (result, error, id) => [{ type: 'CGCC_USER', id }],
    }),
    getRolesByUserId: builder.query({
      query: (userId) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user/${userId}/role`,
      }),
      providesTags: (result, error, id) => [{ type: 'CGCC_USER', id }],
    }),
    getCGCCUserMe: builder.query({
      query: (userId) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user/me`,
      }),
      providesTags: (result, error, args) => [{ type: 'CGCC_USER', id: 'me' }],
    }),
    addNewUser: builder.mutation({
      query: (user) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/user`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [{ type: 'PFI_USER' }],
    }),
    addNewPFIUser: builder.mutation({
      query: ({ pfiId, ...user }) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/${pfiId}/user`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [{ type: 'PFI_USER' }],
    }),
    addNewCGCCUser: builder.mutation({
      query: (user) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [{ type: 'CGCC_USER' }],
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/user`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: [{ type: 'PFI_USER' }],
    }),
    updateUserMe: builder.mutation({
      query: (user) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/user/me`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: [{ type: 'PFI_USER', id: 'me' }],
    }),
    updatePFIUser: builder.mutation({
      query: ({ pfiId, ...user }) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/${pfiId}/user`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: [{ type: 'PFI_USER' }],
    }),
    updateCGCCUser: builder.mutation({
      query: (user) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: [{ type: 'CGCC_USER' }],
    }),
    updateCGCCUserMe: builder.mutation({
      query: (user) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user/me`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: [{ type: 'CGCC_USER', id: 'me' }],
    }),
    deletePFIUser: builder.mutation({
      query: ({ pfiId, userId }) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/${pfiId}/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'PFI_USER' }],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'PFI_USER' }],
    }),
    deleteCGCCUser: builder.mutation({
      query: (userId) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'CGCC_USER' }],
    }),
    generatePFIMFASecret: builder.mutation({
      query: () => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/user/me/mfa/generate/secret`,
        method: 'POST',
      }),
    }),
    verifyPFICode: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/user/me/mfa/verify`,
        method: 'POST',
        body,
      }),
    }),
    generateCGCCMFASecret: builder.mutation({
      query: () => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user/me/mfa/generate/secret`,
        method: 'POST',
      }),
    }),
    verifyCGCCCode: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/user/me/mfa/verify`,
        method: 'POST',
        body,
      }),
    }),
    loginPFI: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_PFI_SERVICE}/pfi/login`,
        method: 'POST',
        body,
      }),
    }),
    loginCGCC: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/login`,
        method: 'POST',
        body,
      }),
    }),
    getShortcutsUsers: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/setting-user/shortcut`,
      }),
      providesTags: () => [{ type: 'SHORTCUT_LIST', id: 'me' }],
    }),
    createShortcut: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/setting-user/create-shortcut`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'SHORTCUT_LIST' }],
    }),
    deleteShortcut: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_GSM_SERVICE_UI}/setting-user/delete-shortcut/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'SHORTCUT_LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetPFIUsersQuery,
  useGetCGCCUsersQuery,
  useGetUserbyIdQuery,
  useGetPFIUserMeQuery,
  useGetPFIUserbyIdQuery,
  useGetCGCCUserbyIdQuery,
  useGetRolesByUserIdQuery,
  useGetCGCCUserMeQuery,
  useAddNewUserMutation,
  useAddNewPFIUserMutation,
  useAddNewCGCCUserMutation,
  useUpdateUserMutation,
  useUpdateUserMeMutation,
  useUpdatePFIUserMutation,
  useUpdateCGCCUserMutation,
  useUpdateCGCCUserMeMutation,
  useDeleteUserMutation,
  useDeletePFIUserMutation,
  useDeleteCGCCUserMutation,
  useGeneratePFIMFASecretMutation,
  useVerifyPFICodeMutation,
  useGenerateCGCCMFASecretMutation,
  useVerifyCGCCCodeMutation,
  useLoginPFIMutation,
  useLoginCGCCMutation,
  useCreateShortcutMutation,
  useGetShortcutsUsersQuery,
  useDeleteShortcutMutation,
} = extendedApi;
