// export * from './apps/todos';
// export * from './apps/mailApp';
// export * from './apps/chat';
// export * from './apps/contact';
import * as PFI from './PFI';
import * as Application from './Application';
import * as CIF from './CIF';
import * as PG from './PG';

export * from './apps/profile';
// export * from './apps/wall';
export * from './users';

export * from './fileUpload';

export const API = {
  ...PFI,
  ...CIF,
  ...Application,
  ...PG,
};
