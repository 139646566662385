import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import BillingSubTab from '@component/Tabs/BillingSubTab';
import { TabContext, TabPanel } from '@material-ui/lab';
import ErrorBoundary from '@component/ErrorBoundary';
import SBDMetabaseDashboard from './PGGuaranteeProduct';
import SBDIGMetabaseDashboard from './IndividualGuaranteeProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerRoot: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  appCard: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    position: 'relative',
    overflow: 'hidden',
    padding: 16,
  },
  tabPanelRoot: {
    padding: 0,
  },
}));

const SBDDashboard = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState('1');

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  return (
    <Box className={classes.centerRoot} style={{ marginTop: 15 }}>
      <PageContainer>
        <Box className={classes.appCard} display="flex" flexDirection="column">
          <Box display="flex">
            <BillingSubTab label="Portfolio DashBoard" value={selectedTab} index={'1'} onClick={handleTabChange} />
            <BillingSubTab
              label="Individual Guarantee Dashboard"
              value={selectedTab}
              key={'2'}
              index={'2'}
              onClick={handleTabChange}
              style={{ marginLeft: 10 }}
            />
          </Box>
          <TabContext value={selectedTab}>
            <TabPanel value={'1'} classes={{ root: classes.tabPanelRoot }}>
              <ErrorBoundary>
                <SBDMetabaseDashboard />
              </ErrorBoundary>
            </TabPanel>
            {/* <TabPanel value={'2'} classes={{ root: classes.tabPanelRoot }}>
              <ErrorBoundary>
              <SBDMetabaseDashboard />
              </ErrorBoundary>
            </TabPanel> */}
            <TabPanel value={'2'} classes={{ root: classes.tabPanelRoot }}>
              <ErrorBoundary>
                <SBDIGMetabaseDashboard />
              </ErrorBoundary>
            </TabPanel>
          </TabContext>
        </Box>
      </PageContainer>
    </Box>
  );
};

export default SBDDashboard;
