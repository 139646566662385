import { SCHEME_CREATE_PG, SCHEME_DEL_PG, SCHEME_GET_PG, SCHEME_UPDATE_PG } from '../../@jumbo/constants/GMSActionType';

const INIT_STATE = {
  schemesPG: [],
  schemePG: {},
};

export default (state = INIT_STATE, action) => {
  let returnData = action.payload;
  let schemesPG = state.schemesPG;
  switch (action.type) {
    case SCHEME_GET_PG: {
      let result = returnData;
      return {
        ...state,
        schemesPG: result,
      };
    }
    case SCHEME_CREATE_PG: {
      schemesPG.push(returnData);
      return {
        ...state,
        schemesPG: [...schemesPG],
      };
    }
    case SCHEME_UPDATE_PG: {
      let findIndex = schemesPG.findIndex((item) => item._id == returnData._id);
      schemesPG[findIndex] = returnData;
      return {
        ...state,
        schemesPG: [...schemesPG],
      };
    }

    case SCHEME_DEL_PG: {
      return {
        ...state,
        schemesPG: state.schemesPG.filter((schemes) => schemes._id !== action.payload),
      };
    }
    default:
      return state;
  }
};
