import { useEffect, useState } from 'react';
import { getIndividualFullNameInList } from 'routes/CreateApp/data';

export const capitalizeFLetter = (string) => {
  return string[0]?.toUpperCase() + string.slice(1);
};

export const isValidEmail = (value) => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const idGenerator = () => {
  return Math.floor(Math.random() * 100000);
};

export const linkify = (inputText) => {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z0-9\\-]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const geValidUrl = (url, ubSecureUrl = false) => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    if (ubSecureUrl) {
      return 'http://' + url;
    }
    return 'https://' + url;
  }

  return url;
};

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay],
  );

  return debouncedValue;
};

export const getIndentityType = (type) => {
  const dataType = {
    1: 'National ID',
    2: 'Passport',
    3: 'Family Book',
    4: 'Driver License',
    5: 'Government Issued ID',
    6: 'Birth Certificate',
    7: 'Voter Req. Card',
    8: 'Tax Number',
    9: 'Resident Book',
    10: 'Local Authority Certify with photo',
  };
  return dataType[type];
};

export const getNationality = (index) => {
  let value = '';
  if (index == 'CAM') {
    value = 'Cambodian';
  } else if (index == 'NONCAM') {
    value = 'Non-Cambodian';
  }
  return value;
};

export const getAddressType = (type) => {
  let address = '';
  switch (type) {
    case '0':
      address = 'Residential';
      break;
    case '1':
      address = 'Work';
      break;
    case '2':
      address = 'Correspondence';
      break;
    default:
      address = '';
  }

  return address;
};

export const getGender = (type) => {
  let gender = '';
  if (type == 'M') {
    gender = 'Male';
  } else if (type == 'F') {
    gender = 'Female';
  }
  return gender;
};

export const getBusinessConstituency = (index) => {
  let businessConstituecy = '';
  switch (index) {
    case '0':
      businessConstituecy = 'Sole Proprietorship';
      break;
    case '1':
      businessConstituecy = 'Partnership';
      break;
    case '2':
      businessConstituecy = 'Private Limited Company';
      break;
    case '3':
      businessConstituecy = 'Others';
      break;
    default:
      businessConstituecy = '';
  }

  return businessConstituecy;
};
export const getBorrowerRelationship = (currentObject, listSH = [], listGuarantor = []) => {
  let borrowerRelationship = currentObject?.borrowerRelationship;
  switch (borrowerRelationship) {
    case '10':
      borrowerRelationship = 'Main Borrower';
      break;
    case '30':
      borrowerRelationship = 'Representative';
      break;
    case '20':
      borrowerRelationship = 'Co - Borrower';
      break;
    // case '40':
    //   borrowerRelationship = 'Guarantor';
    //   break;
    // default:
    //   borrowerRelationship = 'Shareholder';
    //   break;
    default:
      borrowerRelationship = 'NA';
      break;
  }
  if (listSH && listSH.length > 0) {
    let findSH = listSH.find((i) => i.indNo === currentObject.indNo);
    if (findSH) {
      borrowerRelationship += ', Shareholder';
    }
  }
  if (listGuarantor && listGuarantor.length > 0) {
    let findIndG = listGuarantor.find((i) => i.indNo === currentObject.indNo);
    if (findIndG) {
      borrowerRelationship += ', Guarantor';
    }
  }

  return borrowerRelationship;
};

export const getAnnualTurnoverOfBorrowerBusiness = (index) => {
  let annualTurnover = '';
  if (index == 0) {
    annualTurnover = '< 62,250 USD';
  } else if (index == 1) {
    annualTurnover = '62,250 - 400,000 USD';
  } else if (index == 2) {
    annualTurnover = '400,001 - 2,000,000 USD';
  } else if (index == 3) {
    annualTurnover = '> 2,000,0001 USD';
  }

  return annualTurnover;
};

export const getTotalAsset = (index) => {
  let totalAsset = '';
  if (index == '0') {
    totalAsset = '< 50,000 USD';
  } else if (index == '1') {
    totalAsset = '50,000 - 500,000 USD';
  } else if (index == '2') {
    totalAsset = '500,001 - 1,000,000 USD';
  } else if (index == '3') {
    totalAsset = '> 1,000,0001 USD';
  }

  return totalAsset;
};

export const getBusinessSector = (index) => {
  let businessSector = '';
  if (index == '0') {
    businessSector = 'Agriculture';
  } else if (index == '1') {
    businessSector = 'Industry';
  } else if (index == '2') {
    businessSector = 'Service and Trade';
  }

  return businessSector;
};

export const getCertificationLicense = (index) => {
  let certificationLicense = '';
  switch (index) {
    case '0':
      certificationLicense = 'Certificate / License Issued by Ministry of Commerce';
      break;
    case '1':
      certificationLicense = 'Certificate / License Issued by Ministry of Economy & Finance';
      break;
    case '2':
      certificationLicense = 'Certificate / License Issued by General Department of Taxation';
      break;
    case '3':
      certificationLicense = 'Certificate / License Issued by Other Authority';
      break;
    case '4':
      certificationLicense = 'Not Available';
      break;
    default:
      certificationLicense = '';
  }

  return certificationLicense;
};
export const numberWithCommas = (value) => {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return 0;
};

export const formatAmount = (value, currency = 'USD') => {
  const fraction = ['USD', 'usd', '$'].includes(currency) ? 2 : 0;
  if (!value) {
    value = 0;
  }
  const amount = parseFloat(`${value}`?.replace(/,/g, ''));

  return amount.toLocaleString('en', { minimumFractionDigits: fraction, maximumFractionDigits: fraction });
};

export const getNameAddress = (val, type, address) => {
  let result = '';
  if (address) result = address.find((adr) => adr.code == val && adr.type == type)?.name || '';
  return result;
};

export const getActivity = (activity) => {
  let activityResult = '';
  switch (activity) {
    case 'ADD-WATCHLIST':
      activityResult = 'Add Watchlist';
      break;
    case 'DEL-WATCHLIST':
      activityResult = 'Remove Watchlist';
      break;
    default:
      activityResult = '';
  }

  return activityResult;
};

const getAppBorrowers = (app) => {
  const steps = app.application.steps;
  const borrower = steps.find((item) => item.stepType == 'BORROWER');
  return borrower?.selectedBorrowers ?? [];
};

const getAppBusinesses = (app) => {
  const steps = app.application.steps;

  const businesses = steps.find((item) => item.name == 'BUSINESS');
  return businesses?.selectedBusiness;
};

export const getMainBorrowerName = (app) => {
  if (app.application.typeOfBorrower === 'INDIVIDUAL') {
    const individuals = getAppBorrowers(app);
    const mainBorrower = individuals.find((item) => item.borrowerRelationship == '10');
    return `${mainBorrower?.lastName} ${mainBorrower?.firstName}`;
  } else if (app.application.typeOfBorrower === 'COMPANY') {
    const businesses = getAppBusinesses(app);
    return businesses?.companyName;
  }
};

export const getMainBorrowerFromLG = (lg) => {
  let mainBorrower = '';

  if (lg?.typeMainBorrower === 'INDIVIDUAL') {
    mainBorrower = getIndividualFullNameInList(lg?.borrowers, '10');
  } else {
    mainBorrower = lg?.business?.companyName;
  }
  return mainBorrower;
};

export const convertToMonetaryFormat = (number, digits = 2) => {
  return number?.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits });
};
