import CmtAvatar from '@coremat/CmtAvatar';
import CmtMediaObject from '@coremat/CmtMediaObject';
import { getDateElements } from '@jumbo/utils/dateHelper';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
  navMenuLink: { color: theme.palette.text.primary },
}));

const GA = ({ item }) => {
  const classes = useStyles();

  const getLink = (payload) => {
    if (
      ['NEW', 'ASSIGN_TO_GA', 'ASSIGN_TO_DRAFT', 'RETURN_CREATOR', 'RETURN_INTERNAL', 'APPROVED', 'REJECTED'].includes(
        payload?.type,
      )
    ) {
      return `/guarantee/application/detail/${payload?.appId}`;
    } else if (payload?.type === 'ASSIGN_TO_DRAFT') {
      return `/app/create?draftId=${payload?.draftId}`;
    } else if (payload?.type === 'NEED_MORE_INFO') {
      return '/todo/assigned';
    } else if (payload?.type === 'REPLY_NEED_MORE_INFO') {
      return '/todo/requested';
    }

    return '#';
  };

  return (
    <NavLink className={classes.navMenuLink} to={getLink(item?.payload)}>
      <CmtMediaObject
        avatarPos="center"
        avatar={<CmtAvatar size={40} src="/images/avator/avator3.jpg" />}
        title={
          <Typography component="div" variant="h5" className={classes.titleRoot}>
            <Box component="span" color="blue">
              {item?.payload?.content}
            </Box>
          </Typography>
        }
        subTitle={
          <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
            <Box ml={2}>{getDateElements(item?.createdAt).time}</Box>
          </Box>
        }
      />
    </NavLink>
  );
};

export default GA;
