import axios from 'axios';

export const getDraftApplications = () => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/draft`);
};

export const getDraftApplicationsPG = () => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee-pg/application/draft`);
};

export const getApplicationById = (id) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/${id}`);
};

export const getApplicationByIdPG = (id) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee-pg/application/${id}`);
};

export const getApplicationsMe = ({ stage, status }) => {
  let params = { stage, status };

  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/me`, { params });
};

export const getApplications = (params) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application`, { ...params });
};

export const getApplicationsAssignToMe = ({ stage, status }) => {
  let params = { stage, status };

  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/assign`, { params });
};

export const appAssignTo = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/assign`, body);
};

export const createApplication = (application) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application`, application);
};

export const createApplicationPG = (application) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee-pg/application`, application);
};

export const deleteApplication = (applicationId) => {
  return axios.delete(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/${applicationId}`);
};
export const deleteApplicationPG = (applicationId) => {
  return axios.delete(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee-pg/application/${applicationId}`);
};

export const getAssignableUser = ({ appId }) => {
  let params = { appId };

  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/assignable`, { params });
};

export const getSubmittableUser = ({ appId }) => {
  let params = { appId };

  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/submittable`, { params });
};

export const getPfiAuthorizer = ({ appId }) => {
  let params = { appId };
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/pfi/authorizer`);
};

export const submitApplication = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/submit`, body);
};

export const submitApplicationPG = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee-pg/application/submit`, body);
};

export const revokeApplication = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/revoke`, body);
};

export const reviewApplication = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/review`, body);
};

export const onHoldApplication = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/onhold`, body);
};

export const rejectApplication = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/reject`, body);
};

export const approveApplication = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/approve`, body);
};

export const getAskableUser = ({ appId }) => {
  let params = { appId };

  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/moreinfo/askable`, { params });
};

export const appAskMoreInfo = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/moreinfo/ask`, body);
};

export const appProvideMoreInfo = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/moreinfo/provide`, body);
};

export const appChangePriority = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/priority`, body);
};

export const appRequestForCancel = (body) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/cancel`, body);
};

export const getLoanByCustomerId = (cid) => {
  const url = `${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/loan?cid=${cid}`;
  return axios.get(url, {});
};

export const getAllBusiness = () => {
  const url = `${process.env.REACT_APP_GSM_SERVICE_UI}/corporate`;

  return axios.get(url, {});
};

export const getAllBorrowers = (currentPfi, notId, selectedBorrowers) => {
  let url = `${process.env.REACT_APP_GSM_SERVICE_UI}/borrowers?pfi=${currentPfi.id}`;
  if (notId) {
    url = url + '&notId=' + notId;
  }
  if (selectedBorrowers && selectedBorrowers.length > 0) {
    let ids = [];
    selectedBorrowers.forEach((i) => ids.push(i._id));
    url = url + '&notIds=' + ids;
  }

  return axios.get(url, {});
};

export const getOneBorrower = (id) => {
  let url = `${process.env.REACT_APP_GSM_SERVICE_UI}/borrowers/${id}`;
  return axios.get(url, {});
};

export const getOneBussiness = (id) => {
  const url = `${process.env.REACT_APP_GSM_SERVICE_UI}/corporate/${id}`;
  return axios.get(url, {});
};

export const getCompanyRelationship = (id) => {
  let url = `${process.env.REACT_APP_GSM_SERVICE_UI}/corporate/companyRelationship?cid=${id}`;
  return axios.get(url, {});
};

export const getLoanByBusinessId = (bid) => {
  const url = `${process.env.REACT_APP_GSM_SERVICE_UI}/loan/business?bid=${bid}`;
  return axios.get(url, {});
};

export const getGuarantorByCustomerId = (cid) => {
  const url = `${process.env.REACT_APP_GSM_SERVICE_UI}/loan/guarantor?cid=${cid}`;
  return axios.get(url, {});
};

export const createBorrower = (borrowerDTO) => {
  return axios.post(`${process.env.REACT_APP_GSM_SERVICE_UI}/borrowers`, borrowerDTO);
};

export const updateInd = (borrowerDTO) => {
  return axios.put(`${process.env.REACT_APP_GSM_SERVICE_UI}/borrowers`, borrowerDTO);
};

export const cleanRelationshipInd = (borrowerDTO) => {
  borrowerDTO.borrowerRelationship = null;
  return axios.put(`${process.env.REACT_APP_GSM_SERVICE_UI}/borrowers`, borrowerDTO);
};

export const checkUserRelatedApp = (id) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/individual/related-app/${id}`);
};

export const createBusiness = (businessDTO) => {
  return axios.post(`${process.env.REACT_APP_GSM_SERVICE_UI}/corporate/create`, businessDTO);
};

export const createLoan = (loanDTO) => {
  return axios.post(`${process.env.REACT_APP_GMS_SERVICE_UI}/loan`, loanDTO);
};

export const getLGHistory = (lgId) => {
  return axios.get(`${process.env.REACT_APP_LG_SERVICE}/lg/${lgId}/history`);
};

export const getLG = (params) => {
  return axios.get(`${process.env.REACT_APP_LG_SERVICE}/lg`, { ...params });
};

export const doReturnApp = (appId) => {
  return axios.patch(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/return/${appId}`, {});
};

export const doReturnAppPost = (app) => {
  let req = { appId: app._id, comment: 'self-return' };
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/pfi/return`, req);
};

export const updateBusinessInApp = (businessDTO, appId) => {
  return axios.put(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/${appId}/corporate`, businessDTO);
};

export const migrateAppAfterLinking = (linkingData) => {
  return axios.post(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/individual/link-app`, linkingData);
};

export const getCities = () => {
  return axios.get(`${process.env.REACT_APP_GSM_SERVICE_UI}/address/cities`);
};

export const getDistricts = (dependVal) => {
  return axios.get(`${process.env.REACT_APP_GSM_SERVICE_UI}/address/districts?cityName=${dependVal}`);
};

export const getSangkatCommune = (dependVal) => {
  return axios.get(`${process.env.REACT_APP_GSM_SERVICE_UI}/address/communes?districtName=${dependVal}`);
};

export const getVillage = (dependVal) => {
  return axios.get(`${process.env.REACT_APP_GSM_SERVICE_UI}/address/villages?communeName=${dependVal}`);
};

export const getGlobalSettings = () => {
  let url = `${process.env.REACT_APP_CGCC_SERVICE}/cgcc/exchange_rate/USD/equivalent`;
  return axios.get(url);
};

export const getNewApps = (params) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/new`, { ...params });
};
export const getReturnedApps = (params) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/return`, { ...params });
};
export const getNoWorkflowApps = (params) => {
  return axios.get(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/noworkflow`, { ...params });
};

export const removeFileInApp = (appId, fileId) => {
  return axios.put(`${process.env.REACT_APP_GUARANTEE_SERVICE}/guarantee/application/files/remove`, { fileId, appId });
};
